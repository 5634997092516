import { c as _c } from "react/compiler-runtime";
import { Theme, ThemeContext } from '@emotion/react';
import { themeGet } from '@styled-system/theme-get';
import React from 'react';
import { Box, BoxProps } from '../grid';
export function Stamp(t0) {
  const $ = _c(17);
  let color_;
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      color: color_,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = color_;
    $[2] = props;
    $[3] = ref;
  } else {
    color_ = $[1];
    props = $[2];
    ref = $[3];
  }
  const theme = React.useContext(ThemeContext as any);
  const t1 = color_ || "primary";
  let backgroundColor;
  let borderColor;
  let fontColor;
  if ($[4] !== t1 || $[5] !== theme) {
    const color = t1.split(".")[0];
    const colorScaleName = {
      success: "green",
      warning: "yellow",
      alert: "red",
      primary: "primary_"
    }[color] || color;
    const isDark = theme.mode === "dark";
    if (!isDark) {
      fontColor = themeGet(`colors.${colorScaleName}.9`, colorScaleName)({
        theme
      });
      borderColor = themeGet(`colors.${colorScaleName}.1`, colorScaleName)({
        theme
      });
      backgroundColor = themeGet(`colors.${colorScaleName}.0`, colorScaleName)({
        theme
      });
    } else {
      fontColor = themeGet(`colors.${colorScaleName}.8`, colorScaleName)({
        theme
      });
      borderColor = "transparent";
      backgroundColor = "gray.2";
    }
    $[4] = t1;
    $[5] = theme;
    $[6] = backgroundColor;
    $[7] = borderColor;
    $[8] = fontColor;
  } else {
    backgroundColor = $[6];
    borderColor = $[7];
    fontColor = $[8];
  }
  let t2;
  if ($[9] !== backgroundColor || $[10] !== borderColor || $[11] !== fontColor) {
    t2 = {
      padding: "4px 8px",
      borderRadius: 2,
      display: "inline-block",
      textAlign: "center",
      fontSize: 1,
      fontWeight: "heading",
      whiteSpace: "nowrap",
      backgroundColor,
      color: fontColor,
      borderColor,
      borderWidth: 1,
      borderStyle: "solid",
      overflow: "hidden"
    };
    $[9] = backgroundColor;
    $[10] = borderColor;
    $[11] = fontColor;
    $[12] = t2;
  } else {
    t2 = $[12];
  }
  let t3;
  if ($[13] !== props || $[14] !== ref || $[15] !== t2) {
    t3 = <Box ref={ref} __css={t2} {...props} />;
    $[13] = props;
    $[14] = ref;
    $[15] = t2;
    $[16] = t3;
  } else {
    t3 = $[16];
  }
  return t3;
}
export default Stamp;