import { c as _c } from "react/compiler-runtime";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { BoxProps, Flex } from 'shared/grid';
const IconWrapper = function IconWrapper(t0) {
  const $ = _c(17);
  let icon;
  let ref;
  let rest;
  let t1;
  if ($[0] !== t0) {
    ({
      ref,
      icon,
      size: t1,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = icon;
    $[2] = ref;
    $[3] = rest;
    $[4] = t1;
  } else {
    icon = $[1];
    ref = $[2];
    rest = $[3];
    t1 = $[4];
  }
  const size = t1 === undefined ? 2 : t1;
  const t2 = `${30 * size}px`;
  const t3 = `${30 * size}px`;
  const t4 = `${30 * size}px`;
  let t5;
  if ($[5] !== t2 || $[6] !== t3 || $[7] !== t4) {
    t5 = {
      backgroundColor: "gray.0",
      borderRadius: "999px",
      width: t2,
      minWidth: t3,
      height: t4,
      fontSize: 2
    };
    $[5] = t2;
    $[6] = t3;
    $[7] = t4;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const t6 = `${size}x` as any;
  let t7;
  if ($[9] !== icon || $[10] !== t6) {
    t7 = <FontAwesomeIcon icon={icon} fixedWidth={true} size={t6} />;
    $[9] = icon;
    $[10] = t6;
    $[11] = t7;
  } else {
    t7 = $[11];
  }
  let t8;
  if ($[12] !== ref || $[13] !== rest || $[14] !== t5 || $[15] !== t7) {
    t8 = <Flex ref={ref} flexShrink={0} justifyContent="center" alignItems="center" color="primary" sx={t5} {...rest}>{t7}</Flex>;
    $[12] = ref;
    $[13] = rest;
    $[14] = t5;
    $[15] = t7;
    $[16] = t8;
  } else {
    t8 = $[16];
  }
  return t8;
};
export default IconWrapper;