import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { useAppDispatch } from '../../store';
import axios from 'shared/anaxios';
import settings from 'shared/settings';
import Button from 'shared/button';
import { addToast } from 'shared/toaster/redux/actions';
import { Heading, Text } from 'shared/typography';
import { Grid } from 'shared/grid';
import { useParams } from 'react-router-dom';
const NotificationsView = () => {
  const $ = _c(18);
  const {
    clientId
  } = useParams();
  const [notFound, setNotFound] = React.useState(false);
  const [dontSendNotifications, setDontSendNotifications] = React.useState(false);
  const dispatch = useAppDispatch();
  let t0;
  if ($[0] !== clientId) {
    t0 = () => {
      axios.get(`${settings.api2Root}/client-notification-preferences/${clientId}`).then(t1 => {
        const {
          data
        } = t1;
        setDontSendNotifications(data.dont_send_notifications);
      }).catch(() => {
        setNotFound(true);
      });
    };
    $[0] = clientId;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  let t1;
  if ($[2] !== clientId || $[3] !== dispatch) {
    t1 = [clientId, dispatch];
    $[2] = clientId;
    $[3] = dispatch;
    $[4] = t1;
  } else {
    t1 = $[4];
  }
  React.useEffect(t0, t1);
  let t2;
  if ($[5] !== clientId || $[6] !== dispatch || $[7] !== dontSendNotifications) {
    t2 = () => {
      axios.put(`${settings.api2Root}/client-notification-preferences/${clientId}`, {
        dont_send_notifications: !dontSendNotifications
      }).then(t3 => {
        const {
          data: data_0
        } = t3;
        setDontSendNotifications(data_0.dont_send_notifications);
        dispatch(addToast(data_0.dont_send_notifications ? "Unsubscribed" : "Subscribed", "success"));
      });
    };
    $[5] = clientId;
    $[6] = dispatch;
    $[7] = dontSendNotifications;
    $[8] = t2;
  } else {
    t2 = $[8];
  }
  const toggleDontSendNotifications = t2;
  if (notFound) {
    let t3;
    if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
      t3 = <>Profile not found</>;
      $[9] = t3;
    } else {
      t3 = $[9];
    }
    return t3;
  }
  let t3;
  if ($[10] !== dontSendNotifications) {
    t3 = dontSendNotifications ? <><Heading>Subscribe to booking updates?</Heading><Text variant="warning">You are not currently receiving any booking related reminders or notifications, subscribing will keep you updated. Would you like to receive booking updates?</Text></> : <><Heading>Unsubscribe from all emails and SMSes?</Heading><Text variant="warning">Unsubscribing means you won't be notified of upcoming bookings, are you sure you want to continue?</Text></>;
    $[10] = dontSendNotifications;
    $[11] = t3;
  } else {
    t3 = $[11];
  }
  const t4 = dontSendNotifications ? "Yes, subscribe me" : "Yes, unsubscribe me";
  let t5;
  if ($[12] !== t4 || $[13] !== toggleDontSendNotifications) {
    t5 = <Button onClick={toggleDontSendNotifications}>{t4}</Button>;
    $[12] = t4;
    $[13] = toggleDontSendNotifications;
    $[14] = t5;
  } else {
    t5 = $[14];
  }
  let t6;
  if ($[15] !== t3 || $[16] !== t5) {
    t6 = <Grid>{t3}{t5}</Grid>;
    $[15] = t3;
    $[16] = t5;
    $[17] = t6;
  } else {
    t6 = $[17];
  }
  return t6;
};
export default NotificationsView;