import { c as _c } from "react/compiler-runtime";
import { useLocationId } from './hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import Button from 'shared/button';
import { Box, Container, Flex, Grid } from 'shared/grid';
import { StandardModal } from 'shared/modal';
import PoweredBy from 'shared/powered-by';
import { logout } from 'shared/redux/session/actions';
import { updatePreferences } from 'shared/redux/user-preferences/actions';
import { Heading } from 'shared/typography';
import { languages } from 'shared/utils/constants';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { useAppDispatch, useAppSelector } from './store';
import { Text } from 'shared/typography';
import { faAddressBook, faLanguage } from '@fortawesome/pro-solid-svg-icons';
export const Header = () => {
  const $ = _c(42);
  const dispatch = useAppDispatch();
  const [isLanguageModalOpen, setLanguageModalOpen] = React.useState(false);
  let t0;
  if ($[0] !== dispatch) {
    t0 = language => {
      dispatch(updatePreferences({
        language
      }));
    };
    $[0] = dispatch;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const setLanguage = t0;
  const isSessionValid = useAppSelector(_temp);
  const [locationId] = useLocationId();
  const business = useAppSelector(_temp2);
  let t1;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = ["space-between"];
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  let t2;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = ["wrap", "nowrap"];
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  let t3;
  let t4;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = [3, 3];
    t4 = ["24px", "24px"];
    $[4] = t3;
    $[5] = t4;
  } else {
    t3 = $[4];
    t4 = $[5];
  }
  let t5;
  let t6;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = ["wrap", "nowrap"];
    t6 = {
      gap: 2
    };
    $[6] = t5;
    $[7] = t6;
  } else {
    t5 = $[6];
    t6 = $[7];
  }
  const t7 = `/` + (locationId ? `?lid=${locationId}` : "");
  let t8;
  if ($[8] === Symbol.for("react.memo_cache_sentinel")) {
    t8 = {
      gap: 3
    };
    $[8] = t8;
  } else {
    t8 = $[8];
  }
  let t9;
  if ($[9] !== business.logo_image_id) {
    t9 = business.logo_image_id && <Box position="relative" flexGrow={0} flexShrink={1} flexBasis={[100, 150]}><Box sx={{
        position: "absolute",
        bottom: "0px",
        zIndex: 1
      }}><Flex alignItems="center" justifyContent="center" flexShrink={0} sx={{
          width: [100, 150],
          height: [100, 150],
          borderRadius: "9999px",
          overflow: "hidden",
          objectFit: "cover",
          backgroundPosition: "center",
          backgroundImage: `url(${getCdnImageUrlFromId(business.logo_image_id)})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderColor: "gray.1",
          borderStyle: "solid",
          borderWidth: "1px",
          boxShadow: "medium"
        }} /></Box><Box width={[100, 150]} height="60px" mr={3} /></Box>;
    $[9] = business.logo_image_id;
    $[10] = t9;
  } else {
    t9 = $[10];
  }
  let t10;
  if ($[11] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = {
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflowWrap: "break-word"
    };
    $[11] = t10;
  } else {
    t10 = $[11];
  }
  let t11;
  if ($[12] !== business.name) {
    t11 = <Box flex="1 1 100px"><Heading as="h1" fontSize={3} color="text" sx={t10}>{business.name}</Heading></Box>;
    $[12] = business.name;
    $[13] = t11;
  } else {
    t11 = $[13];
  }
  let t12;
  if ($[14] !== t11 || $[15] !== t7 || $[16] !== t9) {
    t12 = <Flex className="logo-and-name"><Flex as={ReachLink} justifyContent="center" alignItems="center" to={t7} sx={t8}>{t9}{t11}</Flex></Flex>;
    $[14] = t11;
    $[15] = t7;
    $[16] = t9;
    $[17] = t12;
  } else {
    t12 = $[17];
  }
  let t13;
  if ($[18] === Symbol.for("react.memo_cache_sentinel")) {
    t13 = {
      gap: 0,
      top: 0,
      right: 0,
      position: ["absolute", "static"],
      backgroundColor: ["background", "transparent"],
      borderBottomLeftRadius: [16, 0]
    };
    $[18] = t13;
  } else {
    t13 = $[18];
  }
  let t14;
  if ($[19] !== dispatch || $[20] !== isSessionValid) {
    t14 = isSessionValid && <Button variant="flat" onClick={() => dispatch(logout())}>Sign out</Button>;
    $[19] = dispatch;
    $[20] = isSessionValid;
    $[21] = t14;
  } else {
    t14 = $[21];
  }
  const t15 = `/contact?lid=${locationId}`;
  let t16;
  if ($[22] === Symbol.for("react.memo_cache_sentinel")) {
    t16 = <Text ml="0.5em" display={["none", "none", "inline"]}><FormattedMessage id="Contact us" defaultMessage="Contact us" /></Text>;
    $[22] = t16;
  } else {
    t16 = $[22];
  }
  let t17;
  if ($[23] !== t15) {
    t17 = <Button as={ReachLink} variant="flat" color="gray" to={t15} noIconPadding={true} iconLeft={faAddressBook}>{t16}</Button>;
    $[23] = t15;
    $[24] = t17;
  } else {
    t17 = $[24];
  }
  let t18;
  if ($[25] === Symbol.for("react.memo_cache_sentinel")) {
    t18 = <Button color="gray" variant="flat" onClick={() => setLanguageModalOpen(true)} iconLeft={faLanguage} />;
    $[25] = t18;
  } else {
    t18 = $[25];
  }
  let t19;
  if ($[26] !== t14 || $[27] !== t17) {
    t19 = <Flex sx={t13}>{t14}{t17}{t18}</Flex>;
    $[26] = t14;
    $[27] = t17;
    $[28] = t19;
  } else {
    t19 = $[28];
  }
  let t20;
  if ($[29] !== t12 || $[30] !== t19) {
    t20 = <Flex bg="background" width="100%" justifyContent={t1} alignItems="center" boxShadow="medium" zIndex={3} flexWrap={t2}><Container py={t3} px={t4} className="container"><Flex justifyContent="space-between" alignItems="center" flexWrap={t5} sx={t6}>{t12}{t19}</Flex></Container></Flex>;
    $[29] = t12;
    $[30] = t19;
    $[31] = t20;
  } else {
    t20 = $[31];
  }
  let t21;
  if ($[32] === Symbol.for("react.memo_cache_sentinel")) {
    t21 = () => setLanguageModalOpen(false);
    $[32] = t21;
  } else {
    t21 = $[32];
  }
  let t22;
  if ($[33] === Symbol.for("react.memo_cache_sentinel")) {
    t22 = <FormattedMessage id="Public.selectLanguage" defaultMessage="Select a language" />;
    $[33] = t22;
  } else {
    t22 = $[33];
  }
  let t23;
  if ($[34] !== setLanguage) {
    t23 = () => <Grid>{Object.entries(languages).map((t24, idx) => {
        const [code, language_0] = t24;
        return <Button variant="outlined" key={idx} width="100%" onClick={() => {
          setLanguage(code);
          setLanguageModalOpen(false);
        }}>{language_0}</Button>;
      })}</Grid>;
    $[34] = setLanguage;
    $[35] = t23;
  } else {
    t23 = $[35];
  }
  let t24;
  if ($[36] !== isLanguageModalOpen || $[37] !== t23) {
    t24 = <StandardModal close={t21} isOpen={isLanguageModalOpen} title={t22}>{t23}</StandardModal>;
    $[36] = isLanguageModalOpen;
    $[37] = t23;
    $[38] = t24;
  } else {
    t24 = $[38];
  }
  let t25;
  if ($[39] !== t20 || $[40] !== t24) {
    t25 = <>{t20}{t24}</>;
    $[39] = t20;
    $[40] = t24;
    $[41] = t25;
  } else {
    t25 = $[41];
  }
  return t25;
};
export const BareLayout = t0 => {
  const $ = _c(13);
  const {
    children
  } = t0;
  const business = useAppSelector(_temp3);
  const t1 = business.cover_image_id ? `url(${getCdnImageUrlFromId(business.cover_image_id)})` : `linear-gradient(
                to right,
                rgba(0, 0, 0, 0.2), 
                transparent)`;
  let t2;
  if ($[0] !== t1) {
    t2 = <Box sx={{
      height: "10rem",
      backgroundColor: "#fff",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      position: "relative",
      bg: "gray.1",
      backgroundImage: t1
    }} />;
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <Header />;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  let t5;
  let t6;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = [1, "24px"];
    t5 = ["24px"];
    t6 = [1, 0];
    $[3] = t4;
    $[4] = t5;
    $[5] = t6;
  } else {
    t4 = $[3];
    t5 = $[4];
    t6 = $[5];
  }
  let t7;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = {
      flexDirection: "row",
      "& > *": {
        flexGrow: 1
      }
    };
    $[6] = t7;
  } else {
    t7 = $[6];
  }
  let t8;
  if ($[7] !== children) {
    t8 = <Container px={t4} py={t5} flex={t6} display="flex" sx={t7}>{children}</Container>;
    $[7] = children;
    $[8] = t8;
  } else {
    t8 = $[8];
  }
  let t9;
  if ($[9] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = <PoweredBy medium="embed" my={3} />;
    $[9] = t9;
  } else {
    t9 = $[9];
  }
  let t10;
  if ($[10] !== t2 || $[11] !== t8) {
    t10 = <>{t2}{t3}{t8}{t9}</>;
    $[10] = t2;
    $[11] = t8;
    $[12] = t10;
  } else {
    t10 = $[12];
  }
  return t10;
};
const Layout = t0 => {
  const $ = _c(9);
  const {
    children
  } = t0;
  const business = useAppSelector(_temp4);
  const t1 = business.cover_image_id ? `url(${getCdnImageUrlFromId(business.cover_image_id)})` : `linear-gradient(
                to right,
                rgba(0, 0, 0, 0.2), 
                transparent)`;
  let t2;
  if ($[0] !== t1) {
    t2 = <Box sx={{
      height: "10rem",
      backgroundColor: "#fff",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      position: "relative",
      bg: "gray.1",
      backgroundImage: t1
    }} />;
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <Header />;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  let t4;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = <PoweredBy pt={2} />;
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] !== children) {
    t5 = <Flex flex="1"><Container backgroundColor="bodyBackground" flex="1" display="flex" flexDirection="column">{children}{t4}</Container></Flex>;
    $[4] = children;
    $[5] = t5;
  } else {
    t5 = $[5];
  }
  let t6;
  if ($[6] !== t2 || $[7] !== t5) {
    t6 = <>{t2}{t3}{t5}</>;
    $[6] = t2;
    $[7] = t5;
    $[8] = t6;
  } else {
    t6 = $[8];
  }
  return t6;
};
export default Layout;
function _temp(state) {
  return state.session.tokenExp > new Date().getTime() / 1000;
}
function _temp2(state_0) {
  return state_0.businesses.data[state_0.public.businessId];
}
function _temp3(state) {
  return state.businesses.data[state.public.businessId];
}
function _temp4(state) {
  return state.businesses.data[state.public.businessId];
}