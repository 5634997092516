import { c as _c } from "react/compiler-runtime";
import { useSearchParams } from 'react-router-dom';
import React from 'react';
import { isUUID } from 'shared/utils/uuid';
import { toBase16 } from 'shared/utils/b58';
export const useLocationId = (): [string, (id: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const locationId_ = searchParams.get('lid');
  const locationId_0 = React.useMemo(() => {
    let locationId = locationId_;
    if (locationId && !isUUID(locationId)) {
      if (locationId == 'undefined') {
        locationId = null;
      } else if (locationId.length > 1 && locationId.length < 32) {
        locationId = toBase16(locationId);
      } else if (locationId.length !== 32) {
        // Not uuid
        locationId = null;
      }
    }
    return locationId;
  }, [locationId_]);
  const setLocation = React.useCallback(lid => {
    if (locationId_0 == lid) {
      return;
    }
    setSearchParams(prev => ({
      ...Object.fromEntries(prev.entries()),
      lid: lid || ''
    }), {
      replace: true
    });
  }, [locationId_0, setSearchParams]);
  return [locationId_0, setLocation];
};
export const useBookableIds = (): [string[], (ids: readonly string[]) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const bids = searchParams.get('bids') || '';
  const bookableIds = React.useMemo(() => bids.split(',').map(id => id || null), [bids]);
  const setBookableIds = React.useCallback(bookableIds_0 => {
    setSearchParams(prev => {
      return {
        ...Object.fromEntries(prev.entries()),
        bids: bookableIds_0.join(',')
      };
    }, {
      replace: true
    });
  }, [setSearchParams]);
  return [bookableIds, setBookableIds];
};
export const useDuration = () => {
  const $ = _c(7);
  const [searchParams, setSearchParams] = useSearchParams();
  let t0;
  if ($[0] !== searchParams) {
    t0 = searchParams.get("duration");
    $[0] = searchParams;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const duration = t0;
  let t1;
  if ($[2] !== setSearchParams) {
    t1 = duration_0 => {
      setSearchParams(prev => ({
        ...Object.fromEntries(prev.entries()),
        duration: duration_0 || ""
      }), {
        replace: true
      });
    };
    $[2] = setSearchParams;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  const setDuration = t1;
  const t2 = Number(duration) || null;
  let t3;
  if ($[4] !== setDuration || $[5] !== t2) {
    t3 = [t2, setDuration];
    $[4] = setDuration;
    $[5] = t2;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  return t3;
};