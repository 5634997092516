import { c as _c } from "react/compiler-runtime";
import 'fontsource-montserrat/latin.css';
import 'fontsource-open-sans/latin.css';
import { DateTime, Settings as LuxonSettings } from 'luxon';
import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';
import axios from 'shared/anaxios';
import Button from 'shared/button';
import { ViewErrorBoundary } from 'shared/error-boundry';
import { Box, Flex, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import 'shared/loader.css';
import { StandardModal } from 'shared/modal';
import { fetchBookableBusy } from 'shared/redux/bookable-busy/actions';
import { fetchIPLocation } from 'shared/redux/geoip/actions';
import { fetchSchedules } from 'shared/redux/schedules/actions';
import { updatePreferences } from 'shared/redux/user-preferences/actions';
import Toaster from 'shared/toaster';
import { addToast } from 'shared/toaster/redux/actions';
import sharedFrench from 'shared/translations/fr.json';
import { CroppedText, Link } from 'shared/typography';
import { getErrorInterceptor } from 'shared/utils/http-error-handling';
import { getBrowserTimezone } from 'shared/utils/time';
import fav192 from './images/icon-192x192.png';
import { useAppDispatch, useAppSelector } from './store';
import french from './translations/fr.json';
import { Global, ThemeProvider } from '@emotion/react';
import { config, dom } from '@fortawesome/fontawesome-svg-core';
import { Provider as ReduxProvider } from 'react-redux';
import ErrorBoundary from 'shared/error-boundry';
import globalStyle from 'shared/global-style';
import { ModalToastProvider } from 'shared/modal/context';
import initWhatInput from 'shared/what-input-with-shadow-root';
// import 'what-input';
import { faChevronLeft, faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIsRecaptchaReady } from 'shared/hooks/use-recaptcha';
import NotFoundImage from 'shared/images/404-1.gif';
import settings from 'shared/settings';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import theme from './theme';
config.autoAddCss = false;
const messages = {
  fr: {
    ...sharedFrench,
    ...french
  },
  en: {}
};
const isServer = typeof window === 'undefined';
const hasNewVersion = () => axios.get('/version.json').then(({
  data
}) => data.hash && __VERSION__ !== data.hash);
if (!isServer) {
  //@ts-ignore
  hasNewVersion().then(hasNew => hasNew && window.location.reload(true));
}
const confLuxon = () => {
  LuxonSettings.defaultZone = getBrowserTimezone();
  LuxonSettings.throwOnInvalid = true;
};
// prevent it from taking the site down
setTimeout(confLuxon);
const App = ({
  children,
  helmetContext
}: {
  children: React.ReactNode;
  helmetContext?: any;
}) => {
  React.useEffect(() => {
    initWhatInput();
  }, []);
  const [isUpdateModalOpen, setUpdateModalOpen] = React.useState(false);
  const updateIntervalIdRef = React.useRef(null);
  const sessionIntervalIdRef = React.useRef(null);
  const businessId = useAppSelector(state => state.public.businessId);
  const business = useAppSelector(state => state.businesses.data[businessId]);
  const language = useAppSelector(state => state.userPreferences.language);
  const businessLanguage = business?.language || 'en';
  const notFound = useAppSelector(state => state.businesses.status === 'failed');
  const isLoading = useAppSelector(state => [state.businesses.status, state.locations.status, state.businesses.status, state.services.status, state.bookables.status, state.bookableGroups.status].map(v => v || 'loading').includes('loading'));
  const dispatch = useAppDispatch();
  const userLocale = typeof window === 'undefined' ? globalThis['LOCALE'] || 'en-GB' : navigator.language;
  const localeCandidate = language.substring(0, 2) === userLocale.substring(0, 2) ? userLocale : language;
  const locale = Intl.NumberFormat.supportedLocalesOf([localeCandidate, 'en-GB'])[0];
  React.useEffect(() => {
    LuxonSettings.defaultLocale = locale;
  }, [language, locale]);
  const setLanguage = React.useCallback(language => {
    dispatch(updatePreferences({
      language
    }));
  }, [dispatch]);
  React.useEffect(() => {
    setLanguage(businessLanguage);
  }, [businessLanguage, setLanguage]);
  React.useEffect(() => {
    // const handle = window.location.host.split('.')[0];

    const updateAvailability = () => {
      if (!business) {
        return;
      }
      const [start, end] = business.settings.booking_window.map(v => DateTime.utc().plus({
        minutes: v
      }).toISO().substring(0, 19) + 'Z');
      dispatch(fetchSchedules(business.id));
      dispatch(fetchBookableBusy(business.id, start, end));
    };

    // updateAvailability();
    updateIntervalIdRef.current = setInterval(updateAvailability, 5 * 60 * 1000);

    // dispatch(fetchLocations(business.id));
    // dispatch(fetchServices(business.id, true));
    // dispatch(fetchBookableGroups(business.id));
    // dispatch(fetchBookables(business.id));
    // dispatch(fetchBillableItems({ businessId: business.id }));
    // dispatch(fetchInvoiceSettings(business.id));
    setLanguage(language);
    if (business?.settings.fb_pixel_id) {
      setTimeout(() => {
        (function (f: any, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            n.callMethod ?
            // eslint-disable-next-line prefer-spread, prefer-rest-params
            n.callMethod.apply(n, arguments) :
            // eslint-disable-next-line prefer-rest-params
            n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = '2.0';
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window['fbq']('init', business.settings.fb_pixel_id);
        window['fbq']('track', 'PageView');
      });
    }
    dispatch(fetchIPLocation());
    sessionIntervalIdRef.current = setInterval(() => {
      hasNewVersion().then(hasNew => hasNew && setUpdateModalOpen(true));
    }, 60000);
    return () => {
      clearInterval(updateIntervalIdRef.current);
      clearInterval(sessionIntervalIdRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setLanguage]);
  const [wasLoaded, setWasLoaded] = React.useState(false);
  React.useEffect(() => {
    if (!isLoading) {
      setWasLoaded(true);
    }
  }, [isLoading]);
  const isRecaptchaReady = useIsRecaptchaReady();
  if (notFound) {
    return <>
        <title>Business not found</title>

        <Flex justifyContent="center" alignItems="center" flex={1} height="100vh">
          <Grid textAlign="center">
            <CroppedText as="h1" fontSize={5} color="text">
              Business not found
            </CroppedText>
            <Link variant="discreetLink" href={`//${settings.bookemBaseDomain}`}>
              <FontAwesomeIcon icon={faChevronLeft} /> Take me back
            </Link>
          </Grid>

          <Box as="img" sx={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          height: '40%'
        }} src={NotFoundImage} alt="Not found" title="Not found" />
        </Flex>
      </>;
  }
  if (!businessId) {
    return <>
        <title>Error</title>
        <Flex justifyContent="center" alignItems="center" flex={1} height="100vh">
          <Grid textAlign="center">
            <CroppedText as="h1" fontSize={5} color="text">
              Something went wrong.
            </CroppedText>
            <Link variant="discreetLink" as="button" type="button" onClick={() => window.location.reload()}>
              <FontAwesomeIcon icon={faRefresh} /> Reload
            </Link>
          </Grid>
        </Flex>
      </>;
  }
  if (isLoading && !wasLoaded) {
    return <>
        <title>business?.name</title>
        <Loader />
      </>;
  }
  return <IntlProvider key={language} locale={locale} defaultLocale="en-GB" messages={messages[language.substring(0, 2)]} data-sentry-element="IntlProvider" data-sentry-component="App" data-sentry-source-file="app.tsx">
      <Flex bg={['white', 'bodyBackground']} flexDirection="column" minHeight="100vh" data-sentry-element="Flex" data-sentry-source-file="app.tsx">
        <title>{business?.name}</title>

        <link rel="shortcut icon" sizes="192x192" href={fav192} />
        {/* <link rel="apple-touch-icon" sizes="48x48" href={fav48} />
            <link rel="apple-touch-icon" sizes="72x72" href={fav72} />
            <link rel="apple-touch-icon" sizes="96x96" href={fav96} />
            <link rel="apple-touch-icon" sizes="144x144" href={fav144} />
            <link rel="apple-touch-icon" sizes="192x192" href={fav192} /> */}

        <meta name="viewport" content="width=device-width, initial-scale=1, interactive-widget=resizes-content" data-sentry-element="meta" data-sentry-source-file="app.tsx" />

        {/* <meta
              name="viewport"
              content="width=device-width, initial-scale=1"
            /> */}

        <meta name="description" content={business?.description} data-sentry-element="meta" data-sentry-source-file="app.tsx" />
        <meta name="mobile-web-app-capable" content="yes" data-sentry-element="meta" data-sentry-source-file="app.tsx" />
        <meta name="apple-mobile-web-app-capable" content="yes" data-sentry-element="meta" data-sentry-source-file="app.tsx" />

        <meta property="og:title" content={business?.name} data-sentry-element="meta" data-sentry-source-file="app.tsx" />
        <meta property="og:description" content={business?.description} data-sentry-element="meta" data-sentry-source-file="app.tsx" />
        <meta property="og:site_name" content={business?.name} data-sentry-element="meta" data-sentry-source-file="app.tsx" />
        <meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="app.tsx" />

        {(business.logo_image_id || business.cover_image_id) && <meta property="og:image" content={(business.logo_image_id ? getCdnImageUrlFromId(business.logo_image_id) : null) || (business.cover_image_id ? getCdnImageUrlFromId(business.cover_image_id) : null)} />}

        <Toaster data-sentry-element="Toaster" data-sentry-source-file="app.tsx" />

        <React.Suspense fallback={<Loader />} data-sentry-element="unknown" data-sentry-source-file="app.tsx">
          <ViewErrorBoundary data-sentry-element="ViewErrorBoundary" data-sentry-source-file="app.tsx">{children}</ViewErrorBoundary>
        </React.Suspense>

        <StandardModal isOpen={isUpdateModalOpen} close={() => setUpdateModalOpen(false)} title={<FormattedMessage id="Public.siteUpdateModalHeader" defaultMessage="This page has been updated" />} data-sentry-element="StandardModal" data-sentry-source-file="app.tsx">
          {() => <Grid>
              <CroppedText p={3} textAlign="center">
                <FormattedMessage id="Public.siteUpdateModalDescription" defaultMessage="Please reload the page, unless you are in the middle of something." />
              </CroppedText>

              <Flex justifyContent="space-between" flexWrap="wrap">
                <Button width={['100%', 'auto']}
            //@ts-ignore
            onClick={() => window.location.reload(true)}>
                  <FormattedMessage id="Public.siteUpdateModalReloadButtonText" defaultMessage="Reload" />
                </Button>
                <Button mt={[3, 0]} width={['100%', 'auto']} variant="outlined" color="gray" onClick={() => {
              setUpdateModalOpen(false);
              setTimeout(() => {
                setUpdateModalOpen(true);
              }, 5 * 60 * 1000);
            }}>
                  <FormattedMessage id="Public.siteUpdateModalPostponeButtonText" defaultMessage="Remind me in 5 minutes" />
                </Button>
              </Flex>
            </Grid>}
        </StandardModal>
      </Flex>
    </IntlProvider>;
};
const WrappedApp = t0 => {
  const $ = _c(16);
  const {
    children,
    store,
    css: t1
  } = t0;
  const css = t1 === undefined ? "" : t1;
  let t2;
  if ($[0] !== store) {
    t2 = () => {
      axios.interceptors.response.use(_temp, getErrorInterceptor(_temp2, message => store.dispatch(addToast(message[0], message[1], message[2]))));
    };
    $[0] = store;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  React.useEffect(t2);
  let t3;
  let t4;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = <meta charSet="utf-8" />;
    t4 = <meta name="viewport" content="width=device-width, initial-scale=1, interactive-widget=resizes-content" />;
    $[2] = t3;
    $[3] = t4;
  } else {
    t3 = $[2];
    t4 = $[3];
  }
  let t5;
  if ($[4] !== css) {
    t5 = <head>{t3}{t4}<style>{css}</style></head>;
    $[4] = css;
    $[5] = t5;
  } else {
    t5 = $[5];
  }
  let t6;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = <Global styles={globalStyle as any} />;
    $[6] = t6;
  } else {
    t6 = $[6];
  }
  let t7;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = <style dangerouslySetInnerHTML={{
      __html: dom.css()
    }} />;
    $[7] = t7;
  } else {
    t7 = $[7];
  }
  let t8;
  if ($[8] !== children) {
    t8 = <ModalToastProvider><ErrorBoundary>{t7}<App>{children}</App></ErrorBoundary></ModalToastProvider>;
    $[8] = children;
    $[9] = t8;
  } else {
    t8 = $[9];
  }
  let t9;
  if ($[10] !== store || $[11] !== t8) {
    t9 = <body><ThemeProvider theme={theme}>{t6}<ReduxProvider store={store}>{t8}</ReduxProvider></ThemeProvider></body>;
    $[10] = store;
    $[11] = t8;
    $[12] = t9;
  } else {
    t9 = $[12];
  }
  let t10;
  if ($[13] !== t5 || $[14] !== t9) {
    t10 = <html lang="en">{t5}{t9}</html>;
    $[13] = t5;
    $[14] = t9;
    $[15] = t10;
  } else {
    t10 = $[15];
  }
  return t10;
};
export default WrappedApp;
function _temp(response) {
  return response;
}
function _temp2() {
  return null;
}