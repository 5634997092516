import { c as _c } from "react/compiler-runtime";
import { Theme, ThemeContext } from '@emotion/react';
import { faCalendar, faEnvelope, faMapMarker, faPhone } from '@fortawesome/pro-regular-svg-icons';
import Tippy from 'shared/tooltip';
import deepEqual from 'fast-deep-equal';
import { DateTime } from 'luxon';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import Button from 'shared/button';
import Callout from 'shared/callout';
import DropdownSelect from 'shared/dropdown-select';
import { Box, Flex, Grid } from 'shared/grid';
import IconWrapper from 'shared/icon-wrapper';
import { fetchService } from 'shared/redux/services/actions';
import settings from 'shared/settings';
import { CustomElement } from 'shared/slate-shared/custom-types';
import RenderSlate from 'shared/slate-shared/render';
import { CroppedText, Heading, Link, Text } from 'shared/typography';
import { getBookingUrl, getVideoCallLink, getVideoCallUrl, statusToColorScale } from 'shared/utils/booking';
import { useAppDispatch, useAppSelector } from '../../store';
import { BorderBox } from '../shared';
import { useCantCancelBookingReason } from './hooks';
const ContactDetails = ({
  location
}) => <>
    {location.email && <Flex as="a" href={`mailto:${location.email}`} alignItems="center" fontSize={2}>
        <IconWrapper icon={faEnvelope} size={1} mr={2} /> {location.email}
      </Flex>}
    {location.tel && <Flex as="a" href={`tel:${location.tel}`} alignItems="center" fontSize={2}>
        <IconWrapper icon={faPhone} size={1} mr={2} /> {location.tel}
      </Flex>}
  </>;
const EMPTY_VALUE: CustomElement[] = [{
  type: 'paragraph',
  children: [{
    text: ''
  }]
}];
const isSlateBlank = (value: any[]) => {
  if (!value) {
    return true;
  }
  return deepEqual(value, EMPTY_VALUE);
};
export default function BookingDefaultView(t0) {
  const $ = _c(129);
  const {
    booking,
    location,
    canCancel,
    isOwner,
    setShowCancelModal
  } = t0;
  const dispatch = useAppDispatch();
  const business = useAppSelector(_temp);
  useAppSelector(_temp2);
  const serviceData = useAppSelector(_temp3);
  let t1;
  if ($[0] !== booking.services) {
    t1 = booking?.services || [];
    $[0] = booking.services;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== t1) {
    t2 = t1.map(_temp4).join(" + ");
    $[2] = t1;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  const serviceName = t2;
  let t3;
  if ($[4] !== booking.services || $[5] !== serviceData) {
    t3 = booking?.services.some(t4 => {
      const {
        service_id
      } = t4;
      return serviceData[service_id]?.offsite !== "on_premises";
    });
    $[4] = booking.services;
    $[5] = serviceData;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  const offsite = t3;
  const timezone = offsite && booking.client_timezone || location.timezone;
  const [timeToStart, setTimeToStart] = React.useState("");
  let t4;
  if ($[7] !== booking.services) {
    t4 = booking.services.map(_temp5).sort(_temp6);
    $[7] = booking.services;
    $[8] = t4;
  } else {
    t4 = $[8];
  }
  const earliestStart = t4[0];
  booking.services.map(_temp7).sort(_temp8);
  let t5;
  let t6;
  if ($[9] !== booking.services || $[10] !== dispatch || $[11] !== serviceData) {
    t5 = () => {
      booking.services.forEach(t7 => {
        const {
          service_id: service_id_0
        } = t7;
        if (service_id_0 && !serviceData[service_id_0]) {
          dispatch(fetchService(service_id_0));
        }
      });
    };
    t6 = [booking.services, dispatch, serviceData];
    $[9] = booking.services;
    $[10] = dispatch;
    $[11] = serviceData;
    $[12] = t5;
    $[13] = t6;
  } else {
    t5 = $[12];
    t6 = $[13];
  }
  React.useEffect(t5, t6);
  let t7;
  let t8;
  if ($[14] !== earliestStart) {
    t7 = () => {
      const set = () => {
        const tts = DateTime.fromISO(earliestStart).toRelative();
        setTimeToStart(tts);
      };
      const id = setInterval(set, 60000);
      set();
      return () => clearInterval(id);
    };
    t8 = [earliestStart];
    $[14] = earliestStart;
    $[15] = t7;
    $[16] = t8;
  } else {
    t7 = $[15];
    t8 = $[16];
  }
  React.useEffect(t7, t8);
  let t9;
  if ($[17] !== earliestStart || $[18] !== timezone) {
    t9 = DateTime.fromISO(earliestStart, {
      zone: timezone
    }).toFormat("t");
    $[17] = earliestStart;
    $[18] = timezone;
    $[19] = t9;
  } else {
    t9 = $[19];
  }
  const startTime = t9;
  let t10;
  if ($[20] !== earliestStart || $[21] !== timezone) {
    t10 = DateTime.fromISO(earliestStart, {
      zone: timezone
    }).toFormat("DDDD");
    $[20] = earliestStart;
    $[21] = timezone;
    $[22] = t10;
  } else {
    t10 = $[22];
  }
  const startDate = t10;
  let t11;
  if ($[23] !== earliestStart || $[24] !== timezone) {
    t11 = DateTime.fromISO(earliestStart, {
      zone: timezone
    }).toFormat("DDDD 'at' t (ZZZZ)");
    $[23] = earliestStart;
    $[24] = timezone;
    $[25] = t11;
  } else {
    t11 = $[25];
  }
  const startDateTime = t11;
  const t12 = location.name || "[removed location]";
  let t13;
  if ($[26] !== serviceName || $[27] !== startDate || $[28] !== startTime || $[29] !== t12) {
    t13 = {
      startTime,
      startDate,
      serviceName,
      locationName: t12
    };
    $[26] = serviceName;
    $[27] = startDate;
    $[28] = startTime;
    $[29] = t12;
    $[30] = t13;
  } else {
    t13 = $[30];
  }
  const bookingMsgValues = t13;
  let t14;
  if ($[31] !== booking.services || $[32] !== booking.third_party_video_call_url || $[33] !== booking.video_call_enabled || $[34] !== serviceData) {
    t14 = !!booking.third_party_video_call_url || booking.video_call_enabled || booking.services.map(t15 => {
      const {
        service_id: service_id_1
      } = t15;
      return serviceData[service_id_1];
    }).filter(Boolean).some(_temp9);
    $[31] = booking.services;
    $[32] = booking.third_party_video_call_url;
    $[33] = booking.video_call_enabled;
    $[34] = serviceData;
    $[35] = t14;
  } else {
    t14 = $[35];
  }
  const hasVideoCall = t14;
  let t15;
  if ($[36] !== booking.services || $[37] !== serviceData) {
    let t16;
    if ($[39] !== serviceData) {
      t16 = t17 => {
        const {
          service_id: service_id_2
        } = t17;
        return serviceData[service_id_2];
      };
      $[39] = serviceData;
      $[40] = t16;
    } else {
      t16 = $[40];
    }
    t15 = booking.services.map(t16).filter(Boolean).some(_temp10);
    $[36] = booking.services;
    $[37] = serviceData;
    $[38] = t15;
  } else {
    t15 = $[38];
  }
  const hasOnsite = t15;
  const showLocation = !location.is_virtual && hasOnsite;
  let t16;
  let t17;
  if ($[41] !== booking) {
    t17 = getVideoCallLink(booking);
    $[41] = booking;
    $[42] = t17;
  } else {
    t17 = $[42];
  }
  t16 = t17;
  const videoCallLink = t16;
  let t18;
  if ($[43] !== bookingMsgValues) {
    t18 = {
      confirmed: <FormattedMessage id="Public.BookingPage.confirmedBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} was confirmed.`} values={bookingMsgValues} />,
      no_show: <FormattedMessage id="Public.BookingPage.noShowBookingDesciption" defaultMessage={`It was noted that you did not show up for your {serviceName} booking that was scheduled for {startTime} on {startDate}.`} values={bookingMsgValues} />,
      cancelled: <FormattedMessage id="Public.BookingPage.cancelledBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} was cancelled.`} values={bookingMsgValues} />,
      requested: <FormattedMessage id="Public.BookingPage.requestedBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} is pending approval.`} values={bookingMsgValues} />,
      pencilled_in: <FormattedMessage id="Public.BookingPage.pencilledInBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} has been pencilled in.`} values={bookingMsgValues} />,
      awaiting_payment: <FormattedMessage id="Public.BookingPage.awaitingPaymentBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} is awaiting payment.`} values={bookingMsgValues} />,
      denied: <FormattedMessage id="Public.BookingPage.deniedBookingDesciption" defaultMessage={`Your {serviceName} booking for {startTime} on {startDate} was declined.`} values={bookingMsgValues} />
    };
    $[43] = bookingMsgValues;
    $[44] = t18;
  } else {
    t18 = $[44];
  }
  const statusToMsg = t18;
  const theme = React.useContext(ThemeContext as any);
  let t19;
  if ($[45] !== business.feature_flags) {
    t19 = business?.feature_flags || [];
    $[45] = business.feature_flags;
    $[46] = t19;
  } else {
    t19 = $[46];
  }
  const featureFlags = t19;
  let t20;
  if ($[47] !== featureFlags) {
    t20 = featureFlags.includes("video_calls");
    $[47] = featureFlags;
    $[48] = t20;
  } else {
    t20 = $[48];
  }
  const videoCallsEnabled = t20;
  const locationData = useAppSelector(_temp11);
  const businessData = useAppSelector(_temp12);
  let t21;
  const business_0 = businessData[booking.business_id];
  const location_0 = locationData[booking.location_id];
  let urls;
  if ($[49] !== booking.id || $[50] !== booking.services || $[51] !== booking.third_party_video_call_url || $[52] !== booking.video_call_enabled || $[53] !== business_0.name || $[54] !== location_0 || $[55] !== serviceData) {
    urls = {
      google: "",
      outlook: "",
      yahoo: ""
    };
    booking.services.forEach(service => {
      let description;
      let locationAddress;
      const serviceStart = DateTime.fromISO(service.start, {
        zone: "UTC"
      });
      const serviceEnd = DateTime.fromISO(service.end, {
        zone: "UTC"
      });
      let serviceName_0 = service.service_name || "Custom";
      serviceName_0 = serviceName_0 + ` (${business_0.name})`;
      const serviceType = serviceData[service.service_id]?.type;
      const bookingLink = getBookingUrl(booking.id);
      if (serviceType == "video" || booking.third_party_video_call_url || booking.video_call_enabled) {
        const videoLink = getVideoCallUrl(booking.id);
        locationAddress = videoLink;
        description = `ℹ️  ${bookingLink}\n\n📍  ${videoLink}`;
      } else {
        if (serviceType === "on_premises" && location_0 && !location_0.is_virtual) {
          locationAddress = [...location_0.street_address.split(","), location_0.city, location_0.postal_code, location_0.province].filter(Boolean).join(", ");
          const gps = location_0?.gps?.[0] && location_0?.gps?.[1] ? location_0.gps : null;
          const mapLink = gps ? `\n\n📍 https://maps.google.com/maps?&z=16&q=${gps[0]}+${gps[1]}&ll=${gps[0]}+${gps[1]}` : "";
          description = location_0 ? `ℹ️  ${bookingLink}${mapLink}` : "";
        } else {
          description = `ℹ️  ${bookingLink}`;
          locationAddress = "";
        }
      }
      const title = serviceName_0;
      description = encodeURIComponent(description);
      const startDatetimeGoogleYahoo = serviceStart.toFormat("yyyyMMdd'T'HHmmss") + "Z";
      const endDatetimeGoogleYahoo = serviceEnd.toFormat("yyyyMMdd'T'HHmmss") + "Z";
      const startDatetimeOutlook = serviceStart.toFormat("yyyy'-'MM'-'dd'T'HH':'mm':'ss") + "Z";
      const endDatetimeOutlook = serviceEnd.toFormat("yyyy'-'MM'-'dd'T'HH':'mm':'ss") + "Z";
      urls.google = `http://www.google.com/calendar/event?action=TEMPLATE&dates=${startDatetimeGoogleYahoo}/${endDatetimeGoogleYahoo}&text=${title}&location=${locationAddress}&details=${description}`;
      urls.outlook = `https://outlook.live.com/calendar/action/compose?rru=addevent&startdt=${startDatetimeOutlook}&enddt=${endDatetimeOutlook}&subject=${title}&body=${description}&location=${locationAddress}`;
      urls.yahoo = `https://calendar.yahoo.com/?v=60&TITLE=${title}&ST=${startDatetimeGoogleYahoo}&ET=${endDatetimeGoogleYahoo}&DESC=${description}&in_loc=${locationAddress}`;
    });
    $[49] = booking.id;
    $[50] = booking.services;
    $[51] = booking.third_party_video_call_url;
    $[52] = booking.video_call_enabled;
    $[53] = business_0.name;
    $[54] = location_0;
    $[55] = serviceData;
    $[56] = urls;
  } else {
    urls = $[56];
  }
  t21 = urls;
  const calendarUrls = t21;
  const cantCancelReason = useCantCancelBookingReason(canCancel, business?.settings.disable_changes, booking?.services, isOwner);
  let t22;
  if ($[57] !== booking.status || $[58] !== theme) {
    t22 = statusToColorScale(theme, booking.status);
    $[57] = booking.status;
    $[58] = theme;
    $[59] = t22;
  } else {
    t22 = $[59];
  }
  let t23;
  if ($[60] === Symbol.for("react.memo_cache_sentinel")) {
    t23 = [3, 0];
    $[60] = t23;
  } else {
    t23 = $[60];
  }
  const t24 = statusToMsg[booking.status];
  let t25;
  if ($[61] !== t24) {
    t25 = <CroppedText>{t24}</CroppedText>;
    $[61] = t24;
    $[62] = t25;
  } else {
    t25 = $[62];
  }
  let t26;
  if ($[63] !== t22 || $[64] !== t25) {
    t26 = <Callout color={t22} mx={t23}>{t25}</Callout>;
    $[63] = t22;
    $[64] = t25;
    $[65] = t26;
  } else {
    t26 = $[65];
  }
  let t27;
  let t28;
  if ($[66] === Symbol.for("react.memo_cache_sentinel")) {
    t27 = [`
          'content'
          'sidepanel'
        `, `
          'content sidepanel'
        `];
    t28 = ["1fr", "1fr 300px"];
    $[66] = t27;
    $[67] = t28;
  } else {
    t27 = $[66];
    t28 = $[67];
  }
  let t29;
  if ($[68] === Symbol.for("react.memo_cache_sentinel")) {
    t29 = [0, "24px"];
    $[68] = t29;
  } else {
    t29 = $[68];
  }
  let t30;
  if ($[69] !== serviceName) {
    t30 = <Heading>{serviceName}</Heading>;
    $[69] = serviceName;
    $[70] = t30;
  } else {
    t30 = $[70];
  }
  let t31;
  if ($[71] === Symbol.for("react.memo_cache_sentinel")) {
    t31 = <IconWrapper icon={faCalendar} size={1} mr={2} flexShrink={0} />;
    $[71] = t31;
  } else {
    t31 = $[71];
  }
  let t32;
  if ($[72] !== startDateTime) {
    t32 = <Flex alignItems="center">{t31}<Text>{startDateTime}</Text></Flex>;
    $[72] = startDateTime;
    $[73] = t32;
  } else {
    t32 = $[73];
  }
  let t33;
  if ($[74] !== location.city || $[75] !== location.directions || $[76] !== location.gps || $[77] !== location.postal_code || $[78] !== location.province || $[79] !== location.street_address || $[80] !== showLocation) {
    t33 = showLocation && <><Flex as="a" href={`https://maps.google.com/maps?&z=16&q=${location.gps[0]}+${location.gps[1]}&ll=${location.gps[0]}+${location.gps[1]}`} alignItems="flex-start"><IconWrapper icon={faMapMarker} size={1} mr={2} flexShrink={0} /><Text>{[...(location.street_address || "").split("\n"), location.city, location.postal_code, location.province].map(_temp13)}{!isSlateBlank(location.directions) && <><br /><Text fontWeight="heading"><FormattedMessage id="Public.BookingPage.directions" defaultMessage={`Directions`} /></Text><RenderSlate value={location.directions} /></>}</Text></Flex></>;
    $[74] = location.city;
    $[75] = location.directions;
    $[76] = location.gps;
    $[77] = location.postal_code;
    $[78] = location.province;
    $[79] = location.street_address;
    $[80] = showLocation;
    $[81] = t33;
  } else {
    t33 = $[81];
  }
  let t34;
  if ($[82] !== location) {
    t34 = <ContactDetails location={location} />;
    $[82] = location;
    $[83] = t34;
  } else {
    t34 = $[83];
  }
  let t35;
  if ($[84] !== t30 || $[85] !== t32 || $[86] !== t33 || $[87] !== t34) {
    t35 = <BorderBox pt={t29} gridArea="content"><Grid gridGap={3}>{t30}{t32}{t33}{t34}</Grid></BorderBox>;
    $[84] = t30;
    $[85] = t32;
    $[86] = t33;
    $[87] = t34;
    $[88] = t35;
  } else {
    t35 = $[88];
  }
  let t36;
  if ($[89] !== booking.status || $[90] !== hasVideoCall || $[91] !== timeToStart || $[92] !== videoCallLink || $[93] !== videoCallsEnabled) {
    t36 = booking.status === "confirmed" && videoCallsEnabled && hasVideoCall && <Button as="a" color="success" target="_blank" rel="nofollow" href={videoCallLink}><FormattedMessage id="Public.BookingPage.joinVideoCall" defaultMessage={`Join video call`} values={{
        timeToStart
      }} /></Button>;
    $[89] = booking.status;
    $[90] = hasVideoCall;
    $[91] = timeToStart;
    $[92] = videoCallLink;
    $[93] = videoCallsEnabled;
    $[94] = t36;
  } else {
    t36 = $[94];
  }
  let t37;
  if ($[95] !== booking.id || $[96] !== booking.status || $[97] !== calendarUrls) {
    t37 = booking.status === "confirmed" && <><DropdownSelect value={null} items={[{
        label: "Add to your calendar (iCal)",
        value: "ics"
      }, {
        label: "Add to Google calendar",
        value: "google"
      }, {
        label: "Add to Outlook calendar",
        value: "outlook"
      }, {
        label: "Add to Yahoo calendar",
        value: "yahoo"
      }]} itemToString={_temp14} onChange={v_0 => {
        if (v_0 == "ics") {
          window.open(`${settings.api2Root}/ical/b/${booking.id}/booking.ics`);
        } else {
          if (v_0 == "google") {
            window.open(calendarUrls.google);
          } else {
            if (v_0 == "outlook") {
              window.open(calendarUrls.outlook);
            } else {
              if (v_0 == "yahoo") {
                window.open(calendarUrls.yahoo);
              }
            }
          }
        }
      }} renderToggle={_temp15} /></>;
    $[95] = booking.id;
    $[96] = booking.status;
    $[97] = calendarUrls;
    $[98] = t37;
  } else {
    t37 = $[98];
  }
  let t38;
  if ($[99] !== booking || $[100] !== business.online) {
    t38 = business.online && <Button as={ReachLink} variant="outlined" to={`/` + (booking ? `?lid=${booking.location_id}` : "")}><FormattedMessage id="Public.BookingPage.makeAnotherBooking" defaultMessage="Make another booking" /></Button>;
    $[99] = booking;
    $[100] = business.online;
    $[101] = t38;
  } else {
    t38 = $[101];
  }
  const t39 = canCancel || booking.status == "cancelled";
  let t40;
  if ($[102] !== setShowCancelModal) {
    t40 = () => setShowCancelModal(true);
    $[102] = setShowCancelModal;
    $[103] = t40;
  } else {
    t40 = $[103];
  }
  const t41 = !canCancel;
  let t42;
  if ($[104] === Symbol.for("react.memo_cache_sentinel")) {
    t42 = <FormattedMessage id="Cancel booking" defaultMessage="Cancel booking" />;
    $[104] = t42;
  } else {
    t42 = $[104];
  }
  let t43;
  if ($[105] !== t40 || $[106] !== t41) {
    t43 = <Box><Button color="alert" variant="outlined" onClick={t40} width="100%" disabled={t41}>{t42}</Button></Box>;
    $[105] = t40;
    $[106] = t41;
    $[107] = t43;
  } else {
    t43 = $[107];
  }
  let t44;
  if ($[108] !== cantCancelReason || $[109] !== t39 || $[110] !== t43) {
    t44 = <Tippy disabled={t39} content={cantCancelReason}>{t43}</Tippy>;
    $[108] = cantCancelReason;
    $[109] = t39;
    $[110] = t43;
    $[111] = t44;
  } else {
    t44 = $[111];
  }
  let t45;
  if ($[112] !== t36 || $[113] !== t37 || $[114] !== t38 || $[115] !== t44) {
    t45 = <Grid p={3}>{t36}{t37}{t38}{t44}</Grid>;
    $[112] = t36;
    $[113] = t37;
    $[114] = t38;
    $[115] = t44;
    $[116] = t45;
  } else {
    t45 = $[116];
  }
  let t46;
  if ($[117] !== location.gps || $[118] !== showLocation) {
    t46 = showLocation && <Box px={3} py={3} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1"><Link rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/maps?&z=16&q=${location.gps[0]}+${location.gps[1]}&ll=${location.gps[0]}+${location.gps[1]}`}><Box as="img" alt="map" width="100%" src={`https://maps.google.com/maps/api/staticmap?key=${__GOOGLE_MAPS_KEY__}&center=${location.gps[0]},${location.gps[1]}&zoom=14&size=300x300&sensor=false&markers=color:red|${location.gps[0]},${location.gps[1]}`} /></Link></Box>;
    $[117] = location.gps;
    $[118] = showLocation;
    $[119] = t46;
  } else {
    t46 = $[119];
  }
  let t47;
  if ($[120] !== t45 || $[121] !== t46) {
    t47 = <Box gridArea="sidepanel"><BorderBox as={Flex} flexDirection="column" p={0}>{t45}{t46}</BorderBox></Box>;
    $[120] = t45;
    $[121] = t46;
    $[122] = t47;
  } else {
    t47 = $[122];
  }
  let t48;
  if ($[123] !== t35 || $[124] !== t47) {
    t48 = <Grid gridTemplateAreas={t27} gridTemplateColumns={t28}>{t35}{t47}</Grid>;
    $[123] = t35;
    $[124] = t47;
    $[125] = t48;
  } else {
    t48 = $[125];
  }
  let t49;
  if ($[126] !== t26 || $[127] !== t48) {
    t49 = <Box><Grid>{t26}{t48}</Grid></Box>;
    $[126] = t26;
    $[127] = t48;
    $[128] = t49;
  } else {
    t49 = $[128];
  }
  return t49;
}
function _temp15(props) {
  return <Button {...props} variant="outlined" data-sentry-element="Button" data-sentry-component="_temp15" data-sentry-source-file="view.tsx">Add to your calendar</Button>;
}
function _temp14() {
  return "";
}
function _temp13(line, idx) {
  return <Text key={idx} data-sentry-element="Text" data-sentry-component="_temp13" data-sentry-source-file="view.tsx">{line}</Text>;
}
function _temp12(state_3) {
  return state_3.businesses.data;
}
function _temp11(state_2) {
  return state_2.locations.data;
}
function _temp10(s_2) {
  return s_2.type === "on_premises";
}
function _temp9(s_1) {
  return s_1.type === "video";
}
function _temp8(a_0, b_0) {
  return a_0 < b_0 ? 1 : -1;
}
function _temp7(s_0) {
  return s_0.end;
}
function _temp6(a, b) {
  return a < b ? -1 : 1;
}
function _temp5(s) {
  return s.start;
}
function _temp4(v) {
  return v.service_name || "";
}
function _temp3(state_1) {
  return state_1.services.data;
}
function _temp2(state_0) {
  return state_0.userPreferences.language;
}
function _temp(state) {
  return state.businesses.data[state.public.businessId];
}