import { c as _c } from "react/compiler-runtime";
import React from 'react';
import Modal, { StandardModal } from '.';
// import { useStateWithRef } from '../hooks';
// import { iOS } from '../utils/detect';

export interface OpenModalFn {
  (title: string, getContent: (args: {
    close: () => void;
  } & Record<string, any>) => React.JSX.Element, options?: {
    size?: 'tiny' | 'small' | 'medium' | 'large';
    onClose?: () => void;
    leftCorner?: React.ReactNode;
    rightCorner?: React.ReactNode;
    type?: 'standard' | 'blank';
  }): () => void;
}
interface ModalToastContextInterface {
  openModal: OpenModalFn;
  modalCount: number;
  incrementModalCount: () => void;
  decrementModalCount: () => void;
}
export const ModalToastContext = React.createContext<ModalToastContextInterface | null>({
  openModal: () => null,
  modalCount: 0,
  incrementModalCount: () => null,
  decrementModalCount: () => null
});

// const isIOS = true; //iOS();

export const ModalToastProvider = t0 => {
  const $ = _c(12);
  const {
    children
  } = t0;
  const [modalCount, setModalCount] = React.useState(0);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = [];
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const [modalStack, setModalStack] = React.useState(t1);
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = (title, getContent, t3) => {
      const {
        size,
        onClose,
        leftCorner,
        rightCorner,
        type: t4
      } = t3;
      const type = t4 === undefined ? "standard" : t4;
      const randomId = Math.random().toString(36).substring(7);
      const close = () => {
        onClose?.();
        setModalStack(v => v.filter(m => m.id !== randomId));
      };
      const isDirtyRef = React.createRef();
      const backdropRef = React.createRef();
      const hasUnsavedChanges = () => isDirtyRef.current;
      const modalItem = {
        id: randomId,
        title,
        content: getContent({
          close,
          isDirtyRef,
          backdropRef
        }),
        size,
        close,
        leftCorner,
        rightCorner,
        hasUnsavedChanges,
        backdropRef,
        type
      };
      setModalStack(v_0 => [...v_0, modalItem]);
      return close;
    };
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const openGlobalModal = t2;
  let t3;
  let t4;
  if ($[2] !== modalStack) {
    t4 = modalStack.map(_temp2);
    $[2] = modalStack;
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  t3 = t4;
  const modals = t3;
  let t5;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = () => {
      setModalCount(_temp3);
    };
    $[4] = t5;
  } else {
    t5 = $[4];
  }
  const incrementModalCount = t5;
  let t6;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = () => {
      setModalCount(_temp4);
    };
    $[5] = t6;
  } else {
    t6 = $[5];
  }
  const decrementModalCount = t6;
  let t7;
  const t8 = modalCount + modalStack.length;
  let t9;
  if ($[6] !== t8) {
    t9 = {
      openModal: openGlobalModal,
      modalCount: t8,
      incrementModalCount,
      decrementModalCount
    };
    $[6] = t8;
    $[7] = t9;
  } else {
    t9 = $[7];
  }
  t7 = t9;
  const value = t7;
  let t10;
  if ($[8] !== children || $[9] !== modals || $[10] !== value) {
    t10 = <ModalToastContext.Provider value={value}>{children}{modals}</ModalToastContext.Provider>;
    $[8] = children;
    $[9] = modals;
    $[10] = value;
    $[11] = t10;
  } else {
    t10 = $[11];
  }
  return t10;
};
function _temp(t0) {
  const {
    content: content_0
  } = t0;
  return content_0;
}
function _temp2(t0) {
  const {
    id,
    title: title_0,
    content,
    size: size_0,
    close: close_0,
    leftCorner: leftCorner_0,
    rightCorner: rightCorner_0,
    hasUnsavedChanges: hasUnsavedChanges_0,
    backdropRef: backdropRef_0,
    type: type_0
  } = t0;
  const ModalComponent = type_0 === "blank" ? Modal : StandardModal;
  return <ModalComponent key={id} isOpen={true} close={close_0} title={title_0} props={{
    content
  }} size={size_0} leftCorner={leftCorner_0} rightCorner={rightCorner_0} hasUnsavedChanges={hasUnsavedChanges_0} backdropRef={backdropRef_0} data-sentry-element="ModalComponent" data-sentry-component="_temp2" data-sentry-source-file="context.tsx">{_temp}</ModalComponent>;
}
function _temp3(v_1) {
  return v_1 + 1;
}
function _temp4(v_2) {
  return v_2 - 1;
}