import { c as _c } from "react/compiler-runtime";
import { Theme, ThemeContext } from '@emotion/react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Grid } from 'shared/grid';
import { removeToast } from 'shared/toaster/redux/actions';
import { Text } from 'shared/typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ModalToastContext } from '../modal/context';
const Toaster = t0 => {
  const $ = _c(27);
  const {
    width: t1,
    topOffset: t2,
    inModal: t3
  } = t0;
  const width = t1 === undefined ? "100%" : t1;
  const topOffset = t2 === undefined ? 0 : t2;
  const inModal = t3 === undefined ? false : t3;
  const {
    modalCount
  } = React.useContext(ModalToastContext);
  const dispatch = useDispatch();
  const toasts = useSelector(_temp);
  const theme = React.useContext(ThemeContext as any);
  const isDark = theme.mode === "dark";
  let t4;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      success: "green",
      warning: "yellow",
      alert: "red",
      primary: "primary_"
    };
    $[0] = t4;
  } else {
    t4 = $[0];
  }
  const colorScales = t4;
  if (!inModal && modalCount > 0) {
    return null;
  }
  const t5 = inModal ? 0 : theme.topbar.height + topOffset;
  let t6;
  if ($[1] !== t5 || $[2] !== width) {
    t6 = {
      zIndex: 6007,
      top: t5,
      position: "fixed",
      width,
      pointerEvents: "none"
    };
    $[1] = t5;
    $[2] = width;
    $[3] = t6;
  } else {
    t6 = $[3];
  }
  let t7;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = {
      maxWidth: "700px"
    };
    $[4] = t7;
  } else {
    t7 = $[4];
  }
  let t8;
  if ($[5] !== dispatch || $[6] !== isDark || $[7] !== theme.colors || $[8] !== theme.fontWeights.heading || $[9] !== theme.fonts.heading || $[10] !== theme.radii || $[11] !== theme.shadows.large || $[12] !== toasts) {
    let t9;
    if ($[14] !== dispatch || $[15] !== isDark || $[16] !== theme.colors || $[17] !== theme.fontWeights.heading || $[18] !== theme.fonts.heading || $[19] !== theme.radii || $[20] !== theme.shadows.large) {
      t9 = (toast, idx) => {
        const colorScale = colorScales[toast.className || "success"];
        return <Text p={3} sx={{
          pointerEvents: "all",
          textAlign: "center",
          boxShadow: theme.shadows.large,
          fontWeight: theme.fontWeights.heading,
          fontFamily: theme.fonts.heading,
          borderRadius: `${theme.radii[2]}px`,
          backgroundColor: isDark ? `gray.2` : `${colorScale}.5`,
          border: `2px solid ${theme.colors[colorScale][5]}`,
          color: `white`
        }} key={idx}><Box as="button" onClick={() => dispatch(removeToast(toast))} className="toaster-close-button" zIndex={1} sx={{
            lineHeight: "1.5rem",
            float: "right",
            fontSize: "2rem",
            textDecoration: "none",
            cursor: "pointer"
          }}><FontAwesomeIcon size="xs" icon={faTimes} /></Box>{toast.body}</Text>;
      };
      $[14] = dispatch;
      $[15] = isDark;
      $[16] = theme.colors;
      $[17] = theme.fontWeights.heading;
      $[18] = theme.fonts.heading;
      $[19] = theme.radii;
      $[20] = theme.shadows.large;
      $[21] = t9;
    } else {
      t9 = $[21];
    }
    t8 = toasts.map(t9);
    $[5] = dispatch;
    $[6] = isDark;
    $[7] = theme.colors;
    $[8] = theme.fontWeights.heading;
    $[9] = theme.fonts.heading;
    $[10] = theme.radii;
    $[11] = theme.shadows.large;
    $[12] = toasts;
    $[13] = t8;
  } else {
    t8 = $[13];
  }
  let t9;
  if ($[22] !== t8) {
    t9 = <Grid flex="1" sx={t7} p={2}>{t8}</Grid>;
    $[22] = t8;
    $[23] = t9;
  } else {
    t9 = $[23];
  }
  let t10;
  if ($[24] !== t6 || $[25] !== t9) {
    t10 = <Flex flex="1" justifyContent="center" sx={t6}>{t9}</Flex>;
    $[24] = t6;
    $[25] = t9;
    $[26] = t10;
  } else {
    t10 = $[26];
  }
  return t10;
};
export default Toaster;
function _temp(state) {
  return state.toaster.toasts;
}