import { c as _c } from "react/compiler-runtime";
import Color from 'color';
import React from 'react';
import { Box, BoxProps } from 'shared/grid';
import FormError from '../form-error';
import { invalidInput } from '../input-style';
import Label from '../label';
import { Theme, ThemeContext } from '@emotion/react';
interface ExtraStyledSelectProps {
  invalid?: boolean;
}
type StyledSelectProps = Omit<React.HTMLProps<HTMLSelectElement>, keyof BoxProps> & ExtraStyledSelectProps & BoxProps;
export const selectArrowStyle = theme => ({
  backgroundImage: `url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='16' viewBox='0 0 32 16'><polygon points='0,0 32,0 16,16' style='fill: ${Color(theme.colors.text).rgb().string().replace('%', '%29')}'></polygon></svg>")`,
  backgroundOrigin: 'content-box',
  backgroundPosition: `right -${16}px center`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '9px 6px'
});
export function StyledSelect(t0) {
  const $ = _c(19);
  let invalid;
  let props;
  let ref;
  let t1;
  if ($[0] !== t0) {
    ({
      ref,
      invalid,
      as: t1,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = invalid;
    $[2] = props;
    $[3] = ref;
    $[4] = t1;
  } else {
    invalid = $[1];
    props = $[2];
    ref = $[3];
    t1 = $[4];
  }
  const as = t1 === undefined ? "select" : t1;
  const theme = React.useContext(ThemeContext as any);
  let t2;
  if ($[5] !== invalid) {
    t2 = invalid ? invalidInput : {};
    $[5] = invalid;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  let t3;
  let t4;
  if ($[7] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = {
      display: "none"
    };
    t4 = {
      height: "auto",
      backgroundImage: "none"
    };
    $[7] = t3;
    $[8] = t4;
  } else {
    t3 = $[7];
    t4 = $[8];
  }
  let t5;
  if ($[9] !== theme) {
    t5 = selectArrowStyle(theme);
    $[9] = theme;
    $[10] = t5;
  } else {
    t5 = $[10];
  }
  let t6;
  if ($[11] !== t2 || $[12] !== t5) {
    t6 = {
      ...t2,
      "&::-ms-expand": t3,
      "&[multiple]": t4,
      paddingRight: `${32}px !important`,
      fontSize: 2,
      ...t5
    };
    $[11] = t2;
    $[12] = t5;
    $[13] = t6;
  } else {
    t6 = $[13];
  }
  let t7;
  if ($[14] !== as || $[15] !== props || $[16] !== ref || $[17] !== t6) {
    t7 = <Box ref={ref} tx="inputs" variant="default" as={as} __css={t6} {...props} />;
    $[14] = as;
    $[15] = props;
    $[16] = ref;
    $[17] = t6;
    $[18] = t7;
  } else {
    t7 = $[18];
  }
  return t7;
}
type SelectProps = {
  label?: any;
  error?: string;
} & React.InputHTMLAttributes<HTMLSelectElement> & React.LabelHTMLAttributes<HTMLLabelElement> & Omit<StyledSelectProps, 'label'>;
export function Select(t0) {
  const $ = _c(25);
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  const [isFocused, setFocus] = React.useState(false);
  const {
    label,
    onFocus,
    onBlur,
    error,
    invalid
  } = props;
  let t1;
  if ($[3] !== error || $[4] !== invalid || $[5] !== isFocused || $[6] !== label || $[7] !== props.label) {
    t1 = props.label && <Label mb={2} invalid={invalid || !!error} isFocused={isFocused}>{label}</Label>;
    $[3] = error;
    $[4] = invalid;
    $[5] = isFocused;
    $[6] = label;
    $[7] = props.label;
    $[8] = t1;
  } else {
    t1 = $[8];
  }
  const t2 = invalid || !!error;
  let t3;
  if ($[9] !== onFocus) {
    t3 = e => {
      setFocus(true);
      if (onFocus) {
        onFocus(e);
      }
    };
    $[9] = onFocus;
    $[10] = t3;
  } else {
    t3 = $[10];
  }
  let t4;
  if ($[11] !== onBlur) {
    t4 = e_0 => {
      setFocus(false);
      if (onBlur) {
        onBlur(e_0);
      }
    };
    $[11] = onBlur;
    $[12] = t4;
  } else {
    t4 = $[12];
  }
  let t5;
  if ($[13] !== props || $[14] !== ref || $[15] !== t2 || $[16] !== t3 || $[17] !== t4) {
    t5 = <StyledSelect ref={ref} {...props} invalid={t2} onFocus={t3} onBlur={t4} />;
    $[13] = props;
    $[14] = ref;
    $[15] = t2;
    $[16] = t3;
    $[17] = t4;
    $[18] = t5;
  } else {
    t5 = $[18];
  }
  let t6;
  if ($[19] !== error) {
    t6 = error && <FormError>{error}</FormError>;
    $[19] = error;
    $[20] = t6;
  } else {
    t6 = $[20];
  }
  let t7;
  if ($[21] !== t1 || $[22] !== t5 || $[23] !== t6) {
    t7 = <>{t1}{t5}{t6}</>;
    $[21] = t1;
    $[22] = t5;
    $[23] = t6;
    $[24] = t7;
  } else {
    t7 = $[24];
  }
  return t7;
}
export default Select;