import { c as _c } from "react/compiler-runtime";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { getDarkColor } from '../utils/color';
import { BoxProps, Flex } from '../grid';
import { getColorHash, getLetters, hashCode } from './shared';
interface AvatarProps {
  name?: string;
  image?: string;
  email?: string;
  size?: number;
  icon?: IconProp;
  color?: string;
}
export function Avatar(t0) {
  const $ = _c(35);
  let ref;
  let rest;
  let t1;
  let t2;
  let t3;
  let t4;
  let t5;
  let t6;
  let t7;
  if ($[0] !== t0) {
    ({
      ref,
      name: t1,
      image: t2,
      email: t3,
      size: t4,
      icon: t5,
      color: t6,
      sx: t7,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = ref;
    $[2] = rest;
    $[3] = t1;
    $[4] = t2;
    $[5] = t3;
    $[6] = t4;
    $[7] = t5;
    $[8] = t6;
    $[9] = t7;
  } else {
    ref = $[1];
    rest = $[2];
    t1 = $[3];
    t2 = $[4];
    t3 = $[5];
    t4 = $[6];
    t5 = $[7];
    t6 = $[8];
    t7 = $[9];
  }
  const name = t1 === undefined ? "" : t1;
  const imageUrl = t2 === undefined ? "" : t2;
  const email = t3 === undefined ? "" : t3;
  const size = t4 === undefined ? 24 : t4;
  const icon = t5 === undefined ? null : t5;
  const color = t6 === undefined ? null : t6;
  const sx = t7 === undefined ? null : t7;
  let t8;
  if ($[10] !== name) {
    t8 = getLetters(name) || name.substring(0, 2);
    $[10] = name;
    $[11] = t8;
  } else {
    t8 = $[11];
  }
  const letters = t8;
  let t9;
  if ($[12] !== color || $[13] !== name) {
    const hash = getColorHash(name);
    t9 = color || getDarkColor(hash);
    $[12] = color;
    $[13] = name;
    $[14] = t9;
  } else {
    t9 = $[14];
  }
  const bg = t9;
  let gravatarUrl = "";
  if (email) {
    gravatarUrl = `https://www.gravatar.com/avatar/${email}?d=404`;
  }
  const hasImage = imageUrl;
  const t10 = `${0.45 * size}px`;
  const t11 = `${size}px`;
  const t12 = `${size}px`;
  const t13 = !hasImage ? bg : "background";
  let t14;
  if ($[15] !== gravatarUrl || $[16] !== hasImage || $[17] !== imageUrl) {
    t14 = hasImage ? {
      backgroundPosition: "center",
      backgroundImage: `${`url(${imageUrl})`} ${gravatarUrl ? `, url(${gravatarUrl})` : ""}`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      borderColor: "gray.1",
      borderStyle: "solid",
      borderWidth: "1px",
      boxShadow: "medium"
    } : {};
    $[15] = gravatarUrl;
    $[16] = hasImage;
    $[17] = imageUrl;
    $[18] = t14;
  } else {
    t14 = $[18];
  }
  let t15;
  if ($[19] !== sx || $[20] !== t10 || $[21] !== t11 || $[22] !== t12 || $[23] !== t13 || $[24] !== t14) {
    t15 = {
      fontSize: t10,
      fontWeight: 600,
      fontFamily: "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
      width: t11,
      height: t12,
      borderRadius: "9999px",
      backgroundColor: t13,
      color: "white",
      overflow: "hidden",
      objectFit: "cover",
      ...t14,
      ...sx
    };
    $[19] = sx;
    $[20] = t10;
    $[21] = t11;
    $[22] = t12;
    $[23] = t13;
    $[24] = t14;
    $[25] = t15;
  } else {
    t15 = $[25];
  }
  let t16;
  if ($[26] !== hasImage || $[27] !== icon || $[28] !== letters) {
    t16 = !hasImage && (icon ? <FontAwesomeIcon icon={icon} fixedWidth={true} /> : letters);
    $[26] = hasImage;
    $[27] = icon;
    $[28] = letters;
    $[29] = t16;
  } else {
    t16 = $[29];
  }
  let t17;
  if ($[30] !== ref || $[31] !== rest || $[32] !== t15 || $[33] !== t16) {
    t17 = <Flex ref={ref} alignItems="center" justifyContent="center" flexShrink={0} sx={t15} {...rest}>{t16}</Flex>;
    $[30] = ref;
    $[31] = rest;
    $[32] = t15;
    $[33] = t16;
    $[34] = t17;
  } else {
    t17 = $[34];
  }
  return t17;
}