import { c as _c } from "react/compiler-runtime";
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import { Box, BoxProps, Flex } from 'shared/grid';
import IconWrapper from 'shared/icon-wrapper';
import { Heading } from 'shared/typography';
export const BorderBox = ({
  children,
  ...rest
}: BoxProps) => <Box bg="background" borderRadius={2} borderWidth="1px" borderStyle="solid" borderColor={['background', 'borderColor']} p={'24px'} {...rest} data-sentry-element="Box" data-sentry-component="BorderBox" data-sentry-source-file="shared.tsx">
    {children}
  </Box>;
export const BookStepHeading = t0 => {
  const $ = _c(8);
  const {
    children,
    backTo
  } = t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      gap: 3
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  let t2;
  if ($[1] !== backTo) {
    t2 = <IconWrapper flexShrink={0} as={ReachLink} to={backTo} icon={faArrowLeft} size={1} />;
    $[1] = backTo;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== children) {
    t3 = <Heading fontSize={3}>{children}</Heading>;
    $[3] = children;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] !== t2 || $[6] !== t3) {
    t4 = <Flex alignItems="center" sx={t1}>{t2}{t3}</Flex>;
    $[5] = t2;
    $[6] = t3;
    $[7] = t4;
  } else {
    t4 = $[7];
  }
  return t4;
};