import { c as _c } from "react/compiler-runtime";
import { DateTime } from 'luxon';
import React from 'react';
export function useMergeRefs<Instance>(refs: Array<React.Ref<Instance> | undefined>): React.RefCallback<Instance> | null {
  return React.useMemo(() => {
    if (refs.every(ref => ref == null)) {
      return null;
    }
    return value => {
      refs.forEach(ref => {
        if (typeof ref === 'function') {
          ref(value);
        } else if (ref != null) {
          (ref as React.RefObject<Instance | null>).current = value;
        }
      });

      // Clean up function
      return () => {
        refs.forEach(ref => {
          if (typeof ref === 'function') {
            ref(null);
          } else if (ref != null) {
            (ref as React.RefObject<Instance | null>).current = null;
          }
        });
      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, refs);
}
export const useDebounce = (value_, t0) => {
  const $ = _c(5);
  const delay = t0 === undefined ? 100 : t0;
  const [value, setValue_] = React.useState(value_);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = v => {
      React.startTransition(() => {
        setValue_(v);
      });
    };
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const setValue = t1;
  let t2;
  let t3;
  if ($[1] !== delay || $[2] !== value_) {
    t2 = () => {
      const id = setTimeout(() => setValue(value_), delay);
      return () => clearTimeout(id);
    };
    t3 = [value_, delay, setValue];
    $[1] = delay;
    $[2] = value_;
    $[3] = t2;
    $[4] = t3;
  } else {
    t2 = $[3];
    t3 = $[4];
  }
  React.useEffect(t2, t3);
  return value;
};
export function usePrevious<T>(value: T): T {
  const ref = React.useRef<T>(null);
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
export function usePreviousIfFalsy(value_, usePrev, t0) {
  const $ = _c(5);
  const timeout = t0 === undefined ? 1000 : t0;
  const [value, setValue] = React.useState(value_);
  let t1;
  let t2;
  if ($[0] !== timeout || $[1] !== usePrev || $[2] !== value_) {
    t1 = () => {
      if (!usePrev) {
        setValue(value_);
        const id = setTimeout(() => setValue(value_), timeout);
        return () => clearTimeout(id);
      }
    };
    t2 = [timeout, usePrev, value_];
    $[0] = timeout;
    $[1] = usePrev;
    $[2] = value_;
    $[3] = t1;
    $[4] = t2;
  } else {
    t1 = $[3];
    t2 = $[4];
  }
  React.useEffect(t1, t2);
  return usePrev ? value : value_;
}
export function useIsWindowFocused() {
  const $ = _c(3);
  const [isFocused, setIsFocused] = React.useState(true);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = () => {
      setIsFocused(true);
    };
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const setFocused = t0;
  let t1;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => {
      setIsFocused(false);
    };
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const setBlurred = t1;
  let t2;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = () => {
      window.addEventListener("focus", setFocused);
      window.addEventListener("blur", setBlurred);
      return () => {
        window.removeEventListener("focus", setFocused);
        window.removeEventListener("blur", setBlurred);
      };
    };
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  React.useEffect(t2);
  return isFocused;
}
async function checkIfOnline() {
  if (typeof navigator === 'undefined') {
    return true;
  }
  if (!window.navigator.onLine) return false;

  // avoid CORS errors with a request to your own origin
  const url = new URL(window.location.origin);

  // random value to prevent cached responses
  url.searchParams.set('rand', Math.random().toString(36).substring(2, 15));
  try {
    const response = await fetch(url.toString(), {
      method: 'HEAD'
    });
    return response.ok;
  } catch {
    return false;
  }
}
export function useIsOnline() {
  const $ = _c(8);
  const isFocused = useIsWindowFocused();
  const [isOnline, setIsOnline] = React.useState(true);
  let t0;
  if ($[0] !== isFocused) {
    t0 = async () => {
      if (!isFocused) {
        return;
      }
      const result = await checkIfOnline();
      setIsOnline(result);
    };
    $[0] = isFocused;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const setStatus = t0;
  let t1;
  let t2;
  if ($[2] !== setStatus) {
    t1 = () => {
      const id = setInterval(setStatus, 10000);
      return () => clearInterval(id);
    };
    t2 = [setStatus];
    $[2] = setStatus;
    $[3] = t1;
    $[4] = t2;
  } else {
    t1 = $[3];
    t2 = $[4];
  }
  React.useEffect(t1, t2);
  let t3;
  let t4;
  if ($[5] !== setStatus) {
    t3 = () => {
      window.addEventListener("online", setStatus);
      window.addEventListener("offline", setStatus);
      return () => {
        window.removeEventListener("online", setStatus);
        window.removeEventListener("offline", setStatus);
      };
    };
    t4 = [setStatus];
    $[5] = setStatus;
    $[6] = t3;
    $[7] = t4;
  } else {
    t3 = $[6];
    t4 = $[7];
  }
  React.useEffect(t3, t4);
  return isOnline;
}
export function useCurrentDateTime(t0, t1) {
  const $ = _c(8);
  const interval = t0 === undefined ? 1000 : t0;
  const timezone = t1 === undefined ? "UTC" : t1;
  let t2;
  if ($[0] !== timezone) {
    t2 = () => DateTime.utc().setZone(timezone);
    $[0] = timezone;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const getTime = t2;
  let t3;
  if ($[2] !== getTime) {
    t3 = getTime();
    $[2] = getTime;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const [dt, setDt] = React.useState(t3);
  let t4;
  let t5;
  if ($[4] !== getTime || $[5] !== interval) {
    t4 = () => {
      const intervalIdRef = {
        current: null
      };
      const timeoutId = setTimeout(() => {
        intervalIdRef.current = setInterval(() => {
          setDt(getTime());
        }, interval);
      }, 1000 - new Date().getMilliseconds());
      return () => {
        clearTimeout(timeoutId);
        clearInterval(intervalIdRef.current);
      };
    };
    t5 = [getTime, interval];
    $[4] = getTime;
    $[5] = interval;
    $[6] = t4;
    $[7] = t5;
  } else {
    t4 = $[6];
    t5 = $[7];
  }
  React.useEffect(t4, t5);
  return dt;
}
export function useStateWithRef(initialState) {
  const $ = _c(3);
  const [state, setState_] = React.useState(initialState);
  const stateRef = React.useRef(initialState);
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = stateOrFn => {
      if (typeof stateOrFn === "function") {
        const newState = stateOrFn(stateRef.current);
        setState_(newState);
        stateRef.current = newState;
      } else {
        setState_(stateOrFn);
        stateRef.current = stateOrFn;
      }
    };
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const setState = t0;
  let t1;
  if ($[1] !== state) {
    t1 = [state, stateRef, setState];
    $[1] = state;
    $[2] = t1;
  } else {
    t1 = $[2];
  }
  return t1 as [S, React.RefObject<S>, typeof setState_];
}

// export function useStateWithRef<S>(initialState: S) {
//   const [state, setState_] = React.useState<S>(initialState);
//   const stateRef = React.useRef<S>(initialState);

//   const setState = React.useCallback((stateOrFn) => {
//     if (typeof stateOrFn === 'function') {
//       setState_((oldState) => {
//         const newState = stateOrFn(oldState);
//         stateRef.current = newState;
//         return newState;
//       });
//     } else {
//       setState_(stateOrFn);
//       stateRef.current = stateOrFn;
//     }
//   }, []);

//   return [state, stateRef, setState] as [
//     S,
//     React.MutableRefObject<S>,
//     typeof setState_
//   ];
// }

export const useNowDateTime = t0 => {
  const $ = _c(4);
  const interval = t0 === undefined ? 60000 : t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = DateTime.utc();
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const [now, setNow] = React.useState(t1);
  let t2;
  let t3;
  if ($[1] !== interval) {
    t2 = () => {
      const timeoutId = setInterval(() => {
        setNow(DateTime.utc());
      }, interval);
      return () => clearInterval(timeoutId);
    };
    t3 = [interval];
    $[1] = interval;
    $[2] = t2;
    $[3] = t3;
  } else {
    t2 = $[2];
    t3 = $[3];
  }
  React.useEffect(t2, t3);
  return now;
};