import { c as _c } from "react/compiler-runtime";
import { faChevronDown, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import React from 'react';
import { Link as ReachLink } from 'react-router-dom';
import axios from 'shared/anaxios';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Box, Flex, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import settings from 'shared/settings';
import { CroppedText, Heading, Link, Text } from 'shared/typography';
import { toBase58 } from 'shared/utils/b58';
import { currencyFormat } from 'shared/utils/currency';
import { useAppSelector } from '../../store';
import { BorderBox } from '../shared';
import { formPost, payfastRedirect, paymentMeta } from 'shared/payments';
import { useLocationId } from '../../hooks';
import { useParams } from 'react-router';
const PayAccount = () => {
  const $ = _c(34);
  const [paymentData, setPaymentData] = React.useState(null);
  const [openAccordion, setOpenAccordion] = React.useState(null);
  const [, setIsProcessingPayment] = React.useState(false);
  const [locationId] = useLocationId();
  const {
    clientId
  } = useParams();
  const [invoiceStatus, setInvoiceStatus] = React.useState("loading");
  const business = useAppSelector(_temp);
  let t0;
  let t1;
  if ($[0] !== clientId || $[1] !== locationId) {
    t0 = () => {
      axios.get(`${settings.api2Root}/statement-payment-data/${clientId}`, {
        params: {
          location_id: locationId || undefined
        }
      }).then(t2 => {
        const {
          data
        } = t2;
        setPaymentData(data);
        setInvoiceStatus("done");
      }).catch(() => {
        setInvoiceStatus("notfound");
      });
    };
    t1 = [clientId, locationId];
    $[0] = clientId;
    $[1] = locationId;
    $[2] = t0;
    $[3] = t1;
  } else {
    t0 = $[2];
    t1 = $[3];
  }
  React.useEffect(t0, t1);
  if (invoiceStatus === "loading") {
    let t2;
    if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
      t2 = <Loader />;
      $[4] = t2;
    } else {
      t2 = $[4];
    }
    return t2;
  } else {
    if (invoiceStatus === "notfound") {
      let t2;
      if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
        t2 = <EmptyState heading="Invoice not found" />;
        $[5] = t2;
      } else {
        t2 = $[5];
      }
      return t2;
    }
  }
  const {
    total_due,
    currency,
    payment_methods
  } = paymentData;
  let t2;
  let t3;
  if ($[6] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = [`
          'summary'
          'methods'
        `, `
          'methods summary'
        `];
    t3 = ["1fr", "1fr 300px"];
    $[6] = t2;
    $[7] = t3;
  } else {
    t2 = $[6];
    t3 = $[7];
  }
  let t4;
  if ($[8] !== business || $[9] !== clientId || $[10] !== currency || $[11] !== locationId || $[12] !== openAccordion || $[13] !== payment_methods || $[14] !== total_due) {
    t4 = total_due > 0 ? <Box gridArea="methods"><BorderBox pt={0}>{payment_methods.some(_temp2) ? <><Box px={3} py={4} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1"><Heading fontSize={2} fontWeight={600}>Choose a payment method</Heading></Box>{payment_methods.filter(_temp3).filter(_temp4).map((t5, idx) => {
            const {
              id,
              provider_data: t6,
              type,
              instructions
            } = t5;
            const {
              form_data,
              host,
              qrcode_url,
              qrcode_svg_url,
              client_id,
              payment_url
            } = t6;
            return <Box key={idx}><Grid p={3} gridGap={3} gridTemplateColumns="auto 1fr auto" sx={{
                "&:hover, &:active": {
                  backgroundColor: "gray.1"
                },
                cursor: "pointer"
              }} onClick={() => {
                if (type === "payfast") {
                  setOpenAccordion(null);
                  payfastRedirect(host, form_data);
                } else {
                  if (type == "wigwag") {
                    formPost(payment_url, {
                      location_id: locationId,
                      client_id: clientId,
                      payment_method_id: id
                    });
                  } else {
                    if (type == "yoco") {
                      formPost(payment_url, {
                        location_id: locationId,
                        client_id: clientId,
                        payment_method_id: id
                      });
                    } else {
                      setOpenAccordion(openAccordion === idx ? null : idx);
                    }
                  }
                }
              }}><Flex alignItems="center">{paymentMeta[type].icon}</Flex><Flex alignItems="center"><CroppedText fontFamily="heading" fontSize={2}>{paymentMeta[type].name}</CroppedText></Flex><Flex alignItems="center">{paymentMeta[type].inPage && <FontAwesomeIcon icon={openAccordion === idx ? faChevronDown : faChevronRight} />}</Flex></Grid><PayPalScriptProvider options={{
                clientId: client_id,
                currency,
                intent: "capture"
              }}>{openAccordion === idx && type == "paypal" && <Box p={3}><PayPalButtons style={{
                    layout: "horizontal"
                  }} createOrder={(data_0, actions) => {
                    setIsProcessingPayment(true);
                    return actions.order.create({
                      intent: "CAPTURE",
                      purchase_units: [{
                        amount: {
                          currency_code: currency,
                          value: total_due.toString()
                        }
                      }],
                      application_context: {
                        shipping_preference: "NO_SHIPPING"
                      }
                    });
                  }} onApprove={(details, data_1) => {
                    setIsProcessingPayment(true);
                    return axios.post(`${settings.api2Root}/client-paypal-transaction-complete/${id}`, {
                      order_id: details.orderID,
                      client_id: clientId,
                      location_id: locationId
                    }).then(() => {
                      setTimeout(() => {
                        axios.get(`${settings.api2Root}/statement-payment-data/${clientId}`, {
                          params: {
                            location_id: locationId || undefined
                          }
                        }).then(t7 => {
                          const {
                            data: data_2
                          } = t7;
                          setPaymentData(data_2);
                          setIsProcessingPayment(false);
                        });
                      }, 2000);
                    }).catch(() => setIsProcessingPayment(false));
                  }} /></Box>}</PayPalScriptProvider>{openAccordion === idx && type == "snapscan" && <Box p={3}><Link href={qrcode_url}><img alt="qr code" src={qrcode_svg_url} /></Link></Box>}{openAccordion === idx && type == "eft" && <Grid p={3}>{instructions.split("\n").map(_temp5)}</Grid>}</Box>;
          })}</> : <EmptyState minHeight="283px" heading={`${business.name} is not accepting online payments at the
                moment.`} callToAction={<Text>Please view your invoice for further instructions or{" "}<Link as={ReachLink} to={`/contact`}>contact us</Link></Text>} />}</BorderBox></Box> : <Box gridArea="methods"><BorderBox textAlign="center"><CroppedText fontSize={2}>Your account has no outstanding invoices. Thank you.</CroppedText></BorderBox></Box>;
    $[8] = business;
    $[9] = clientId;
    $[10] = currency;
    $[11] = locationId;
    $[12] = openAccordion;
    $[13] = payment_methods;
    $[14] = total_due;
    $[15] = t4;
  } else {
    t4 = $[15];
  }
  let t5;
  if ($[16] === Symbol.for("react.memo_cache_sentinel")) {
    t5 = <Box px={3} py={4} borderBottomWidth="1px" borderBottomStyle="solid" borderBottomColor="gray.1"><Heading fontSize={2} fontWeight={600}>Statement summary</Heading></Box>;
    $[16] = t5;
  } else {
    t5 = $[16];
  }
  let t6;
  if ($[17] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = <CroppedText fontWeight={600}>Total due</CroppedText>;
    $[17] = t6;
  } else {
    t6 = $[17];
  }
  let t7;
  if ($[18] !== currency || $[19] !== total_due) {
    t7 = currencyFormat(total_due, currency);
    $[18] = currency;
    $[19] = total_due;
    $[20] = t7;
  } else {
    t7 = $[20];
  }
  let t8;
  if ($[21] !== t7) {
    t8 = <Flex justifyContent="space-between" px={3} py={4}>{t6}<CroppedText fontWeight={600}>{t7}</CroppedText></Flex>;
    $[21] = t7;
    $[22] = t8;
  } else {
    t8 = $[22];
  }
  let t9;
  if ($[23] !== clientId || $[24] !== locationId) {
    t9 = settings.bypassPWAScope(`/s/${toBase58(clientId)}?download=false` + (locationId ? `&location_id=${locationId}` : ""));
    $[23] = clientId;
    $[24] = locationId;
    $[25] = t9;
  } else {
    t9 = $[25];
  }
  let t10;
  if ($[26] !== t9) {
    t10 = <Box p={3} borderTopWidth="1px" borderTopStyle="solid" borderTopColor="gray.1"><Button as="a" variant="outlined" color="gray" rel="noopener noreferrer" target="_blank" href={t9}>View statement</Button></Box>;
    $[26] = t9;
    $[27] = t10;
  } else {
    t10 = $[27];
  }
  let t11;
  if ($[28] !== t10 || $[29] !== t8) {
    t11 = <Box gridArea="summary"><BorderBox p={0}>{t5}{t8}{t10}</BorderBox></Box>;
    $[28] = t10;
    $[29] = t8;
    $[30] = t11;
  } else {
    t11 = $[30];
  }
  let t12;
  if ($[31] !== t11 || $[32] !== t4) {
    t12 = <Grid gridTemplateAreas={t2} gridTemplateColumns={t3}>{t4}{t11}</Grid>;
    $[31] = t11;
    $[32] = t4;
    $[33] = t12;
  } else {
    t12 = $[33];
  }
  return t12;
};
export default PayAccount;
function _temp(state) {
  return state.businesses.data[state.public.businessId];
}
function _temp2(p_1) {
  return paymentMeta[p_1.type]?.integrated;
}
function _temp3(p) {
  return p.show_on_invoice;
}
function _temp4(p_0) {
  return paymentMeta[p_0.type]?.integrated;
}
function _temp5(v, idx_0) {
  return <CroppedText key={idx_0} data-sentry-element="CroppedText" data-sentry-component="_temp5" data-sentry-source-file="component.tsx">{v}</CroppedText>;
}