import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Field } from 'react-final-form';
import Input from '../../input';
import { Heading as Heading_ } from 'shared/typography';
import { Flex, Box, Grid } from 'shared/grid';
import Switch from '../../switch';
const Heading = t0 => {
  const $ = _c(5);
  const {
    schema,
    menu
  } = t0;
  let t1;
  if ($[0] !== schema.value) {
    t1 = <Box flex="1"><Heading_ as="h2" fontSize={4}>{schema.value}</Heading_></Box>;
    $[0] = schema.value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== menu || $[3] !== t1) {
    t2 = <Flex justifyContent="space-between">{t1}{menu}</Flex>;
    $[2] = menu;
    $[3] = t1;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  return t2;
};
export default Heading;
export const toString = value => {
  return '';
};
export const View = t0 => {
  const $ = _c(2);
  const {
    schema
  } = t0;
  let t1;
  if ($[0] !== schema.value) {
    t1 = <Box>{schema.value}</Box>;
    $[0] = schema.value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  return t1;
};
export const Edit = t0 => {
  const $ = _c(6);
  const {
    isEditing,
    className,
    value,
    menu,
    style
  } = t0;
  let t1;
  if ($[0] !== className || $[1] !== isEditing || $[2] !== menu || $[3] !== style || $[4] !== value) {
    t1 = <>{!isEditing ? <Heading schema={value} menu={menu} style={style} className={className} /> : <Grid sx={{
        display: !isEditing ? "none" : null
      }}><Field name={"" ? `${""}.value` : "value"} validate={_temp}>{_temp2}</Field><Field name="show_on_print">{_temp4}</Field></Grid>}</>;
    $[0] = className;
    $[1] = isEditing;
    $[2] = menu;
    $[3] = style;
    $[4] = value;
    $[5] = t1;
  } else {
    t1 = $[5];
  }
  return t1;
};
function _temp(value_0) {
  if (!value_0) {
    return "Enter a some heading text";
  }
}
function _temp2(t0) {
  const {
    input,
    meta
  } = t0;
  return <Box data-sentry-element="Box" data-sentry-component="_temp2" data-sentry-source-file="index.tsx"><Input {...input} label="Heading text" type="text" error={meta.touched && meta.error} data-sentry-element="Input" data-sentry-source-file="index.tsx" data-sentry-element="Input" /></Box>;
}
function _temp3(t0) {
  const {
    input: input_0
  } = t0;
  return <Flex alignItems="center" data-sentry-element="Flex" data-sentry-component="_temp3" data-sentry-source-file="index.tsx"><Switch size="small" {...input_0} data-sentry-element="Switch" data-sentry-source-file="index.tsx" data-sentry-element="Switch" /><Box ml={2} data-sentry-element="Box" data-sentry-source-file="index.tsx" data-sentry-element="Box">Break page before</Box></Flex>;
}
function _temp4(t0) {
  const {
    input: pInput
  } = t0;
  return <><Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx" data-sentry-element="Flex"><Switch size="small" {...pInput} data-sentry-element="Switch" data-sentry-source-file="index.tsx" data-sentry-element="Switch" /><Box ml={2} data-sentry-element="Box" data-sentry-source-file="index.tsx" data-sentry-element="Box">Show when printing</Box></Flex>{pInput.value && <Field name="has_page_break">{_temp3}</Field>}</>;
}