import { c as _c } from "react/compiler-runtime";
import React from 'react';
import ImageInput from 'shared/image-input';
const TestView = () => {
  const $ = _c(2);
  const [image, setImage] = React.useState(null);
  let t0;
  if ($[0] !== image) {
    t0 = <><ImageInput value={image} onChange={setImage} /></>;
    $[0] = image;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  return t0;
};
export default TestView;