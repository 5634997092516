import { c as _c } from "react/compiler-runtime";
import axios from 'shared/anaxios';
import { FORM_ERROR } from 'final-form';
import { DateTime } from 'luxon';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Flex } from 'shared/grid';
import Label from 'shared/label';
import Loader from 'shared/loader';
import { StandardModal } from 'shared/modal';
import { fetchBooking, patchBooking } from 'shared/redux/bookings/actions';
import { LOGIN_SUCCEEDED } from 'shared/redux/session/action-types';
import settings from 'shared/settings';
import Textarea from 'shared/textarea';
import { addToast } from 'shared/toaster/redux/actions';
import { getScrollToErrors } from 'shared/utils/form';
import { Booking } from 'shared/utils/time';
import { useAppDispatch, useAppSelector } from '../../store';
import PayView from './pay';
import DefaultView from './view';
import { useParams, useSearchParams } from 'react-router-dom';
const BookingView = () => {
  const $ = _c(56);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const {
    bookingId
  } = useParams();
  const business = useAppSelector(_temp);
  let t0;
  if ($[0] !== bookingId) {
    t0 = state_0 => (state_0.session.bookingIds || []).includes(bookingId);
    $[0] = bookingId;
    $[1] = t0;
  } else {
    t0 = $[1];
  }
  const isOwner = useAppSelector(t0);
  let t1;
  if ($[2] !== bookingId) {
    t1 = state_1 => state_1.bookings.data[bookingId];
    $[2] = bookingId;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  const booking = useAppSelector(t1);
  let t2;
  if ($[4] !== booking) {
    t2 = state_2 => booking ? state_2.locations.data[booking.location_id] : null;
    $[4] = booking;
    $[5] = t2;
  } else {
    t2 = $[5];
  }
  const bookingLocation = useAppSelector(t2);
  let t3;
  if ($[6] !== booking || $[7] !== business || $[8] !== isOwner) {
    t3 = isOwner && booking && booking.services.every(_temp2) && ["confirmed", "requested", "pencilled_in", "awaiting_payment"].includes(booking.status) && !business.settings.disable_changes;
    $[6] = booking;
    $[7] = business;
    $[8] = isOwner;
    $[9] = t3;
  } else {
    t3 = $[9];
  }
  const canCancel = t3;
  let t4;
  let t5;
  if ($[10] !== bookingLocation) {
    t5 = bookingLocation || {
      timezone: DateTime.local().zoneName,
      address: ""
    };
    $[10] = bookingLocation;
    $[11] = t5;
  } else {
    t5 = $[11];
  }
  t4 = t5;
  const location = t4;
  let t6;
  if ($[12] !== searchParams) {
    t6 = searchParams.get("c");
    $[12] = searchParams;
    $[13] = t6;
  } else {
    t6 = $[13];
  }
  const code = t6;
  const isLoading = useAppSelector(_temp4);
  let t7;
  let t8;
  if ($[14] !== booking || $[15] !== bookingId || $[16] !== dispatch) {
    t7 = () => {
      if (!booking && bookingId) {
        dispatch(fetchBooking(bookingId));
      }
    };
    t8 = [booking, bookingId, dispatch];
    $[14] = booking;
    $[15] = bookingId;
    $[16] = dispatch;
    $[17] = t7;
    $[18] = t8;
  } else {
    t7 = $[17];
    t8 = $[18];
  }
  React.useEffect(t7, t8);
  let t10;
  let t9;
  if ($[19] !== booking || $[20] !== bookingId || $[21] !== code || $[22] !== dispatch) {
    t9 = () => {
      if (code && code !== "xxxx" && booking) {
        axios.post(`${settings.api2Root}/token-booking`, {
          booking_id: bookingId,
          code
        }, {
          skipErrorReportOnCode: {
            "401": true
          }
        }).then(t11 => {
          const {
            data
          } = t11;
          if (!data) {
            return;
          }
          dispatch({
            type: LOGIN_SUCCEEDED,
            payload: data
          });
        });
      }
    };
    t10 = [booking, bookingId, code, dispatch];
    $[19] = booking;
    $[20] = bookingId;
    $[21] = code;
    $[22] = dispatch;
    $[23] = t10;
    $[24] = t9;
  } else {
    t10 = $[23];
    t9 = $[24];
  }
  React.useEffect(t9, t10);
  let t11;
  if ($[25] !== setSearchParams) {
    t11 = lid => {
      setSearchParams(prev => ({
        ...Object.fromEntries(prev.entries()),
        lid: lid || undefined
      }), {
        replace: true
      });
    };
    $[25] = setSearchParams;
    $[26] = t11;
  } else {
    t11 = $[26];
  }
  const ensureLocationIdIsSet = t11;
  let t12;
  let t13;
  if ($[27] !== booking || $[28] !== ensureLocationIdIsSet) {
    t12 = () => {
      if (booking) {
        ensureLocationIdIsSet(booking.location_id);
      }
    };
    t13 = [booking, ensureLocationIdIsSet];
    $[27] = booking;
    $[28] = ensureLocationIdIsSet;
    $[29] = t12;
    $[30] = t13;
  } else {
    t12 = $[29];
    t13 = $[30];
  }
  React.useEffect(t12, t13);
  const [wasLoaded, setWasLoaded] = React.useState(false);
  let t14;
  let t15;
  if ($[31] !== isLoading) {
    t14 = () => {
      if (!isLoading) {
        setWasLoaded(true);
      }
    };
    t15 = [isLoading];
    $[31] = isLoading;
    $[32] = t14;
    $[33] = t15;
  } else {
    t14 = $[32];
    t15 = $[33];
  }
  React.useEffect(t14, t15);
  let t16;
  let t17;
  if ($[34] === Symbol.for("react.memo_cache_sentinel")) {
    t17 = [getScrollToErrors()];
    $[34] = t17;
  } else {
    t17 = $[34];
  }
  t16 = t17;
  const decorators = t16;
  let t18;
  if ($[35] !== dispatch) {
    t18 = (bookingId_0, reason) => dispatch(patchBooking({
      id: bookingId_0,
      status: "cancelled",
      reason
    })).then(() => {
      dispatch(addToast("Booking cancelled", "success"));
      ;
      try {
        const event = {
          event: "booking_cancelled",
          booking_id: bookingId_0
        };
        if (window.dataLayer) {
          window.dataLayer.push(event);
        } else {
          if (window.gtag) {
            window.gtag("event", "booking_cancelled");
          } else {
            if (window.ga) {
              window.ga("send", "event", "booking", "cancelled");
            }
          }
        }
      } catch (t19) {
        const e = t19;
        console.error(e);
        Sentry.captureException(e);
      }
    });
    $[35] = dispatch;
    $[36] = t18;
  } else {
    t18 = $[36];
  }
  const cancelBooking = t18;
  if (isLoading && !wasLoaded) {
    let t19;
    if ($[37] === Symbol.for("react.memo_cache_sentinel")) {
      t19 = <Loader />;
      $[37] = t19;
    } else {
      t19 = $[37];
    }
    return t19;
  }
  if (!booking) {
    let t19;
    if ($[38] === Symbol.for("react.memo_cache_sentinel")) {
      t19 = <EmptyState heading="Booking not found" />;
      $[38] = t19;
    } else {
      t19 = $[38];
    }
    return t19;
  }
  let t19;
  if ($[39] !== booking || $[40] !== canCancel || $[41] !== isOwner || $[42] !== location || $[43] !== showCancelModal) {
    t19 = booking?.status === "awaiting_payment" ? <PayView booking={booking} location={location} canCancel={canCancel} isOwner={isOwner} setShowCancelModal={setShowCancelModal} /> : <DefaultView booking={booking} location={location} canCancel={canCancel} isOwner={isOwner} setShowCancelModal={setShowCancelModal} showCancelModal={showCancelModal} />;
    $[39] = booking;
    $[40] = canCancel;
    $[41] = isOwner;
    $[42] = location;
    $[43] = showCancelModal;
    $[44] = t19;
  } else {
    t19 = $[44];
  }
  let t20;
  let t21;
  if ($[45] === Symbol.for("react.memo_cache_sentinel")) {
    t20 = () => setShowCancelModal(false);
    t21 = <FormattedMessage id="Public.BookingPage.cancelConfirmModalHeading" defaultMessage="Are you sure you want to cancel?" />;
    $[45] = t20;
    $[46] = t21;
  } else {
    t20 = $[45];
    t21 = $[46];
  }
  let t22;
  if ($[47] !== bookingId || $[48] !== cancelBooking) {
    t22 = () => <Form decorators={decorators} onSubmit={values => cancelBooking(bookingId, values.reason).then(() => {
      setShowCancelModal(false);
    }).catch(_temp5)}>{t23 => {
        const {
          handleSubmit,
          submitting,
          values: values_0
        } = t23;
        return <form onSubmit={handleSubmit} noValidate={true}><Field name="reason">{_temp6}</Field><Flex justifyContent="flex-end" mt={3}><Button color="gray" variant="flat" mr={2} onClick={() => setShowCancelModal(false)}><FormattedMessage id="Back" defaultMessage={`Back`} /></Button><Button type="submit" disabled={!values_0.reason || submitting} loading={submitting}><FormattedMessage id="Cancel booking" defaultMessage="Cancel booking" /></Button></Flex></form>;
      }}</Form>;
    $[47] = bookingId;
    $[48] = cancelBooking;
    $[49] = t22;
  } else {
    t22 = $[49];
  }
  let t23;
  if ($[50] !== showCancelModal || $[51] !== t22) {
    t23 = <StandardModal isOpen={showCancelModal} close={t20} title={t21}>{t22}</StandardModal>;
    $[50] = showCancelModal;
    $[51] = t22;
    $[52] = t23;
  } else {
    t23 = $[52];
  }
  let t24;
  if ($[53] !== t19 || $[54] !== t23) {
    t24 = <>{t19}{t23}</>;
    $[53] = t19;
    $[54] = t23;
    $[55] = t24;
  } else {
    t24 = $[55];
  }
  return t24;
};
export default BookingView;
function _temp(state) {
  return state.businesses.data[state.public.businessId];
}
function _temp2(s) {
  return s.start > DateTime.utc().toISO().substring(0, 19) + "Z";
}
function _temp3(v) {
  return v || "loading";
}
function _temp4(state_3) {
  return [state_3.businesses.status, state_3.locations.status, state_3.services.status, state_3.bookables.status, state_3.bookableGroups.status, state_3.bookableBusy.status, state_3.bookings.status].map(_temp3).includes("loading") || !state_3.public.businessId;
}
function _temp5(error) {
  if (error?.response?.status >= 500 || !error?.response?.data?.errors) {
    return;
  }
  return {
    [FORM_ERROR]: "Something went wrong"
  };
}
function _temp6(t0) {
  const {
    input
  } = t0;
  return <><Label mb={2} data-sentry-element="Label" data-sentry-source-file="component.tsx" data-sentry-element="Label"><FormattedMessage id="Public.BookingPage.cancelConfirmModalReasonInputLabel" defaultMessage="Reason for cancelling" data-sentry-element="FormattedMessage" data-sentry-source-file="component.tsx" data-sentry-element="FormattedMessage" /></Label><Textarea {...input} data-sentry-element="Textarea" data-sentry-source-file="component.tsx" data-sentry-element="Textarea" /></>;
}