import { c as _c } from "react/compiler-runtime";
import langs from 'langs';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import Autocomplete from '../../autocomplete';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PlaceholderField, PrivateField, RequiredField } from '../shared';
const Language = t0 => {
  const $ = _c(21);
  const {
    schema,
    disabled,
    menu,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const suportedLanguages = useSelector(_temp);
  let t2;
  if ($[0] !== suportedLanguages) {
    t2 = suportedLanguages.map(_temp2);
    $[0] = suportedLanguages;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const languages = t2;
  const prefix = React.useContext(FieldPrefixContext);
  const t3 = isCustom && "custom_fields";
  let t4;
  if ($[2] !== prefix || $[3] !== schema.name || $[4] !== t3) {
    t4 = [prefix, t3, schema.name].filter(_temp3);
    $[2] = prefix;
    $[3] = schema.name;
    $[4] = t3;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  const name = t4.join(".");
  let t5;
  if ($[6] !== schema.required) {
    t5 = value => {
      if (schema.required && !value) {
        return "Select a language";
      }
    };
    $[6] = schema.required;
    $[7] = t5;
  } else {
    t5 = $[7];
  }
  let t6;
  if ($[8] !== className || $[9] !== disabled || $[10] !== languages || $[11] !== menu || $[12] !== schema.helptext || $[13] !== schema.label || $[14] !== schema.placeholder || $[15] !== style) {
    t6 = t7 => {
      const {
        input,
        meta
      } = t7;
      return <Box style={style} className={className}><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><Autocomplete {...input} tabIndex={disabled ? -1 : undefined} placeholder={schema.placeholder} sx={{
          display: "block",
          width: "100%"
        }} invalid={meta.touched && meta.error} itemToString={_temp4} items={languages} />{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[8] = className;
    $[9] = disabled;
    $[10] = languages;
    $[11] = menu;
    $[12] = schema.helptext;
    $[13] = schema.label;
    $[14] = schema.placeholder;
    $[15] = style;
    $[16] = t6;
  } else {
    t6 = $[16];
  }
  let t7;
  if ($[17] !== name || $[18] !== t5 || $[19] !== t6) {
    t7 = <Field name={name} validate={t5}>{t6}</Field>;
    $[17] = name;
    $[18] = t5;
    $[19] = t6;
    $[20] = t7;
  } else {
    t7 = $[20];
  }
  return t7;
};
export default Language;
export const toString = value => langs.where('1', value)?.name || value;
export const View = t0 => {
  const $ = _c(2);
  const {
    value
  } = t0;
  const t1 = langs.where("1", value)?.name || value;
  let t2;
  if ($[0] !== t1) {
    t2 = <Box>{t1}</Box>;
    $[0] = t1;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  return t2;
};
export const Edit = t0 => {
  const $ = _c(12);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp5);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== hideRequired || $[3] !== isEditing || $[4] !== isUniqueFieldName || $[5] !== menu || $[6] !== removable || $[7] !== showClientFieldReference || $[8] !== showMore || $[9] !== style || $[10] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp6}>{t3 => <Language schema={value} disabled={true} menu={menu} style={style} className={className} />}</Form> : <Grid sx={{
        display: !isEditing ? "none" : null
      }}><LabelField prefix="" />{!hideRequired && <RequiredField prefix="" />}<PlaceholderField prefix="" /><HelpTextField prefix="" />{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} size="small" width="100%" variant="outlined">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = hideRequired;
    $[3] = isEditing;
    $[4] = isUniqueFieldName;
    $[5] = menu;
    $[6] = removable;
    $[7] = showClientFieldReference;
    $[8] = showMore;
    $[9] = style;
    $[10] = value;
    $[11] = t2;
  } else {
    t2 = $[11];
  }
  return t2;
};
function _temp(state) {
  return state.businesses.data[state.public ? state.public.businessId : state.userPreferences.currentBusinessId]?.supported_languages || ["en"];
}
function _temp2(v) {
  return {
    value: v,
    label: langs.where("1", v).name
  };
}
function _temp3(v_0) {
  return v_0;
}
function _temp4(v_1) {
  return langs.where("1", v_1)?.name || "";
}
function _temp5(v) {
  return !v;
}
function _temp6(value_0) {
  return null;
}