import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Box, BoxProps } from 'shared/grid';
import { trimTextNode } from '../typography';
interface LabelProps {
  invalid?: boolean;
  isFocused?: boolean;
}
export function Label(t0) {
  const $ = _c(10);
  let props;
  let t1;
  let t2;
  let t3;
  if ($[0] !== t0) {
    ({
      ref: t1,
      invalid: t2,
      isFocused: t3,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = t1;
    $[3] = t2;
    $[4] = t3;
  } else {
    props = $[1];
    t1 = $[2];
    t2 = $[3];
    t3 = $[4];
  }
  const ref = t1 === undefined ? null : t1;
  const invalid = t2 === undefined ? false : t2;
  t3 === undefined ? false : t3;
  const t4 = invalid ? "alert" : undefined;
  let t5;
  if ($[5] !== t4) {
    t5 = {
      color: t4,
      display: "block",
      fontSize: 2
    };
    $[5] = t4;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  let t6;
  if ($[7] !== props || $[8] !== t5) {
    t6 = <Box tx="text" variant="label" as="label" ref={ref} __css={t5} {...props} />;
    $[7] = props;
    $[8] = t5;
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  return t6;
}
export default Label;