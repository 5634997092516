import { c as _c } from "react/compiler-runtime";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRouteError } from 'react-router-dom';
import { Box, Flex, Grid } from 'shared/grid';
import NotFoundImage from 'shared/images/404-1.gif';
import { CroppedText, Link } from 'shared/typography';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import Layout from '../layout';
export default function ErrorPage() {
  const $ = _c(8);
  const error = useRouteError() as any;
  let t0;
  if ($[0] !== error?.status || $[1] !== error?.statusText) {
    t0 = error?.status === 404 ? <Grid textAlign="center"><CroppedText as="h1" fontSize={5} color="text">Page not found</CroppedText><Link variant="discreetLink" href="/"><FontAwesomeIcon icon={faChevronLeft} /> Take me back</Link></Grid> : <Grid textAlign="center"><CroppedText as="h1" fontSize={5} color="text">Something went wrong: {error?.status || ""}{" "}{error?.statusText || ""}</CroppedText><Link variant="discreetLink" href="/"><FontAwesomeIcon icon={faChevronLeft} /> Take me back</Link></Grid>;
    $[0] = error?.status;
    $[1] = error?.statusText;
    $[2] = t0;
  } else {
    t0 = $[2];
  }
  let t1;
  if ($[3] !== error?.status) {
    t1 = error?.status === 404 && <Box as="img" sx={{
      position: "fixed",
      bottom: 0,
      right: 0,
      height: "40%"
    }} src={NotFoundImage} alt="Not found" title="Not found" />;
    $[3] = error?.status;
    $[4] = t1;
  } else {
    t1 = $[4];
  }
  let t2;
  if ($[5] !== t0 || $[6] !== t1) {
    t2 = <Layout><Flex justifyContent="center" alignItems="center" flex={1} height="100vh">{t0}{t1}</Flex></Layout>;
    $[5] = t0;
    $[6] = t1;
    $[7] = t2;
  } else {
    t2 = $[7];
  }
  return t2;
}