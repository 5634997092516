import React from 'react';
import { DateTime } from 'luxon';
export const useCantCancelBookingReason = (canCancel, businessDoesNotAllowCancellations, bookingServices, isOwner) => {
  let t0;
  bb0: {
    if (canCancel) {
      t0 = undefined;
      break bb0;
    }
    if (businessDoesNotAllowCancellations) {
      t0 = "We do not allow online booking cancellations. Please contact us.";
      break bb0;
    }
    if (!bookingServices.every(_temp)) {
      t0 = "Past booking cannot be cancelled.";
      break bb0;
    }
    if (!isOwner) {
      t0 = "You cannot cancel this booking. Please follow the link sent to you.";
      break bb0;
    }
    t0 = undefined;
  }
  const cantCancelReason = t0;
  return cantCancelReason;
};
function _temp(s) {
  return s.start > DateTime.utc().toISO().substring(0, 19) + "Z";
}