// import {
//   AsYouType,
//   format,
//   getPhoneCode,
//   parse,
//   isSupportedCountry,
// } from 'libphonenumber-js';
import React from 'react';
import countryItems_ from 'shared/countries';
import Input from 'shared/input';
import Autocomplete from '../autocomplete';
import InputGroup from '../input-group';
import { useSelector } from 'react-redux';
import { useLibPhoneNumber } from 'shared/hooks/phone-number';
interface Props {
  value: string;
  onChange: (any) => void;
  style?: React.CSSProperties;
  className?: string;
  onFocus?: any;
  onBlur?: any;
  placeholder?: string;
  tabIndex?: number;
  invalid?: boolean;
  name?: string;
  labelId?: string;
  autoComplete?: string;
  id?: string;
}
const inputUnformat = value => value.replace(/ /g, '');
function PhoneInput({
  ref,
  value,
  onChange,
  className = '',
  onFocus,
  onBlur,
  placeholder,
  tabIndex,
  invalid,
  name,
  labelId,
  autoComplete,
  id,
  ...rest
}: Props & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  const {
    AsYouType,
    format,
    getPhoneCode,
    parse,
    isSupportedCountry
  } = useLibPhoneNumber();
  const getOutputValue = React.useCallback((country, inputValue) => {
    const parsedNumber = parse(inputValue, country);
    const outputValue = Object.keys(parsedNumber).length !== 0 ? format(parsedNumber, 'E.164') : inputValue;
    return outputValue;
  }, [format, parse]);
  const inputFormat = React.useCallback((inputText, country_0) => {
    const formatter = new AsYouType(country_0);
    return formatter.input(inputText);
  }, [AsYouType]);
  const defaultCountry = useSelector((state: any) => state.geoip.country_code) as string;
  const countryItems = React.useMemo(() => countryItems_.filter(({
    value: value_0
  }) => isSupportedCountry(value_0)), [isSupportedCountry]);
  const [country_1, setCountry] = React.useState(defaultCountry);
  const [inputValue_0, setInputValue] = React.useState('');
  const onSelectChange = React.useCallback(nextCountry => {
    const oldCode = country_1 && getPhoneCode(country_1 as any);
    let newCode;
    try {
      newCode = nextCountry && getPhoneCode(nextCountry);
    } catch {
      newCode = '';
    }
    let nextInputValue = inputValue_0;
    const formatter_0 = new AsYouType(nextCountry || defaultCountry as any);
    formatter_0.input(nextInputValue);
    if (!nextInputValue || nextInputValue === '+' || !formatter_0['country']) {
      nextInputValue = newCode ? `+${newCode}` : '';
    } else if (oldCode) {
      nextInputValue = nextInputValue.replace(new RegExp(`^\\+${oldCode}`), `+${newCode}` as string);
    }
    setCountry(nextCountry);
    setInputValue(inputFormat(nextInputValue, nextCountry || defaultCountry));
    onChange(getOutputValue(nextCountry, nextInputValue));
  }, [AsYouType, country_1, defaultCountry, getOutputValue, getPhoneCode, inputFormat, inputValue_0, onChange]);
  const onInputChange = React.useCallback(e => {
    const cursorStart = e.target.selectionStart;
    const cursorEnd = e.target.selectionEnd;
    const isAtEnd = cursorEnd === e.target.value.length;
    const nextValue = inputUnformat(e.target.value);
    let nextCountry_0 = country_1;
    const formatter_1 = new AsYouType(country_1 || defaultCountry as any);
    formatter_1.input(nextValue); // +1-310-555-1212

    if (nextValue[0] === '+') {
      if (nextValue.length === 1) {
        nextCountry_0 = null;
      } else if (formatter_1.country) {
        nextCountry_0 = formatter_1.country;
      }
    }
    setCountry(nextCountry_0);
    setInputValue(inputFormat(nextValue, nextCountry_0 || defaultCountry));
    const target = e.target;
    const outputValue_0 = getOutputValue(country_1 || defaultCountry as any, nextValue);
    onChange(outputValue_0);
    if (!isAtEnd) {
      setTimeout(() => target.setSelectionRange(cursorStart, cursorEnd), 50);
    }
  }, [AsYouType, country_1, defaultCountry, getOutputValue, inputFormat, onChange]);
  React.useEffect(() => {
    const {
      country: nextCountry_1,
      phone: nextPhone
    } = parse(value);
    const {
      country: currentCountry,
      phone: currentPhone
    } = parse(inputValue_0, country_1 as any);
    if (currentCountry === nextCountry_1 && currentPhone === nextPhone) {
      return;
    }
    const outputValue_1 = getOutputValue(country_1, value);
    if (outputValue_1 !== value) {
      onChange(outputValue_1);
    }
    setCountry(nextCountry_1);
    setInputValue(inputFormat(value, country_1 || currentCountry));
  }, [country_1, defaultCountry, getOutputValue, inputFormat, inputValue_0, onChange, parse, value]);
  return <InputGroup ref={ref} {...rest} data-sentry-element="InputGroup" data-sentry-component="PhoneInput" data-sentry-source-file="index.tsx">
      <Autocomplete
    // autoComplete="new-country-code"
    id={(id || '') + '-country-code'} name={(name || '') + '-country-code'} labelId={labelId} tabIndex={tabIndex} onBlur={onBlur} onFocus={onFocus} placeholder="Country" className={className} value={country_1} onChange={onSelectChange} sx={{
      display: 'block',
      flexShrink: 1,
      '& input': {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
        // borderRightWidth: '2px',
      }
    }} itemToString={item => countryItems.find(v => v.value === item)?.label || ''} items={countryItems} invalid={invalid} data-sentry-element="Autocomplete" data-sentry-source-file="index.tsx" />

      <Input id={id} autoComplete={autoComplete} name={name} tabIndex={tabIndex} onBlur={onBlur} onFocus={onFocus} sx={{
      flexGrow: 1,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderLeftWidth: '0.5px'
    }} className={className} placeholder={placeholder} type="tel" onChange={onInputChange} value={inputValue_0} invalid={invalid} data-sentry-element="Input" data-sentry-source-file="index.tsx" />
    </InputGroup>;
}
export default PhoneInput;