import { c as _c } from "react/compiler-runtime";
import { keyframes, Theme, ThemeContext } from '@emotion/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { themeGet } from '@styled-system/theme-get';
import Tippy from '../tooltip';
import Color from 'color';
import React from 'react';
import * as StyledSystem from 'styled-system';
import { variant } from 'styled-system';
import { Box, BoxKnownProps, BoxProps } from '../grid';
const rotate = keyframes({
  '0%': {
    transform: 'rotate(0deg) scale(1)'
  },
  '50%': {
    transform: 'rotate(180deg) scale(1)'
  },
  '100%': {
    transform: 'rotate(360deg) scale(1)'
  }
});
const loadingStyle = theme => ({
  position: 'relative',
  '&:before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    border: `4px solid ${theme.colors.gray[3]}`,
    borderLeftColor: 'white',
    borderRadius: '50%',
    boxSizing: 'border-box',
    top: '50%',
    left: '50%',
    marginTop: '-12px',
    marginLeft: '-12px',
    width: '24px',
    height: '24px',
    animation: `${rotate} 1s linear infinite`
  }
});
interface ButtonKnownProps extends BoxKnownProps, StyledSystem.ButtonStyleProps {}
export interface ButtonProps extends ButtonKnownProps, Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, keyof ButtonKnownProps> {
  color?: string;
  colorScheme?: 'brand0' | 'brand1' | 'brand2' | 'brand3' | 'gray' | 'green' | 'yellow' | 'red';
  // colorOffset?: number;
  iconColor?: string;
  variant?: 'solid' | 'outlined' | 'flat';
  size?: 'large' | 'medium' | 'small';
  active?: boolean;
  loading?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  noIconPadding?: boolean;
  fixedWidth?: boolean;
}
const sizeVariant = variant({
  prop: 'size',
  scale: 'buttonSizes',
  variants: {
    // can be blank to enable the new API
    large: {
      fontSize: 3,
      px: ['1.2em', '1.2em'],
      py: ['0.5em', '0.7em']
    },
    medium: {
      fontSize: 2,
      px: ['1.2em', '1.2em'],
      py: ['0.5em', '0.7em']
    },
    small: {
      fontSize: 1,
      px: ['1.2em', '1.2em'],
      py: ['0.5em', '0.7em']
    }
  }
});
const clamp = (num, min, max) => Math.min(Math.max(num, min), max);
export function Button(t0) {
  const $ = _c(66);
  let active;
  let children;
  let disabled;
  let fixedWidth;
  let iconLeft;
  let iconRight;
  let loading;
  let noIconPadding;
  let props;
  let ref;
  let t1;
  let t2;
  let t3;
  let t4;
  if ($[0] !== t0) {
    ({
      ref,
      children,
      color: t1,
      iconColor: t2,
      active,
      loading,
      iconLeft,
      iconRight,
      type: t3,
      disabled,
      fixedWidth,
      noIconPadding,
      variant: t4,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = active;
    $[2] = children;
    $[3] = disabled;
    $[4] = fixedWidth;
    $[5] = iconLeft;
    $[6] = iconRight;
    $[7] = loading;
    $[8] = noIconPadding;
    $[9] = props;
    $[10] = ref;
    $[11] = t1;
    $[12] = t2;
    $[13] = t3;
    $[14] = t4;
  } else {
    active = $[1];
    children = $[2];
    disabled = $[3];
    fixedWidth = $[4];
    iconLeft = $[5];
    iconRight = $[6];
    loading = $[7];
    noIconPadding = $[8];
    props = $[9];
    ref = $[10];
    t1 = $[11];
    t2 = $[12];
    t3 = $[13];
    t4 = $[14];
  }
  const buttonColor = t1 === undefined ? "primary" : t1;
  t2 === undefined ? buttonColor : t2;
  const type = t3 === undefined ? "button" : t3;
  const variant_ = t4 === undefined ? "solid" : t4;
  const theme = React.useContext(ThemeContext) as Theme;
  const isDark = theme.mode === "dark";
  const variant = variant_ || "solid";
  let activeStyle;
  let bg = "transparent";
  let color;
  let borderColor = "transparent";
  if (buttonColor.startsWith("#")) {
    debugger;
  }
  const [scale, index] = buttonColor.split(".");
  const colorScale = {
    success: "green",
    warning: "yellow",
    alert: "red",
    primary: "primary_",
    secondary: "gray"
  }[scale] || scale;
  let scaleIndex;
  bb0: switch (variant) {
    default:
      {}
    case "solid":
      {
        if (isDark) {
          scaleIndex = index ? Number(index) : {
            gray: 3,
            red: 6,
            primary_: 5,
            brand1: 6
          }[colorScale] || 8;
        } else {
          scaleIndex = index ? Number(index) : {
            gray: 1,
            red: 6,
            brand1: 4
          }[colorScale] || 8;
        }
        const baseColorN_1 = clamp(scaleIndex + (isDark ? -1 : 0), 0, 9);
        const activeColorN = clamp(baseColorN_1 + (isDark ? -1 : 1), 0, 9);
        const baseColor_0 = `${colorScale}.${baseColorN_1}`;
        const darkenColor = `${colorScale}.${activeColorN}`;
        bg = baseColor_0;
        borderColor = baseColor_0;
        if (isDark ? baseColorN_1 > 6 : baseColorN_1 < 4) {
          color = theme.colors.black;
        } else {
          color = theme.colors.white;
        }
        let t5;
        if ($[15] !== color || $[16] !== darkenColor) {
          t5 = {
            color,
            backgroundColor: darkenColor,
            borderColor: darkenColor
          };
          $[15] = color;
          $[16] = darkenColor;
          $[17] = t5;
        } else {
          t5 = $[17];
        }
        activeStyle = t5;
        break bb0;
      }
    case "outlined":
      {
        if (isDark) {
          scaleIndex = index ? Number(index) : {
            primary_: 7,
            red: 7
          }[colorScale] || 7;
        } else {
          scaleIndex = index ? Number(index) : {
            red: 7
          }[colorScale] || 8;
        }
        const baseColorN_0 = clamp(scaleIndex + (isDark ? -1 : 0), 0, 9);
        const fadeColorN_0 = isDark ? 0 : 2;
        const baseColor = `${colorScale}.${baseColorN_0}`;
        const fadeColor = `${colorScale}.${fadeColorN_0}`;
        color = baseColor;
        borderColor = baseColor;
        let t5;
        if ($[18] !== color || $[19] !== fadeColor) {
          t5 = {
            backgroundColor: fadeColor,
            color
          };
          $[18] = color;
          $[19] = fadeColor;
          $[20] = t5;
        } else {
          t5 = $[20];
        }
        activeStyle = t5;
        break bb0;
      }
    case "flat":
      {
        if (isDark) {
          scaleIndex = index ? Number(index) : {
            primary_: 7,
            red: 7
          }[colorScale] || 7;
        } else {
          scaleIndex = index ? Number(index) : {
            red: 7
          }[colorScale] || 8;
        }
        const baseColorN = clamp(scaleIndex + (isDark ? -1 : 0), 0, 9);
        const fadeColorN = isDark ? 0 : 2;
        color = `${colorScale}.${baseColorN}`;
        const t5 = `${colorScale}.${fadeColorN}`;
        let t6;
        if ($[21] !== color || $[22] !== t5) {
          t6 = {
            backgroundColor: t5,
            color
          };
          $[21] = color;
          $[22] = t5;
          $[23] = t6;
        } else {
          t6 = $[23];
        }
        activeStyle = t6;
      }
  }
  const t5 = disabled || loading;
  let t6;
  if ($[24] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = {
      opacity: 0.5
    };
    $[24] = t6;
  } else {
    t6 = $[24];
  }
  let t7;
  if ($[25] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = {
      lineHeight: 1
    };
    $[25] = t7;
  } else {
    t7 = $[25];
  }
  const t8 = color;
  const t9 = bg;
  const t10 = borderColor;
  const t11 = activeStyle;
  const t12 = `solid 2px ${theme.colors.brand0[isDark ? 7 : 3]}`;
  let t13;
  if ($[26] !== t12) {
    t13 = {
      outline: t12
    };
    $[26] = t12;
    $[27] = t13;
  } else {
    t13 = $[27];
  }
  const t14 = props.size || "medium";
  let t15;
  if ($[28] !== props || $[29] !== t14 || $[30] !== theme) {
    t15 = sizeVariant({
      ...props,
      size: t14,
      theme
    });
    $[28] = props;
    $[29] = t14;
    $[30] = theme;
    $[31] = t15;
  } else {
    t15 = $[31];
  }
  let t16;
  if ($[32] !== active || $[33] !== activeStyle) {
    t16 = active ? activeStyle : {};
    $[32] = active;
    $[33] = activeStyle;
    $[34] = t16;
  } else {
    t16 = $[34];
  }
  let t17;
  if ($[35] !== loading || $[36] !== theme) {
    t17 = loading ? loadingStyle(theme) : "";
    $[35] = loading;
    $[36] = theme;
    $[37] = t17;
  } else {
    t17 = $[37];
  }
  let t18;
  if ($[38] !== activeStyle || $[39] !== bg || $[40] !== borderColor || $[41] !== color || $[42] !== t13 || $[43] !== t15 || $[44] !== t16 || $[45] !== t17) {
    t18 = {
      display: "block",
      appearance: "none",
      textAlign: "center",
      lineHeight: "inherit",
      textDecoration: "none",
      cursor: "pointer",
      overflow: "hidden",
      transition: "all 0.25s ease-out, color 0.25s ease-out",
      "&:disabled": t6,
      borderRadius: 2,
      "&, *": t7,
      borderWidth: 1,
      color: t8,
      bg: t9,
      borderColor: t10,
      borderStyle: "solid",
      "&:active, &:focus, &:hover": t11,
      "&:focus": t13,
      ...t15,
      ...t16,
      ...t17
    };
    $[38] = activeStyle;
    $[39] = bg;
    $[40] = borderColor;
    $[41] = color;
    $[42] = t13;
    $[43] = t15;
    $[44] = t16;
    $[45] = t17;
    $[46] = t18;
  } else {
    t18 = $[46];
  }
  let t19;
  if ($[47] !== children || $[48] !== fixedWidth || $[49] !== iconLeft || $[50] !== noIconPadding) {
    t19 = iconLeft && <Box sx={{
      mr: !noIconPadding && children ? "0.5em" : 0,
      display: "inline"
    }}><FontAwesomeIcon icon={iconLeft} fixedWidth={fixedWidth} /></Box>;
    $[47] = children;
    $[48] = fixedWidth;
    $[49] = iconLeft;
    $[50] = noIconPadding;
    $[51] = t19;
  } else {
    t19 = $[51];
  }
  let t20;
  if ($[52] !== children || $[53] !== fixedWidth || $[54] !== iconRight || $[55] !== noIconPadding) {
    t20 = iconRight && <Box sx={{
      ml: !noIconPadding && children ? "0.5em" : 0,
      display: "inline"
    }}><FontAwesomeIcon icon={iconRight} fixedWidth={fixedWidth} /></Box>;
    $[52] = children;
    $[53] = fixedWidth;
    $[54] = iconRight;
    $[55] = noIconPadding;
    $[56] = t20;
  } else {
    t20 = $[56];
  }
  let t21;
  if ($[57] !== children || $[58] !== props || $[59] !== ref || $[60] !== t18 || $[61] !== t19 || $[62] !== t20 || $[63] !== t5 || $[64] !== type) {
    t21 = <Box ref={ref} as="button" tx="buttons" type={type} disabled={t5} __css={t18} {...props}>{t19}{children}{t20}</Box>;
    $[57] = children;
    $[58] = props;
    $[59] = ref;
    $[60] = t18;
    $[61] = t19;
    $[62] = t20;
    $[63] = t5;
    $[64] = type;
    $[65] = t21;
  } else {
    t21 = $[65];
  }
  return t21;
}
export function ButtonGroup(t0) {
  const $ = _c(7);
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  let t1;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      display: "flex",
      flexWrap: "nowrap",
      alignItems: "stretch",
      "& > *": {
        flexGrow: "1",
        "&:not(:first-of-type):not(:last-of-type)": {
          borderRadius: 0
        },
        "&:not(:last-of-type):not(:first-of-type)": {
          borderRadius: 0
        },
        "&:not(:last-of-type):not(:only-of-type)": {
          marginRight: "1px"
        },
        "&:first-of-type:not(:last-of-type)": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0
        },
        "&:last-of-type:not(:first-of-type)": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0
        }
      }
    };
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  let t2;
  if ($[4] !== props || $[5] !== ref) {
    t2 = <Box ref={ref} {...props} __css={t1} />;
    $[4] = props;
    $[5] = ref;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  return t2;
}
interface RoundButtonProps {
  tooltip;
  icon: any;
  onClick?: (any) => void;
  color?: string;
  className?: string;
  inactiveColor?: string;
}
export const RoundButton = function RoundButton(t0) {
  const $ = _c(38);
  const {
    ref,
    tooltip,
    icon,
    onClick,
    color: t1,
    inactiveColor: t2,
    className
  } = t0;
  const buttonColor = t1 === undefined ? "primary" : t1;
  t2 === undefined ? "gray.5" : t2;
  const theme = React.useContext(ThemeContext) as Theme;
  const isDark = theme.mode === "dark";
  let t3;
  if ($[0] !== buttonColor) {
    t3 = {
      success: "green",
      warning: "yellow",
      alert: "red",
      primary: "primary_",
      secondary: "gray"
    }[buttonColor.split(".")[0]] || buttonColor;
    $[0] = buttonColor;
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  const colorScale = t3;
  const darkness = {
    gray: 1,
    red: 5
  }[colorScale] || 8;
  const baseColorN = darkness + (isDark ? false ? 4 : 1 : 0);
  const t4 = baseColorN + (isDark ? 1 : 1);
  let t5;
  if ($[2] !== t4) {
    t5 = clamp(t4, 0, 9);
    $[2] = t4;
    $[3] = t5;
  } else {
    t5 = $[3];
  }
  const activeColorN = t5;
  isDark ? 1 : 2;
  const t6 = `colors.${colorScale}.${baseColorN}`;
  let bg;
  let borderColor;
  let color;
  let darkenColor;
  let t7;
  let t8;
  if ($[4] !== activeColorN || $[5] !== colorScale || $[6] !== t6 || $[7] !== theme) {
    const baseColor = themeGet(t6)({
      theme
    });
    const t9 = `colors.${colorScale}.${activeColorN}`;
    let t10;
    if ($[14] !== t9 || $[15] !== theme) {
      t10 = themeGet(t9)({
        theme
      });
      $[14] = t9;
      $[15] = theme;
      $[16] = t10;
    } else {
      t10 = $[16];
    }
    darkenColor = t10;
    bg = baseColor;
    borderColor = baseColor;
    t7 = Color(bg).contrast(Color(theme.colors.black));
    t8 = Color(bg).contrast(Color(theme.colors.white));
    $[4] = activeColorN;
    $[5] = colorScale;
    $[6] = t6;
    $[7] = theme;
    $[8] = bg;
    $[9] = borderColor;
    $[10] = color;
    $[11] = darkenColor;
    $[12] = t7;
    $[13] = t8;
  } else {
    bg = $[8];
    borderColor = $[9];
    color = $[10];
    darkenColor = $[11];
    t7 = $[12];
    t8 = $[13];
  }
  if (t7 - t8 > 3.9) {
    color = theme.colors.black;
  } else {
    color = theme.colors.white;
  }
  let t9;
  if ($[17] !== color || $[18] !== darkenColor) {
    t9 = {
      color,
      backgroundColor: darkenColor,
      borderColor: darkenColor
    };
    $[17] = color;
    $[18] = darkenColor;
    $[19] = t9;
  } else {
    t9 = $[19];
  }
  const activeStyle = t9;
  const t10 = `solid 2px ${theme.colors.brand0[isDark ? 7 : 3]}`;
  let t11;
  if ($[20] !== t10) {
    t11 = {
      outline: t10
    };
    $[20] = t10;
    $[21] = t11;
  } else {
    t11 = $[21];
  }
  let t12;
  if ($[22] !== activeStyle || $[23] !== bg || $[24] !== borderColor || $[25] !== color || $[26] !== t11) {
    t12 = {
      borderRadius: "99px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "48px",
      width: "48px",
      color,
      bg,
      borderColor,
      borderStyle: "solid",
      boxShadow: "2px 2px 24px #444",
      "&:active, &:focus, &:hover": activeStyle,
      "&:focus": t11
    };
    $[22] = activeStyle;
    $[23] = bg;
    $[24] = borderColor;
    $[25] = color;
    $[26] = t11;
    $[27] = t12;
  } else {
    t12 = $[27];
  }
  let t13;
  if ($[28] !== icon) {
    t13 = <FontAwesomeIcon icon={icon} fixedWidth={true} size="lg" />;
    $[28] = icon;
    $[29] = t13;
  } else {
    t13 = $[29];
  }
  let t14;
  if ($[30] !== className || $[31] !== onClick || $[32] !== t12 || $[33] !== t13) {
    t14 = <Box as="button" className={className} ref={ref} onClick={onClick} __css={t12}>{t13}</Box>;
    $[30] = className;
    $[31] = onClick;
    $[32] = t12;
    $[33] = t13;
    $[34] = t14;
  } else {
    t14 = $[34];
  }
  let t15;
  if ($[35] !== t14 || $[36] !== tooltip) {
    t15 = <Tippy content={tooltip}>{t14}</Tippy>;
    $[35] = t14;
    $[36] = tooltip;
    $[37] = t15;
  } else {
    t15 = $[37];
  }
  return t15;
};
export default Button;