import { c as _c } from "react/compiler-runtime";
import Color from 'color';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import DrawPalette from '../../draw-palette';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import useComponentSize from '../../hooks/component-size';
import { useLocalState } from '../../hooks/persisted-state';
import ImageInput from '../../image-input';
import Label from '../../label';
import Select from '../../select';
import useDraw, { toSVG } from '../../use-draw';
import { EMPTY_ARRAY } from '../../utils/constants';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from './../shared';
const DiagramDraw = React.memo(function Diagramdraw(t0) {
  const $ = _c(41);
  const {
    value,
    onChange,
    imageUrl,
    disabled: t1,
    name
  } = t0;
  const disabled = t1 === undefined ? false : t1;
  const data = value || EMPTY_ARRAY;
  const setData = onChange;
  const containerRef = React.useRef(null);
  const {
    width: imageWidth,
    height: imageHeight
  } = useComponentSize(containerRef);
  const [penWidth, setPenWidth] = useLocalState(1, "penWidth");
  const [penColor, setPenColor] = useLocalState("#000000", "penColor");
  const [penType, setPenType] = useLocalState("ballpoint", "penType");
  let t2;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = [];
    $[0] = t2;
  } else {
    t2 = $[0];
  }
  const [redoStack, setRedoStack] = React.useState(t2);
  let t3;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = () => {
      setRedoStack([]);
    };
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  const onStroke = t3;
  const minPenWidth = {
    fountain: 0.5,
    ballpoint: 1,
    highlight: 5
  };
  const maxPenWidth = {
    fountain: 2,
    ballpoint: 1,
    highlight: 5
  };
  let t4;
  if ($[2] !== penColor || $[3] !== penType) {
    t4 = penType === "highlight" ? Color(penColor).alpha(0.25).string() : penColor;
    $[2] = penColor;
    $[3] = penType;
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  const {
    setCanvas,
    fromData
  } = useDraw(containerRef, data, setData, t4, minPenWidth[penType] * penWidth, maxPenWidth[penType] * penWidth, onStroke);
  let t5;
  if ($[5] !== data || $[6] !== fromData || $[7] !== redoStack || $[8] !== setData) {
    t5 = () => {
      if (redoStack.length) {
        const item = redoStack[redoStack.length - 1];
        setRedoStack(redoStack.filter((_, idx) => idx !== redoStack.length - 1));
        const newData = [...data, item];
        setData(newData);
        fromData(newData);
      }
    };
    $[5] = data;
    $[6] = fromData;
    $[7] = redoStack;
    $[8] = setData;
    $[9] = t5;
  } else {
    t5 = $[9];
  }
  const redo = t5;
  let t6;
  if ($[10] !== data || $[11] !== fromData || $[12] !== redoStack || $[13] !== setData) {
    t6 = () => {
      if (data.length) {
        const newData_0 = data.filter((__0, idx_0) => idx_0 !== data.length - 1);
        setRedoStack([...redoStack, data[data.length - 1]]);
        setData(newData_0);
        fromData(newData_0);
      }
    };
    $[10] = data;
    $[11] = fromData;
    $[12] = redoStack;
    $[13] = setData;
    $[14] = t6;
  } else {
    t6 = $[14];
  }
  const undo = t6;
  const t7 = disabled ? "none" : "all";
  let t8;
  if ($[15] !== t7) {
    t8 = {
      userSelect: "none",
      WebkitUserSelect: "none",
      WebkitTouchCallout: "none",
      canvas: {
        pointerEvents: t7,
        position: "absolute"
      }
    };
    $[15] = t7;
    $[16] = t8;
  } else {
    t8 = $[16];
  }
  let t9;
  if ($[17] !== imageHeight || $[18] !== imageWidth || $[19] !== setCanvas) {
    t9 = <canvas height={imageHeight} width={imageWidth} ref={setCanvas} />;
    $[17] = imageHeight;
    $[18] = imageWidth;
    $[19] = setCanvas;
    $[20] = t9;
  } else {
    t9 = $[20];
  }
  let t10;
  if ($[21] !== imageUrl) {
    t10 = <Box as="img" ref={containerRef} src={imageUrl} alt="Content" width="100%" flexGrow={1} display="block" maxWidth="100%" />;
    $[21] = imageUrl;
    $[22] = t10;
  } else {
    t10 = $[22];
  }
  let t11;
  if ($[23] === Symbol.for("react.memo_cache_sentinel")) {
    t11 = {
      position: "absolute",
      bottom: 0,
      right: 0
    };
    $[23] = t11;
  } else {
    t11 = $[23];
  }
  let t12;
  if ($[24] !== data || $[25] !== penColor || $[26] !== penType || $[27] !== penWidth || $[28] !== redo || $[29] !== redoStack || $[30] !== setPenColor || $[31] !== setPenType || $[32] !== setPenWidth || $[33] !== undo) {
    t12 = <Box p={3} sx={t11} backgroundColor="rgba(255, 255, 255, 0.4)" borderTopLeftRadius={2}><DrawPalette penType={penType} setPenType={setPenType} penColor={penColor} setPenColor={setPenColor} setPenWidth={setPenWidth} penWidth={penWidth} undo={undo} undoStack={data} redo={redo} redoStack={redoStack} /></Box>;
    $[24] = data;
    $[25] = penColor;
    $[26] = penType;
    $[27] = penWidth;
    $[28] = redo;
    $[29] = redoStack;
    $[30] = setPenColor;
    $[31] = setPenType;
    $[32] = setPenWidth;
    $[33] = undo;
    $[34] = t12;
  } else {
    t12 = $[34];
  }
  let t13;
  if ($[35] !== name || $[36] !== t10 || $[37] !== t12 || $[38] !== t8 || $[39] !== t9) {
    t13 = <Box position="relative" width="100%" bg="gray.0" name={name} sx={t8}>{t9}{t10}{t12}</Box>;
    $[35] = name;
    $[36] = t10;
    $[37] = t12;
    $[38] = t8;
    $[39] = t9;
    $[40] = t13;
  } else {
    t13 = $[40];
  }
  return t13;
});
const Diagram = t0 => {
  const $ = _c(18);
  const {
    schema,
    menu,
    disabled,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const prefix = React.useContext(FieldPrefixContext);
  const t2 = isCustom && "custom_fields";
  let t3;
  if ($[0] !== prefix || $[1] !== schema.name || $[2] !== t2) {
    t3 = [prefix, t2, schema.name].filter(_temp);
    $[0] = prefix;
    $[1] = schema.name;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const name = t3.join(".");
  let t4;
  if ($[4] !== schema.required) {
    t4 = value => {
      if (schema.required && (!value || value.length === 0)) {
        return "Diagram required";
      }
    };
    $[4] = schema.required;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] !== className || $[7] !== disabled || $[8] !== menu || $[9] !== schema.helptext || $[10] !== schema.image_url || $[11] !== schema.label || $[12] !== style) {
    t5 = t6 => {
      const {
        input,
        meta
      } = t6;
      return <Box style={style} className={className}><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><DiagramDraw disabled={disabled} imageUrl={schema.image_url} {...input} />{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[6] = className;
    $[7] = disabled;
    $[8] = menu;
    $[9] = schema.helptext;
    $[10] = schema.image_url;
    $[11] = schema.label;
    $[12] = style;
    $[13] = t5;
  } else {
    t5 = $[13];
  }
  let t6;
  if ($[14] !== name || $[15] !== t4 || $[16] !== t5) {
    t6 = <Field name={name} validate={t4}>{t5}</Field>;
    $[14] = name;
    $[15] = t4;
    $[16] = t5;
    $[17] = t6;
  } else {
    t6 = $[17];
  }
  return t6;
};
export default Diagram;
export const toString = value => {
  return value || '';
};
export const View = t0 => {
  const $ = _c(7);
  const {
    value,
    schema
  } = t0;
  const ref = React.useRef(null);
  const {
    width,
    height
  } = useComponentSize(ref);
  let t1;
  const strokes = value || [];
  t1 = toSVG(strokes, width, height).replace("\n", " ");
  const svgDataUri = t1;
  const t2 = `${svgDataUri}`;
  let t3;
  if ($[0] !== t2) {
    t3 = <Box position="absolute" as="img" width="100%" height="100%" alt="drawing" src={t2} />;
    $[0] = t2;
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  let t4;
  if ($[2] !== schema.image_url) {
    t4 = schema.image_url && <Box as="img" width="100%" ref={ref} alt="image" src={schema.image_url} />;
    $[2] = schema.image_url;
    $[3] = t4;
  } else {
    t4 = $[3];
  }
  let t5;
  if ($[4] !== t3 || $[5] !== t4) {
    t5 = <Box position="relative">{t3}{t4}</Box>;
    $[4] = t3;
    $[5] = t4;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  return t5;
};
export const Edit = t0 => {
  const $ = _c(13);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style,
    disabled
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp2);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== disabled || $[3] !== hideRequired || $[4] !== isEditing || $[5] !== isUniqueFieldName || $[6] !== menu || $[7] !== removable || $[8] !== showClientFieldReference || $[9] !== showMore || $[10] !== style || $[11] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp3}>{t3 => <Diagram schema={value} disabled={disabled} menu={menu} style={style} className={className} />}</Form> : <Grid display={!isEditing ? "none" : undefined}><LabelField prefix="" /><Field name="image_url" validate={_temp4}>{_temp5}</Field>{!hideRequired && <RequiredField prefix="" />}<HelpTextField prefix="" />{showMore && <><Field name="print_width">{_temp6}</Field>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} size="small" width="100%" variant="outlined">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = disabled;
    $[3] = hideRequired;
    $[4] = isEditing;
    $[5] = isUniqueFieldName;
    $[6] = menu;
    $[7] = removable;
    $[8] = showClientFieldReference;
    $[9] = showMore;
    $[10] = style;
    $[11] = value;
    $[12] = t2;
  } else {
    t2 = $[12];
  }
  return t2;
};
function _temp(v) {
  return v;
}
function _temp2(v) {
  return !v;
}
function _temp3(value_0) {
  return null;
}
function _temp4(value_1) {
  if (!value_1) {
    return "Image required";
  }
}
function _temp5(t0) {
  const {
    input,
    meta
  } = t0;
  return <Box data-sentry-element="Box" data-sentry-component="_temp5" data-sentry-source-file="index.tsx"><Label mb={2} data-sentry-element="Label" data-sentry-source-file="index.tsx" data-sentry-element="Label">Image</Label><ImageInput {...input} data-sentry-element="ImageInput" data-sentry-source-file="index.tsx" data-sentry-element="ImageInput" />{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</Box>;
}
function _temp6(t0) {
  const {
    input: input_0
  } = t0;
  return <Box data-sentry-element="Box" data-sentry-component="_temp6" data-sentry-source-file="index.tsx"><Select {...input_0} label="Print width" data-sentry-element="Select" data-sentry-source-file="index.tsx" data-sentry-element="Select"><option value="">100%</option><option value="75%">75%</option><option value="50%">50%</option></Select></Box>;
}