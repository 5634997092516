import { c as _c } from "react/compiler-runtime";
import { DateTime } from 'luxon';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import TimePicker from '../../time-picker';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from '../shared';
const Time = t0 => {
  const $ = _c(17);
  const {
    schema,
    disabled,
    menu,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const prefix = React.useContext(FieldPrefixContext);
  const t2 = isCustom && "custom_fields";
  let t3;
  if ($[0] !== prefix || $[1] !== schema.name || $[2] !== t2) {
    t3 = [prefix, t2, schema.name].filter(_temp);
    $[0] = prefix;
    $[1] = schema.name;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const name = t3.join(".");
  let t4;
  if ($[4] !== schema.required) {
    t4 = value => {
      if (schema.required && !value) {
        return "Enter a time";
      }
    };
    $[4] = schema.required;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] !== className || $[7] !== disabled || $[8] !== menu || $[9] !== schema.helptext || $[10] !== schema.label || $[11] !== style) {
    t5 = t6 => {
      const {
        input,
        meta
      } = t6;
      return <Box style={style} className={className}><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><TimePicker {...input} useLocalTime={true} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error} />{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[6] = className;
    $[7] = disabled;
    $[8] = menu;
    $[9] = schema.helptext;
    $[10] = schema.label;
    $[11] = style;
    $[12] = t5;
  } else {
    t5 = $[12];
  }
  let t6;
  if ($[13] !== name || $[14] !== t4 || $[15] !== t5) {
    t6 = <Field name={name} validate={t4}>{t5}</Field>;
    $[13] = name;
    $[14] = t4;
    $[15] = t5;
    $[16] = t6;
  } else {
    t6 = $[16];
  }
  return t6;
};
export default Time;
export const toString = value => {
  return value ? DateTime.fromISO(value).toFormat('t') : '';
};
export const View = t0 => {
  const $ = _c(4);
  const {
    value
  } = t0;
  let t1;
  if ($[0] !== value) {
    t1 = value && DateTime.fromISO(value).toFormat("t");
    $[0] = value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== t1) {
    t2 = <Box>{t1}</Box>;
    $[2] = t1;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  return t2;
};
export const Edit = t0 => {
  const $ = _c(12);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp2);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== hideRequired || $[3] !== isEditing || $[4] !== isUniqueFieldName || $[5] !== menu || $[6] !== removable || $[7] !== showClientFieldReference || $[8] !== showMore || $[9] !== style || $[10] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp3}>{t3 => <Time schema={value} disabled={true} menu={menu} style={style} className={className} />}</Form> : <Grid sx={{
        display: !isEditing ? "none" : null
      }}><LabelField prefix="" />{!hideRequired && <RequiredField prefix="" />}<HelpTextField prefix="" />{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} size="small" width="100%" variant="outlined">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = hideRequired;
    $[3] = isEditing;
    $[4] = isUniqueFieldName;
    $[5] = menu;
    $[6] = removable;
    $[7] = showClientFieldReference;
    $[8] = showMore;
    $[9] = style;
    $[10] = value;
    $[11] = t2;
  } else {
    t2 = $[11];
  }
  return t2;
};
function _temp(v) {
  return v;
}
function _temp2(v) {
  return !v;
}
function _temp3(value_0) {
  return null;
}