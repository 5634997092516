import { c as _c } from "react/compiler-runtime";
import { Theme } from '@emotion/react';
import Color from 'color';
import React from 'react';
import { Box, BoxProps } from '../grid';
import { ThemeContext } from '@emotion/react';
export function InputGroupLabel(t0) {
  const $ = _c(14);
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  const theme = React.useContext(ThemeContext as any);
  let fontColor;
  const backgroundColor = theme.colors.secondary;
  if (Color(backgroundColor).contrast(Color(theme.colors.black)) > Color(backgroundColor).contrast(Color(theme.colors.white))) {
    fontColor = theme.colors.black;
  } else {
    fontColor = theme.colors.white;
  }
  const t1 = `${theme.radii[1]}px`;
  let t2;
  let t3;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      marginRight: "-1px"
    };
    t3 = {
      borderLeft: 0
    };
    $[3] = t2;
    $[4] = t3;
  } else {
    t2 = $[3];
    t3 = $[4];
  }
  let t4;
  if ($[5] !== backgroundColor || $[6] !== fontColor || $[7] !== t1 || $[8] !== theme.fonts.body) {
    t4 = {
      display: "flex",
      fontFamily: theme.fonts.body,
      margin: 0,
      whiteSpace: "nowrap",
      padding: "0 1rem",
      textAlign: "center",
      flex: "0 0 auto",
      alignItems: "center",
      borderRadius: t1,
      lineHeight: 1,
      backgroundColor,
      color: fontColor,
      "&:first-child": t2,
      "&:last-child": t3
    };
    $[5] = backgroundColor;
    $[6] = fontColor;
    $[7] = t1;
    $[8] = theme.fonts.body;
    $[9] = t4;
  } else {
    t4 = $[9];
  }
  let t5;
  if ($[10] !== props || $[11] !== ref || $[12] !== t4) {
    t5 = <Box ref={ref} __css={t4} {...props} />;
    $[10] = props;
    $[11] = ref;
    $[12] = t4;
    $[13] = t5;
  } else {
    t5 = $[13];
  }
  return t5;
}
export function InputGroup({
  ref,
  ...props
}: BoxProps & {
  ref?: React.Ref<HTMLDivElement>;
}) {
  return <Box ref={ref} __css={{
    display: 'flex',
    width: '100%',
    alignItems: 'stretch',
    button: {
      flexShrink: 0
    },
    '& > :focus + *': {
      borderLeftColor: 'transparent'
    },
    [`& > :not(button):not(${InputGroupLabel}) + :not(:first-child)`]: {
      '&:not(:focus), *:not(:focus)': {
        borderLeftColor: 'transparent'
      }
    },
    '& > :first-child:not(:last-child)': {
      "&, input, select, [role='button'], button": {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    '& > :last-child:not(:first-child)': {
      "&, input, select, [role='button'], button": {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
      }
    }
  }} {...props} data-sentry-element="Box" data-sentry-component="InputGroup" data-sentry-source-file="index.tsx" />;
}
export default InputGroup;