import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Box } from './grid';
export function Arrow2(t0) {
  const $ = _c(50);
  const {
    ref,
    styles,
    placement,
    arrowSize: t1,
    borderWidth: t2,
    color: t3,
    borderColor: t4
  } = t0;
  const arrowSize = t1 === undefined ? 8 : t1;
  const borderWidth = t2 === undefined ? 1 : t2;
  const color = t3 === undefined ? "background" : t3;
  const borderColor = t4 === undefined ? "gray.1" : t4;
  const p = placement?.split("-")?.[0] || "bottom";
  const t5 = `${arrowSize}px ${arrowSize}px 0 ${arrowSize}px`;
  const t6 = `-${arrowSize}px`;
  const t7 = `${-(arrowSize - borderWidth * 2 - 1)}px`;
  const t8 = `${-(arrowSize - borderWidth)}px`;
  const t9 = `${arrowSize - borderWidth}px`;
  let t10;
  if ($[0] !== color || $[1] !== t7 || $[2] !== t8 || $[3] !== t9) {
    t10 = {
      content: "' '",
      pointerEvents: "none",
      border: "solid transparent",
      bottom: t7,
      left: t8,
      position: "absolute",
      borderColor: "rgba(194, 225, 245, 0)",
      borderTopColor: color,
      borderWidth: t9
    };
    $[0] = color;
    $[1] = t7;
    $[2] = t8;
    $[3] = t9;
    $[4] = t10;
  } else {
    t10 = $[4];
  }
  let t11;
  if ($[5] !== borderColor || $[6] !== t10 || $[7] !== t5 || $[8] !== t6) {
    t11 = {
      borderWidth: t5,
      borderColor: "transparent",
      borderTopColor: borderColor,
      bottom: t6,
      "&:before": t10
    };
    $[5] = borderColor;
    $[6] = t10;
    $[7] = t5;
    $[8] = t6;
    $[9] = t11;
  } else {
    t11 = $[9];
  }
  const t12 = `${arrowSize}px ${arrowSize}px ${arrowSize}px 0`;
  const t13 = `-${arrowSize}px`;
  const t14 = `-${arrowSize - borderWidth}px`;
  const t15 = `${-(arrowSize - borderWidth * 2 - 1)}px`;
  const t16 = `${arrowSize - borderWidth}px`;
  let t17;
  if ($[10] !== color || $[11] !== t14 || $[12] !== t15 || $[13] !== t16) {
    t17 = {
      content: "' '",
      pointerEvents: "none",
      border: "solid transparent",
      top: t14,
      left: t15,
      position: "absolute",
      borderColor: "rgba(194, 225, 245, 0)",
      borderRightColor: color,
      borderWidth: t16
    };
    $[10] = color;
    $[11] = t14;
    $[12] = t15;
    $[13] = t16;
    $[14] = t17;
  } else {
    t17 = $[14];
  }
  let t18;
  if ($[15] !== borderColor || $[16] !== t12 || $[17] !== t13 || $[18] !== t17) {
    t18 = {
      borderWidth: t12,
      borderColor: "transparent",
      borderRightColor: borderColor,
      left: t13,
      "&:before": t17
    };
    $[15] = borderColor;
    $[16] = t12;
    $[17] = t13;
    $[18] = t17;
    $[19] = t18;
  } else {
    t18 = $[19];
  }
  const t19 = `0 ${arrowSize}px ${arrowSize}px ${arrowSize}px`;
  const t20 = `-${arrowSize}px`;
  const t21 = `${-(arrowSize - borderWidth * 2 - 1)}px`;
  const t22 = `${-(arrowSize - borderWidth)}px`;
  const t23 = `${arrowSize - borderWidth}px`;
  let t24;
  if ($[20] !== color || $[21] !== t21 || $[22] !== t22 || $[23] !== t23) {
    t24 = {
      content: "' '",
      pointerEvents: "none",
      border: "solid transparent",
      top: t21,
      left: t22,
      position: "absolute",
      borderColor: "rgba(194, 225, 245, 0)",
      borderBottomColor: color,
      borderWidth: t23
    };
    $[20] = color;
    $[21] = t21;
    $[22] = t22;
    $[23] = t23;
    $[24] = t24;
  } else {
    t24 = $[24];
  }
  let t25;
  if ($[25] !== borderColor || $[26] !== t19 || $[27] !== t20 || $[28] !== t24) {
    t25 = {
      borderWidth: t19,
      borderColor: "transparent",
      borderBottomColor: borderColor,
      top: t20,
      "&:before": t24
    };
    $[25] = borderColor;
    $[26] = t19;
    $[27] = t20;
    $[28] = t24;
    $[29] = t25;
  } else {
    t25 = $[29];
  }
  const t26 = `${arrowSize}px 0 ${arrowSize}px ${arrowSize}px`;
  const t27 = `-${arrowSize}px`;
  const t28 = `-${arrowSize - borderWidth}px`;
  const t29 = `${-(arrowSize - borderWidth * 2 - 1)}px`;
  const t30 = `${arrowSize - borderWidth}px`;
  let t31;
  if ($[30] !== color || $[31] !== t28 || $[32] !== t29 || $[33] !== t30) {
    t31 = {
      content: "' '",
      pointerEvents: "none",
      border: "solid transparent",
      top: t28,
      right: t29,
      position: "absolute",
      borderColor: "rgba(194, 225, 245, 0)",
      borderLeftColor: color,
      borderWidth: t30
    };
    $[30] = color;
    $[31] = t28;
    $[32] = t29;
    $[33] = t30;
    $[34] = t31;
  } else {
    t31 = $[34];
  }
  let t32;
  if ($[35] !== borderColor || $[36] !== t26 || $[37] !== t27 || $[38] !== t31) {
    t32 = {
      borderWidth: t26,
      borderColor: "transparent",
      borderLeftColor: borderColor,
      right: t27,
      "&:before": t31
    };
    $[35] = borderColor;
    $[36] = t26;
    $[37] = t27;
    $[38] = t31;
    $[39] = t32;
  } else {
    t32 = $[39];
  }
  let t33;
  if ($[40] !== t11 || $[41] !== t18 || $[42] !== t25 || $[43] !== t32) {
    t33 = {
      top: t11,
      right: t18,
      bottom: t25,
      left: t32
    };
    $[40] = t11;
    $[41] = t18;
    $[42] = t25;
    $[43] = t32;
    $[44] = t33;
  } else {
    t33 = $[44];
  }
  const s = t33;
  const t34 = s[p];
  let t35;
  if ($[45] !== t34) {
    t35 = {
      width: "1px",
      height: 0,
      borderStyle: "solid",
      position: "absolute",
      ...t34
    };
    $[45] = t34;
    $[46] = t35;
  } else {
    t35 = $[46];
  }
  let t36;
  if ($[47] !== styles || $[48] !== t35) {
    t36 = <Box ref={ref} sx={t35} style={styles} />;
    $[47] = styles;
    $[48] = t35;
    $[49] = t36;
  } else {
    t36 = $[49];
  }
  return t36;
}