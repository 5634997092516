import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Box, BoxProps } from '../grid';
export const trimTextNode = ({
  lineHeight
}: {
  lineHeight: number;
}) => {
  const descenderHeightScale = 0.13;
  const capHeightScale = 0.77;
  const typeOffset = (lineHeight - 1) / 2 + descenderHeightScale;
  const heightCorrection = lineHeight - capHeightScale;
  const preventCollapse = 1;
  return {
    paddingTop: `${preventCollapse}px`,
    /* baseline on bottom of container */
    transform: `translateY(${typeOffset}em)`,
    /* crop the top of the text node */
    '&:before': {
      content: '""',
      marginTop: `calc(${-heightCorrection}em - ${preventCollapse}px)`,
      display: 'block',
      height: 0
    }
  };
};
export function CroppedText(t0) {
  const $ = _c(11);
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  const t1 = Number(props.lineHeight) || 1.6;
  let t2;
  if ($[3] !== t1) {
    t2 = trimTextNode({
      lineHeight: t1
    });
    $[3] = t1;
    $[4] = t2;
  } else {
    t2 = $[4];
  }
  let t3;
  if ($[5] !== t2) {
    t3 = {
      fontFamily: "inherit",
      fontWeight: "inherit",
      lineHeight: "body",
      fontSize: 2,
      ...t2
    };
    $[5] = t2;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  let t4;
  if ($[7] !== props || $[8] !== ref || $[9] !== t3) {
    t4 = <Box ref={ref} __css={t3} {...props} />;
    $[7] = props;
    $[8] = ref;
    $[9] = t3;
    $[10] = t4;
  } else {
    t4 = $[10];
  }
  return t4;
}
export function Text(t0) {
  const $ = _c(7);
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  let t1;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      fontFamily: "inherit",
      fontWeight: "inherit",
      lineHeight: "body",
      fontSize: 2,
      wordBreak: "break-word"
    };
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  let t2;
  if ($[4] !== props || $[5] !== ref) {
    t2 = <Box ref={ref} __css={t1} {...props} />;
    $[4] = props;
    $[5] = ref;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  return t2;
}
export function Heading(t0) {
  const $ = _c(7);
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  let t1;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      fontSize: 4,
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: "heading",
      color: "inherit"
    };
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  let t2;
  if ($[4] !== props || $[5] !== ref) {
    t2 = <Box ref={ref} as="h2" tx="text" variant="heading" {...props} __css={t1} />;
    $[4] = props;
    $[5] = ref;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  return t2;
}
export function Link(t0) {
  const $ = _c(7);
  let props;
  let ref;
  if ($[0] !== t0) {
    ({
      ref,
      ...props
    } = t0);
    $[0] = t0;
    $[1] = props;
    $[2] = ref;
  } else {
    props = $[1];
    ref = $[2];
  }
  let t1;
  if ($[3] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = {
      fontFamily: "inherit",
      fontWeight: "heading",
      fontSize: "inherit",
      lineHeight: "body",
      display: "inline",
      textAlign: "inherit"
    };
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  let t2;
  if ($[4] !== props || $[5] !== ref) {
    t2 = <Box ref={ref} as="a" tx="text" variant="link" __css={t1} {...props} />;
    $[4] = props;
    $[5] = ref;
    $[6] = t2;
  } else {
    t2 = $[6];
  }
  return t2;
}
export const Anchor = Link;