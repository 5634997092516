import { c as _c } from "react/compiler-runtime";
import { faNoteMedical } from '@fortawesome/pro-regular-svg-icons';
import humanizeDuration from 'humanize-duration';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as ReachLink } from 'react-router-dom';
import { Avatar } from 'shared/avatar';
import Button from 'shared/button';
import EmptyState from 'shared/empty-state';
import { Box, Grid } from 'shared/grid';
import Loader from 'shared/loader';
import Select from 'shared/select';
import Stamp from 'shared/stamp';
import { Text } from 'shared/typography';
import { groupSubstitute } from 'shared/utils/booking-tools';
import { getDarkColor } from 'shared/utils/color';
import { CONFIRM_PUBLIC_BOOKING_POLICY } from 'shared/utils/constants';
import { getCdnImageUrlFromId } from 'shared/utils/images';
import { buildBookableOptionsList } from 'shared/utils/schedule';
import { getTranslation } from 'shared/utils/text';
import { useLocationId } from '../../hooks';
import { useAppSelector } from '../../store';
import { BorderBox } from '../shared';
const Service = t0 => {
  const $ = _c(25);
  const {
    service,
    locationId
  } = t0;
  const t1 = `/services/${service.id}` + (locationId ? `?lid=${locationId}` : "");
  let t2;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      "&:hover": {
        borderColor: "gray.3"
      }
    };
    $[0] = t2;
  } else {
    t2 = $[0];
  }
  const t3 = service.name;
  let t4;
  if ($[1] !== service.image_id) {
    t4 = service.image_id ? getCdnImageUrlFromId(service.image_id) : null;
    $[1] = service.image_id;
    $[2] = t4;
  } else {
    t4 = $[2];
  }
  let t5;
  if ($[3] !== service.color) {
    t5 = getDarkColor(service.color);
    $[3] = service.color;
    $[4] = t5;
  } else {
    t5 = $[4];
  }
  let t6;
  if ($[5] !== service.name || $[6] !== t4 || $[7] !== t5) {
    t6 = <Avatar flex="0 0 60px" name={t3} image={t4} color={t5} mr={3} size={60} />;
    $[5] = service.name;
    $[6] = t4;
    $[7] = t5;
    $[8] = t6;
  } else {
    t6 = $[8];
  }
  let t7;
  if ($[9] !== service.name) {
    t7 = <Text display="block" fontSize={3} fontFamily="heading">{service.name}</Text>;
    $[9] = service.name;
    $[10] = t7;
  } else {
    t7 = $[10];
  }
  let t8;
  if ($[11] !== service.description) {
    t8 = (service.description || "").trim() && <Grid gridGap={1}>{service.description.trim().split("\n").filter(_temp).map(_temp2)}</Grid>;
    $[11] = service.description;
    $[12] = t8;
  } else {
    t8 = $[12];
  }
  let t9;
  if ($[13] !== service.durations) {
    t9 = service.durations.map(_temp3);
    $[13] = service.durations;
    $[14] = t9;
  } else {
    t9 = $[14];
  }
  let t10;
  if ($[15] !== t9) {
    t10 = <Box mb={-1} mr={-1}>{t9}</Box>;
    $[15] = t9;
    $[16] = t10;
  } else {
    t10 = $[16];
  }
  let t11;
  if ($[17] !== t10 || $[18] !== t7 || $[19] !== t8) {
    t11 = <Grid gridGap={2}>{t7}{t8}{t10}</Grid>;
    $[17] = t10;
    $[18] = t7;
    $[19] = t8;
    $[20] = t11;
  } else {
    t11 = $[20];
  }
  let t12;
  if ($[21] !== t1 || $[22] !== t11 || $[23] !== t6) {
    t12 = <BorderBox borderColor="borderColor" as={ReachLink} to={t1} display="flex" sx={t2}>{t6}{t11}</BorderBox>;
    $[21] = t1;
    $[22] = t11;
    $[23] = t6;
    $[24] = t12;
  } else {
    t12 = $[24];
  }
  return t12;
};
const ServicesView = () => {
  const [locationId, setLocationId] = useLocationId();
  const [a, setA] = React.useState(null);
  const serviceData = useAppSelector(state => state.services.data);
  const business = useAppSelector(state_0 => state_0.businesses.data[state_0.public.businessId]);
  const hasClientRequests = business.feature_flags?.includes('client_requests');
  const bookableData = useAppSelector(state_1 => state_1.bookables.data);
  const bookableGroupData = useAppSelector(state_2 => state_2.bookableGroups.data);
  const language = useAppSelector(state_3 => state_3.userPreferences.language);
  const allServices = React.useMemo(() => Object.values<any>(serviceData).filter(v => !v.is_deleted).filter(v_0 => v_0.is_public == true).map(v_1 => ({
    ...v_1,
    name: getTranslation(v_1.name, language)
  })).sort((a_0, b) => a_0.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1).sort((a_1, b_0) => a_1.index < b_0.index ? -1 : 1), [serviceData, language]);
  const services = React.useMemo(() => allServices.filter(service => {
    const locationIds = locationId ? [locationId] : service.locations.map(sl => sl.location_id);
    return locationIds.some(locationId_0 => {
      const serviceLocation = service.locations.find(sl_0 => sl_0.location_id === locationId_0);
      if (!serviceLocation) {
        return false;
      }
      const requirements = groupSubstitute(serviceLocation.requirements, locationId_0, bookableData, bookableGroupData);
      const requirementsList = buildBookableOptionsList(requirements, bookableData);
      return requirementsList.length > 0;
    });
  }).map(service_0 => ({
    ...service_0,
    durations: [...new Set<number>(service_0.locations.flatMap(serviceDuration => serviceDuration.service_durations).map(sd => sd.duration))].sort((a_2, b_1) => a_2 < b_1 ? -1 : 1).map(mins => humanizeDuration(mins * 1000 * 60, {
      delimiter: ' and '
    })),
    locations: service_0.locations?.map(serviceLocation_0 => {
      return {
        ...serviceLocation_0,
        has_missing_pricing: serviceLocation_0.service_durations.some(serviceDuration_0 => serviceDuration_0.pricing_model === 'hidden' || serviceDuration_0.pricing_model === 'simple' && serviceDuration_0.price === undefined || serviceDuration_0.pricing_model === 'billable_items' && serviceDuration_0.billable_items.length === 0)
      };
    }, {})
  })).filter(service_1 => service_1.confirm_public_booking_policy !== CONFIRM_PUBLIC_BOOKING_POLICY.payment || !service_1.locations.every(sl_1 => sl_1.has_missing_pricing)), [allServices, bookableData, bookableGroupData, locationId]);
  const locationData = useAppSelector(state_4 => state_4.locations.data);
  const locations = React.useMemo(() => (business?.location_ids || []).map(id => locationData[id]).filter(l => allServices.some(service_2 => !!service_2.locations.find(sl_2 => l?.id === sl_2.location_id))), [allServices, business?.location_ids, locationData]);
  const isLoading = useAppSelector(state_5 => [state_5.businesses.status, state_5.services.status].includes('loading'));
  if (isLoading) {
    return <Loader />;
  }
  if (business.is_deleted || business.subscription_status === 'cancelled' || !business.online || allServices.length === 0 && locations.length > 1) {
    return <>
        <EmptyState heading={<FormattedMessage id="Public.ServicesView.notTakingBookings" defaultMessage="Unfortunately we are not currently taking online bookings." />} subheading={<FormattedMessage id="Public.ServicesView.notTakingBookingsSubheading" defaultMessage="Contact us to enquire about availability." />} callToAction={<Button as={ReachLink} className="hide-for-small-only" mr={2} variant="outlined" color="gray" to={`/contact` + (locationId ? `?lid=${locationId}` : '')}>
              <FormattedMessage id="Contact us" defaultMessage="Contact us" />
            </Button>} />
      </>;
  }
  return <Box flex="1" mt={3} data-sentry-element="Box" data-sentry-component="ServicesView" data-sentry-source-file="component.tsx">
      <Grid data-sentry-element="Grid" data-sentry-source-file="component.tsx">
        {locations.length > 1 && <Select variant="outlined" onChange={e => setLocationId(e.target.value)} value={locationId || ''}>
            <FormattedMessage id="All" defaultMessage="All locations">
              {text => <option value="">{text}</option>}
            </FormattedMessage>

            {locations.map((location, idx) => <option key={idx} value={location.id}>
                {location.name}
              </option>)}
          </Select>}
        {services.map((service_3, idx_0) => <Service key={idx_0} service={service_3} locationId={locationId} />)}
        {hasClientRequests && <BorderBox borderColor="borderColor" as={ReachLink} to={`/request-prescription` + (locationId ? `?lid=${locationId}` : '')} display="flex" sx={{
        '&:hover': {
          borderColor: 'gray.3'
        }
      }}>
            <Avatar flex="0 0 60px" icon={faNoteMedical} mr={3} size={60} />
            <Grid gridGap={2}>
              <Text display="block" fontSize={3} fontFamily="heading">
                <FormattedMessage id="Public.RequestPrescription.requestPrescription" defaultMessage="Request a prescription" />
              </Text>
            </Grid>
          </BorderBox>}
      </Grid>
      {services.length === 0 && <EmptyState heading={<FormattedMessage id="Public.ServicesView.notServicesAvailable" defaultMessage="No services available" />} subheading={<FormattedMessage id="Public.ServicesView.notTakingBookingsSubheading" defaultMessage="Contact us to enquire about availability." />} callToAction={<Button as={ReachLink} className="hide-for-small-only" mr={2} variant="outlined" color="gray" to={`/contact` + (locationId ? `?lid=${locationId}` : '')}>
              <FormattedMessage id="Contact us" defaultMessage="Contact us" />
            </Button>} />}
    </Box>;
};
export default ServicesView;
function _temp(v) {
  return v;
}
function _temp2(v_0, idx) {
  return <Text key={idx} data-sentry-element="Text" data-sentry-component="_temp2" data-sentry-source-file="component.tsx">{v_0}</Text>;
}
function _temp3(v_1, idx_0) {
  return <Stamp color="gray.6" key={idx_0} mr={1} mb={1} data-sentry-element="Stamp" data-sentry-component="_temp3" data-sentry-source-file="component.tsx">{v_1}</Stamp>;
}