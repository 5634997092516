import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { DateTime } from 'luxon';
import Input from 'shared/input';
import { BoxProps } from '../grid';

// Only hours and minutes, no timezone or UTC
const validateRegex = /^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d(:[0-5]\d)?(?:Z|\+00:00)?$/;
interface TimePickerProps
// extends Omit<
//   React.InputHTMLAttributes<HTMLInputElement>,
//   'onChange' | 'prefix'
// > {
extends Omit<BoxProps, 'onChange' | 'prefix'> {
  value: string;
  onChange: (value: string) => any;
  style?: Record<string, any>;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  displayTimezone?: string;
  useLocalTime: boolean;
  defaultDate?: string;
  invalid?: boolean;
}
const timeFmt = 'HH:mm';
const isValidDate = v => (v || '').match(validateRegex);
const isValidTime = v => (v || '').match(/^[0-2]\d:[0-5]\d(:[0-5]\d)?$/);
const toDisplayText = (value, displayTimezone) => {
  try {
    return value && DateTime.fromISO(value, {
      zone: 'UTC'
    }) // If no tz interpret as utc
    .setZone(displayTimezone).toFormat(timeFmt);
    // eslint-disable-next-line no-empty
  } catch (e) {}
};

/* eslint-disable-next-line react/display-name */
const TimePicker = t0 => {
  const $ = _c(30);
  let defaultDate;
  let onBlur;
  let onChange;
  let ref;
  let rest;
  let t1;
  let t2;
  let value;
  if ($[0] !== t0) {
    ({
      ref,
      value,
      onChange,
      onBlur,
      displayTimezone: t1,
      useLocalTime: t2,
      defaultDate,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = defaultDate;
    $[2] = onBlur;
    $[3] = onChange;
    $[4] = ref;
    $[5] = rest;
    $[6] = t1;
    $[7] = t2;
    $[8] = value;
  } else {
    defaultDate = $[1];
    onBlur = $[2];
    onChange = $[3];
    ref = $[4];
    rest = $[5];
    t1 = $[6];
    t2 = $[7];
    value = $[8];
  }
  const displayTimezone = t1 === undefined ? "UTC" : t1;
  const useLocalTime = t2 === undefined ? false : t2;
  if (useLocalTime && value?.endsWith("Z")) {
    console.error("Don't input UTC times when using localtime");
  }
  if (useLocalTime && displayTimezone !== "UTC") {
    console.error("Don't specify a timezone for localtimes");
  }
  const [displayText, setDisplayText_] = React.useState("");
  let t3;
  if ($[9] !== defaultDate || $[10] !== displayTimezone || $[11] !== onChange || $[12] !== useLocalTime || $[13] !== value) {
    t3 = newDisplayText => {
      setDisplayText_(newDisplayText);
      const valueDisplayText = toDisplayText(value, displayTimezone);
      if (newDisplayText === valueDisplayText) {
        return;
      }
      if (!isValidTime(newDisplayText)) {
        return;
      }
      const timeStringNoSeconds = newDisplayText.split(":").splice(0, 2).join(":");
      const dateTime = DateTime.fromFormat(timeStringNoSeconds, timeFmt, {
        zone: displayTimezone || "UTC"
      });
      let dt;
      if (isValidDate(value)) {
        dt = DateTime.fromISO(value, {
          zone: "UTC"
        }).setZone(displayTimezone);
      } else {
        dt = (defaultDate && DateTime.fromISO(defaultDate, {
          zone: "UTC"
        }) || DateTime.utc()).setZone(displayTimezone);
      }
      dt = dt.set({
        hour: dateTime.hour,
        minute: dateTime.minute
      });
      const newValue = `${dt.setZone("UTC").set({
        second: 0
      }).toISO().substring(0, 19)}${useLocalTime ? "" : "Z"}`;
      onChange(newValue);
    };
    $[9] = defaultDate;
    $[10] = displayTimezone;
    $[11] = onChange;
    $[12] = useLocalTime;
    $[13] = value;
    $[14] = t3;
  } else {
    t3 = $[14];
  }
  const setDisplayText = t3;
  let t4;
  let t5;
  if ($[15] !== displayTimezone || $[16] !== value) {
    t4 = () => {
      if (!isValidDate(value)) {
        return;
      }
      const displayText_0 = DateTime.fromISO(value, {
        zone: "UTC"
      }).setZone(displayTimezone).toFormat(timeFmt);
      setDisplayText_(displayText_0);
    };
    t5 = [displayTimezone, value];
    $[15] = displayTimezone;
    $[16] = value;
    $[17] = t4;
    $[18] = t5;
  } else {
    t4 = $[17];
    t5 = $[18];
  }
  React.useEffect(t4, t5);
  const t6 = rest as any;
  let t7;
  if ($[19] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = {
      "&::-webkit-clear-button": {
        display: "none"
      },
      "&::-ms-clear": {
        display: "none"
      },
      "&::-webkit-calendar-picker-indicator": {
        display: "none"
      }
    };
    $[19] = t7;
  } else {
    t7 = $[19];
  }
  let t8;
  if ($[20] !== onBlur) {
    t8 = e => {
      if (onBlur) {
        onBlur(e);
      }
    };
    $[20] = onBlur;
    $[21] = t8;
  } else {
    t8 = $[21];
  }
  let t9;
  if ($[22] !== setDisplayText) {
    t9 = v => setDisplayText(v.target.value);
    $[22] = setDisplayText;
    $[23] = t9;
  } else {
    t9 = $[23];
  }
  let t10;
  if ($[24] !== displayText || $[25] !== ref || $[26] !== t6 || $[27] !== t8 || $[28] !== t9) {
    t10 = <Input {...t6} required={true} sx={t7} step="300" ref={ref} placeholder="HH:MM" type="time" value={displayText} onBlur={t8} onChange={t9} />;
    $[24] = displayText;
    $[25] = ref;
    $[26] = t6;
    $[27] = t8;
    $[28] = t9;
    $[29] = t10;
  } else {
    t10 = $[29];
  }
  return t10;
};
export default React.memo(TimePicker);