import { c as _c } from "react/compiler-runtime";
import React from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import FormError from 'shared/form-error';
import { StyledInput, StyledInputProps } from '../input';
import Label from '../label';
type Props = {
  label?: any;
  error?: string;
} & StyledInputProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>;

// eslint-disable-next-line react/display-name
const TextArea = t0 => {
  const $ = _c(29);
  let error;
  let id;
  let label;
  let ref;
  let rest;
  if ($[0] !== t0) {
    ({
      ref,
      label,
      error,
      id,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = error;
    $[2] = id;
    $[3] = label;
    $[4] = ref;
    $[5] = rest;
  } else {
    error = $[1];
    id = $[2];
    label = $[3];
    ref = $[4];
    rest = $[5];
  }
  const [isFocused, setFocus] = React.useState(false);
  let t1;
  if ($[6] !== error || $[7] !== id || $[8] !== isFocused || $[9] !== label || $[10] !== rest.name) {
    t1 = label && <Label mb={2} htmlFor={id || rest.name} isFocused={isFocused} invalid={!!error}>{label}</Label>;
    $[6] = error;
    $[7] = id;
    $[8] = isFocused;
    $[9] = label;
    $[10] = rest.name;
    $[11] = t1;
  } else {
    t1 = $[11];
  }
  let t2;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      borderRadius: 2,
      minHeight: "70px",
      resize: "vertical"
    };
    $[12] = t2;
  } else {
    t2 = $[12];
  }
  const t3 = id || rest.name;
  const t4 = !!error;
  let t5;
  let t6;
  if ($[13] !== rest) {
    t5 = e => {
      if (rest.onFocus) {
        rest.onFocus(e);
      }
      setFocus(true);
    };
    t6 = e_0 => {
      if (rest.onBlur) {
        rest.onBlur(e_0);
      }
      setFocus(false);
    };
    $[13] = rest;
    $[14] = t5;
    $[15] = t6;
  } else {
    t5 = $[14];
    t6 = $[15];
  }
  const t7 = rest as any;
  let t8;
  if ($[16] !== ref || $[17] !== t3 || $[18] !== t4 || $[19] !== t5 || $[20] !== t6 || $[21] !== t7) {
    t8 = <StyledInput as={TextareaAutosize} __css={t2} id={t3} ref={ref} invalid={t4} onFocus={t5} onBlur={t6} {...t7} />;
    $[16] = ref;
    $[17] = t3;
    $[18] = t4;
    $[19] = t5;
    $[20] = t6;
    $[21] = t7;
    $[22] = t8;
  } else {
    t8 = $[22];
  }
  let t9;
  if ($[23] !== error) {
    t9 = error && <FormError>{error}</FormError>;
    $[23] = error;
    $[24] = t9;
  } else {
    t9 = $[24];
  }
  let t10;
  if ($[25] !== t1 || $[26] !== t8 || $[27] !== t9) {
    t10 = <>{t1}{t8}{t9}</>;
    $[25] = t1;
    $[26] = t8;
    $[27] = t9;
    $[28] = t10;
  } else {
    t10 = $[28];
  }
  return t10;
};
export default TextArea;