import { c as _c } from "react/compiler-runtime";
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { CroppedText } from 'shared/typography';
import Button from '../button';
import { Box, Flex, Grid } from '../grid';
import ScrollTabs from '../scroll-tabs';
import { Heading } from '../typography';
import Modal_, { ModalProps } from './component';
const nope = () => false;
export const withCloseWarning = (Component: React.ComponentType<any>) =>
// eslint-disable-next-line react/display-name
({
  close,
  hasUnsavedChanges,
  // = nope,
  ...props
}: {
  close: () => void;
  hasUnsavedChanges: boolean | (() => boolean);
} & any) => {
  const [warningVisible, setWarningVisible] = React.useState(false);
  const checkedClose = React.useCallback((skipCheck = false) => {
    if (skipCheck !== true && (typeof hasUnsavedChanges === 'function' ? hasUnsavedChanges() : hasUnsavedChanges)) {
      setWarningVisible(true);
    } else {
      if (close) {
        close();
      }
      setWarningVisible(false);
    }
  }, [close, hasUnsavedChanges]);
  const dismiss = React.useCallback(() => {
    if (close) {
      close();
    }
    setWarningVisible(false);
  }, [close]);
  return <>
        <Component {...props} close={close && checkedClose} />
        <Modal_ isOpen={warningVisible} close={() => setWarningVisible(false)}>
          {() => <>
              <ModalHeader close={() => setWarningVisible(false)} title="Unsaved changes" type="alert" />

              <Grid p={3}>
                <CroppedText>
                  You have unsaved changes. Would you like to discard them?
                </CroppedText>

                <Flex justifyContent="flex-end" mt={3}>
                  <Button mr={2} variant="flat" color="gray" onClick={() => setWarningVisible(false)}>
                    Back
                  </Button>

                  <Button color="alert" className="confirm-button" onClick={dismiss}>
                    Discard changes
                  </Button>
                </Flex>
              </Grid>
            </>}
        </Modal_>
      </>;
};
export function CloseButton(t0) {
  const $ = _c(15);
  let onClick;
  let rest;
  let t1;
  let t2;
  if ($[0] !== t0) {
    ({
      ref: t1,
      onClick,
      isDark: t2,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = onClick;
    $[2] = rest;
    $[3] = t1;
    $[4] = t2;
  } else {
    onClick = $[1];
    rest = $[2];
    t1 = $[3];
    t2 = $[4];
  }
  const ref = t1 === undefined ? null : t1;
  const isDark = t2 === undefined ? false : t2;
  const t3 = isDark ? "text" : "white";
  const t4 = isDark ? "gray.6" : "gray.1";
  let t5;
  if ($[5] !== t4) {
    t5 = {
      color: t4
    };
    $[5] = t4;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  let t6;
  if ($[7] !== t3 || $[8] !== t5) {
    t6 = {
      cursor: "pointer",
      color: t3,
      fontSize: "16px",
      "&:hover, &:active": t5
    };
    $[7] = t3;
    $[8] = t5;
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  let t7;
  if ($[10] === Symbol.for("react.memo_cache_sentinel")) {
    t7 = <FontAwesomeIcon size="lg" icon={faTimes} />;
    $[10] = t7;
  } else {
    t7 = $[10];
  }
  let t8;
  if ($[11] !== onClick || $[12] !== rest || $[13] !== t6) {
    t8 = <Box ref={ref} as="button" type="button" className="close" onClick={onClick} tabIndex={-1} sx={t6} {...rest}>{t7}</Box>;
    $[11] = onClick;
    $[12] = rest;
    $[13] = t6;
    $[14] = t8;
  } else {
    t8 = $[14];
  }
  return t8;
}
const ModalHeader_ = ({
  close,
  title,
  tabs = null,
  type = '',
  leftCorner = null,
  rightCorner = null,
  useEllipsis = true
}) => <Box borderBottomStyle="solid" borderBottomColor="borderColor" borderBottomWidth="1px" data-sentry-element="Box" data-sentry-component="ModalHeader_" data-sentry-source-file="index.tsx">
    <Flex justifyContent="center" alignItems="center" className="header" px={3} flexWrap="nowrap" sx={{
    position: 'relative'
  }} minHeight="50px" data-sentry-element="Flex" data-sentry-source-file="index.tsx">
      <Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx">{leftCorner}</Flex>

      <Flex justifyContent="center" py={3} flexGrow={1} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        <Heading fontSize={3} as="div"
      // maxWidth="calc(100% - 30px)"
      px={3} sx={useEllipsis ? {
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        overflowWrap: 'break-word'
      } : null} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
          {title}
        </Heading>
      </Flex>
      <Flex alignItems="center" py={3} mr={0} data-sentry-element="Flex" data-sentry-source-file="index.tsx">
        {rightCorner && <Box mr={2}>{rightCorner}</Box>}
        {close && <CloseButton isDark={true} onClick={close} />}
      </Flex>
    </Flex>
    {tabs && <Box flex="1 1 auto" overflow="hidden" mb="-1px" alignSelf="flex-end">
        {tabs}
      </Box>}
  </Box>;
export const ModalHeader = withCloseWarning(ModalHeader_);
const Modal = withCloseWarning(Modal_);
export default Modal;
interface ConfirmModalProps {
  isOpen: boolean;
  title: string;
  close?: () => void;
  className?: string;
  type?: string;
  onConfirm?: () => Promise<any> | void;
  confirmText?: string;
  dismissText?: string;
  leftOfButtons?: string | React.ReactElement | React.JSX.Element;
  children;
}
export const ConfirmModal = t0 => {
  const $ = _c(18);
  const {
    isOpen,
    title,
    close,
    className: t1,
    type: t2,
    onConfirm: t3,
    confirmText: t4,
    dismissText: t5,
    leftOfButtons: t6,
    children
  } = t0;
  const className = t1 === undefined ? "" : t1;
  const type = t2 === undefined ? "" : t2;
  const onConfirm = t3 === undefined ? Promise.resolve : t3;
  const confirmText = t4 === undefined ? "" : t4;
  const dismissText = t5 === undefined ? "" : t5;
  const leftOfButtons = t6 === undefined ? null : t6;
  const [isLoading, setIsLoading] = React.useState(false);
  let t7;
  let t8;
  if ($[0] !== isOpen) {
    t7 = () => {
      if (!isOpen) {
        setIsLoading(false);
      }
    };
    t8 = [isOpen];
    $[0] = isOpen;
    $[1] = t7;
    $[2] = t8;
  } else {
    t7 = $[1];
    t8 = $[2];
  }
  React.useEffect(t7, t8);
  const t9 = `${className} ${type}`;
  let t10;
  if ($[3] !== children || $[4] !== close || $[5] !== confirmText || $[6] !== dismissText || $[7] !== isLoading || $[8] !== leftOfButtons || $[9] !== onConfirm || $[10] !== title || $[11] !== type) {
    t10 = () => <><ModalHeader_ close={close} title={title} type={type} /><Grid p={3}><Box>{children}</Box><Flex justifyContent="space-between" mt={3}><Box>{leftOfButtons}</Box><Flex>{dismissText && <Button mr={2} variant="flat" color="gray" onClick={close}>{dismissText || "No"}</Button>}<Button color={type || "primary"} className="confirm-button" loading={isLoading} disabled={isLoading} onClick={() => {
              const result = onConfirm();
              if (result && result.then) {
                setIsLoading(true);
                result.then(() => {
                  setIsLoading(false);
                }).catch(() => {
                  setIsLoading(false);
                });
              }
            }}>{confirmText || "Yes"}</Button></Flex></Flex></Grid></>;
    $[3] = children;
    $[4] = close;
    $[5] = confirmText;
    $[6] = dismissText;
    $[7] = isLoading;
    $[8] = leftOfButtons;
    $[9] = onConfirm;
    $[10] = title;
    $[11] = type;
    $[12] = t10;
  } else {
    t10 = $[12];
  }
  let t11;
  if ($[13] !== close || $[14] !== isOpen || $[15] !== t10 || $[16] !== t9) {
    t11 = <Modal isOpen={isOpen} close={close} className={t9}>{t10}</Modal>;
    $[13] = close;
    $[14] = isOpen;
    $[15] = t10;
    $[16] = t9;
    $[17] = t11;
  } else {
    t11 = $[17];
  }
  return t11;
};
export const StandardModal = t0 => {
  const $ = _c(20);
  let children;
  let close;
  let isOpen;
  let rest;
  let t1;
  let t2;
  let t3;
  let title;
  if ($[0] !== t0) {
    ({
      isOpen,
      title,
      close,
      leftCorner: t1,
      rightCorner: t2,
      hasUnsavedChanges: t3,
      children,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = children;
    $[2] = close;
    $[3] = isOpen;
    $[4] = rest;
    $[5] = t1;
    $[6] = t2;
    $[7] = t3;
    $[8] = title;
  } else {
    children = $[1];
    close = $[2];
    isOpen = $[3];
    rest = $[4];
    t1 = $[5];
    t2 = $[6];
    t3 = $[7];
    title = $[8];
  }
  const leftCorner = t1 === undefined ? null : t1;
  const rightCorner = t2 === undefined ? null : t2;
  const hasUnsavedChanges = t3 === undefined ? nope : t3;
  let t4;
  if ($[9] !== children || $[10] !== leftCorner || $[11] !== rightCorner || $[12] !== title) {
    t4 = props => <><ModalHeader title={title} close={props.close} leftCorner={leftCorner} rightCorner={rightCorner} /><Grid p={3}>{children({
          ...props
        })}</Grid></>;
    $[9] = children;
    $[10] = leftCorner;
    $[11] = rightCorner;
    $[12] = title;
    $[13] = t4;
  } else {
    t4 = $[13];
  }
  let t5;
  if ($[14] !== close || $[15] !== hasUnsavedChanges || $[16] !== isOpen || $[17] !== rest || $[18] !== t4) {
    t5 = <Modal isOpen={isOpen} close={close} hasUnsavedChanges={hasUnsavedChanges} {...rest}>{t4}</Modal>;
    $[14] = close;
    $[15] = hasUnsavedChanges;
    $[16] = isOpen;
    $[17] = rest;
    $[18] = t4;
    $[19] = t5;
  } else {
    t5 = $[19];
  }
  return t5;
};
export const TabModal = ({
  isOpen,
  title,
  close,
  hasUnsavedChanges = nope,
  children,
  activeItem,
  items,
  setActiveItem,
  className,
  ...rest
}: {
  title: React.JSX.Element | string;
  hasUnsavedChanges?: boolean | (() => boolean);
  type?: 'alert' | 'success';
  activeItem: any;
  setActiveItem: (v: any) => void;
  items: any[];
  children;
} & Omit<ModalProps, 'children'>) => <Modal isOpen={isOpen} close={close} className={`tab-modal ${className}`} hasUnsavedChanges={hasUnsavedChanges} {...rest} data-sentry-element="Modal" data-sentry-component="TabModal" data-sentry-source-file="index.tsx">
    {props => <>
        <ModalHeader title={title} close={close} hasUnsavedChanges={hasUnsavedChanges} tabs={<ScrollTabs rtl={true} activeItem={activeItem} items={items} setActiveItem={setActiveItem} />} />
        <Grid p={3}>{children({
        ...props,
        close
      })}</Grid>
      </>}
  </Modal>;