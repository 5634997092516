import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import Tags from '../../tags';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from '../shared';
const LocationList = t0 => {
  const $ = _c(26);
  const {
    schema,
    disabled,
    menu,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const locationData = useSelector(_temp);
  const currentBusinessId = useSelector(_temp2);
  let t2;
  let t3;
  if ($[0] !== currentBusinessId || $[1] !== locationData) {
    let t4;
    if ($[3] !== currentBusinessId) {
      t4 = l => l.business_id === currentBusinessId && !l.is_deleted;
      $[3] = currentBusinessId;
      $[4] = t4;
    } else {
      t4 = $[4];
    }
    t3 = Object.values(locationData).filter(t4).map(_temp3);
    $[0] = currentBusinessId;
    $[1] = locationData;
    $[2] = t3;
  } else {
    t3 = $[2];
  }
  t2 = t3;
  const locationOptions = t2;
  const prefix = React.useContext(FieldPrefixContext);
  const t4 = isCustom && "custom_fields";
  let t5;
  if ($[5] !== prefix || $[6] !== schema.name || $[7] !== t4) {
    t5 = [prefix, t4, schema.name].filter(_temp4);
    $[5] = prefix;
    $[6] = schema.name;
    $[7] = t4;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  const name = t5.join(".");
  let t6;
  if ($[9] !== schema.required) {
    t6 = value => {
      if (schema.required && (!value || value.length === 0)) {
        return "Select a location";
      }
    };
    $[9] = schema.required;
    $[10] = t6;
  } else {
    t6 = $[10];
  }
  let t7;
  if ($[11] !== disabled || $[12] !== locationData || $[13] !== locationOptions || $[14] !== menu || $[15] !== schema.helptext || $[16] !== schema.label) {
    t7 = t8 => {
      const {
        input,
        meta
      } = t8;
      return <Box><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><Tags {...input} items={locationOptions} itemToString={v_0 => locationData[v_0]?.name || "Removed"} tabIndex={disabled ? -1 : undefined} invalid={meta.touched && meta.error} />{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[11] = disabled;
    $[12] = locationData;
    $[13] = locationOptions;
    $[14] = menu;
    $[15] = schema.helptext;
    $[16] = schema.label;
    $[17] = t7;
  } else {
    t7 = $[17];
  }
  let t8;
  if ($[18] !== name || $[19] !== t6 || $[20] !== t7) {
    t8 = <Field name={name} validate={t6}>{t7}</Field>;
    $[18] = name;
    $[19] = t6;
    $[20] = t7;
    $[21] = t8;
  } else {
    t8 = $[21];
  }
  let t9;
  if ($[22] !== className || $[23] !== style || $[24] !== t8) {
    t9 = <Box style={style} className={className}>{t8}</Box>;
    $[22] = className;
    $[23] = style;
    $[24] = t8;
    $[25] = t9;
  } else {
    t9 = $[25];
  }
  return t9;
};
export default LocationList;
export const toString = value => {
  return value && value.join(', ') || '';
};
export const View = t0 => {
  const $ = _c(4);
  const {
    value
  } = t0;
  let t1;
  if ($[0] !== value) {
    t1 = value && value.join(", ") || "";
    $[0] = value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== t1) {
    t2 = <Box>{t1}</Box>;
    $[2] = t1;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  return t2;
};
export const Edit = t0 => {
  const $ = _c(12);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp5);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== hideRequired || $[3] !== isEditing || $[4] !== isUniqueFieldName || $[5] !== menu || $[6] !== removable || $[7] !== showClientFieldReference || $[8] !== showMore || $[9] !== style || $[10] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp6}>{t3 => <LocationList schema={value} disabled={true} menu={menu} style={style} className={className} />}</Form> : <Grid sx={{
        display: !isEditing ? "none" : null
      }}><LabelField prefix="" />{!hideRequired && <RequiredField prefix="" />}<HelpTextField prefix="" />{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} size="small" width="100%" variant="outlined">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = hideRequired;
    $[3] = isEditing;
    $[4] = isUniqueFieldName;
    $[5] = menu;
    $[6] = removable;
    $[7] = showClientFieldReference;
    $[8] = showMore;
    $[9] = style;
    $[10] = value;
    $[11] = t2;
  } else {
    t2 = $[11];
  }
  return t2;
};
function _temp(state) {
  return state.locations.data;
}
function _temp2(state_0) {
  return state_0.userPreferences.currentBusinessId;
}
function _temp3(l_0) {
  return {
    value: l_0.id,
    label: l_0.name
  };
}
function _temp4(v) {
  return v;
}
function _temp5(v) {
  return !v;
}
function _temp6(value_0) {
  return null;
}