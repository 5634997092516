import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import { LabeledCheckbox } from '../../checkbox';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import { ClientFieldReference, DataSourceField, useOptionItems, FieldPrefixContext, LabelField, NameField, PrivateField, RequiredField } from '../shared';
const Checkboxes = t0 => {
  const $ = _c(34);
  const {
    schema,
    disabled,
    menu,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const prefix = React.useContext(FieldPrefixContext);
  const [items] = useOptionItems(schema);
  const t2 = isCustom && "custom_fields";
  let t3;
  if ($[0] !== prefix || $[1] !== schema.name || $[2] !== t2) {
    t3 = [prefix, t2, schema.name].filter(_temp);
    $[0] = prefix;
    $[1] = schema.name;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const name = t3.join(".");
  let t4;
  if ($[4] !== schema.required) {
    t4 = value => {
      if (schema.required && (!value || !value.length)) {
        return "Select an option";
      }
    };
    $[4] = schema.required;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] !== menu || $[7] !== schema.label) {
    t5 = t6 => <Box mb={2}><Flex justifyContent="space-between"><Label sx={{
          flexGrow: 1
        }}>{schema.label}</Label>{menu}</Flex></Box>;
    $[6] = menu;
    $[7] = schema.label;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== name || $[10] !== t4 || $[11] !== t5) {
    t6 = <Field name={name} validate={t4}>{t5}</Field>;
    $[9] = name;
    $[10] = t4;
    $[11] = t5;
    $[12] = t6;
  } else {
    t6 = $[12];
  }
  let t7;
  if ($[13] !== disabled || $[14] !== items || $[15] !== name) {
    let t8;
    if ($[17] !== disabled || $[18] !== name) {
      t8 = (t9, idx_0) => {
        const {
          label,
          value: value_0
        } = t9;
        return <Box mb={2} key={idx_0}><Field type="checkbox" name={name} value={value_0} tabIndex={disabled ? -1 : undefined}>{t10 => {
              const {
                input: input_0
              } = t10;
              return <LabeledCheckbox label={label} {...input_0} />;
            }}</Field></Box>;
      };
      $[17] = disabled;
      $[18] = name;
      $[19] = t8;
    } else {
      t8 = $[19];
    }
    t7 = items.filter(_temp2).map(t8);
    $[13] = disabled;
    $[14] = items;
    $[15] = name;
    $[16] = t7;
  } else {
    t7 = $[16];
  }
  let t8;
  if ($[20] !== schema.required) {
    t8 = value_1 => {
      if (schema.required && (!value_1 || !value_1.length)) {
        return "Select an option";
      }
    };
    $[20] = schema.required;
    $[21] = t8;
  } else {
    t8 = $[21];
  }
  let t9;
  if ($[22] !== schema.helptext) {
    t9 = t10 => {
      const {
        meta: meta_0
      } = t10;
      return <Box>{meta_0.error && meta_0.touched && <FormError>{meta_0.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[22] = schema.helptext;
    $[23] = t9;
  } else {
    t9 = $[23];
  }
  let t10;
  if ($[24] !== name || $[25] !== t8 || $[26] !== t9) {
    t10 = <Field name={name} validate={t8}>{t9}</Field>;
    $[24] = name;
    $[25] = t8;
    $[26] = t9;
    $[27] = t10;
  } else {
    t10 = $[27];
  }
  let t11;
  if ($[28] !== className || $[29] !== style || $[30] !== t10 || $[31] !== t6 || $[32] !== t7) {
    t11 = <Box style={style} className={className}>{t6}{t7}{t10}</Box>;
    $[28] = className;
    $[29] = style;
    $[30] = t10;
    $[31] = t6;
    $[32] = t7;
    $[33] = t11;
  } else {
    t11 = $[33];
  }
  return t11;
};
export default Checkboxes;
export const toString = value => {
  return value ? value.join(', ') : '';
};
export const View = t0 => {
  const $ = _c(4);
  const {
    value
  } = t0;
  let t1;
  if ($[0] !== value) {
    t1 = value ? value.join(", ") : "";
    $[0] = value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let t2;
  if ($[2] !== t1) {
    t2 = <Box>{t1}</Box>;
    $[2] = t1;
    $[3] = t2;
  } else {
    t2 = $[3];
  }
  return t2;
};
export const Edit = t0 => {
  const $ = _c(12);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp3);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== hideRequired || $[3] !== isEditing || $[4] !== isUniqueFieldName || $[5] !== menu || $[6] !== removable || $[7] !== showClientFieldReference || $[8] !== showMore || $[9] !== style || $[10] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp4}>{t3 => <Checkboxes schema={value} disabled={true} menu={menu} style={style} className={className} />}</Form> : <Grid sx={{
        display: !isEditing ? "none" : null
      }}><LabelField prefix="" /><DataSourceField prefix="" />{!hideRequired && <RequiredField prefix="" />}{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} variant="outlined" size="small" width="100%">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = hideRequired;
    $[3] = isEditing;
    $[4] = isUniqueFieldName;
    $[5] = menu;
    $[6] = removable;
    $[7] = showClientFieldReference;
    $[8] = showMore;
    $[9] = style;
    $[10] = value;
    $[11] = t2;
  } else {
    t2 = $[11];
  }
  return t2;
};
function _temp(v) {
  return v;
}
function _temp2(_, idx) {
  return idx < 100;
}
function _temp3(v) {
  return !v;
}
function _temp4(value_0) {
  return null;
}