import { c as _c } from "react/compiler-runtime";
/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import { Field } from 'react-final-form';
import { useSelector } from 'react-redux';
import Loader from 'shared/loader';
// import SlateRichText from 'shared/slate-rich-text';
import RenderSlate from 'shared/slate-shared/render';
import FormError from '../../form-error';
import { Box, Flex, Grid } from '../../grid';
import Switch from '../../switch';
import { EMPTY_OBJECT } from 'shared/utils/constants';
const SlateRichText = React.lazy(() => import('shared/slate-rich-text'));
const EMPTY_BODY = [{
  type: 'paragraph',
  children: [{
    text: ''
  }]
}];
const Text = t0 => {
  const $ = _c(6);
  const {
    schema,
    menu,
    variables: t1
  } = t0;
  const variables = t1 === undefined ? EMPTY_OBJECT : t1;
  let t2;
  if ($[0] !== schema.value || $[1] !== variables) {
    t2 = <Box flex="1"><RenderSlate value={schema.value} variables={variables} /></Box>;
    $[0] = schema.value;
    $[1] = variables;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== menu || $[4] !== t2) {
    t3 = <Flex justifyContent="space-between">{t2}{menu}</Flex>;
    $[3] = menu;
    $[4] = t2;
    $[5] = t3;
  } else {
    t3 = $[5];
  }
  return t3;
};
export default Text;
export const toString = value => {
  return '';
};
export const View = t0 => {
  const $ = _c(3);
  const {
    schema,
    variables: t1
  } = t0;
  const variables = t1 === undefined ? EMPTY_OBJECT : t1;
  let t2;
  if ($[0] !== schema.value || $[1] !== variables) {
    t2 = <RenderSlate value={schema.value} variables={variables} />;
    $[0] = schema.value;
    $[1] = variables;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  return t2;
};
export const Edit = t0 => {
  const $ = _c(7);
  const {
    isEditing,
    value,
    menu
  } = t0;
  const clientSchema = useSelector(_temp);
  let t1;
  let t2;
  if ($[0] !== clientSchema) {
    t2 = (clientSchema.filter(_temp2).map(_temp3) || []).concat({
      label: "Business name",
      value: "business_name"
    });
    $[0] = clientSchema;
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  t1 = t2;
  const clientVariables = t1;
  let t3;
  if ($[2] !== clientVariables || $[3] !== isEditing || $[4] !== menu || $[5] !== value) {
    t3 = <>{!isEditing ? <Text schema={value} menu={menu} /> : <Grid sx={{
        display: !isEditing ? "none;" : undefined
      }}><Field name={"" ? `${""}.value` : "value"} validate={_temp4}>{t4 => {
            const {
              input,
              meta
            } = t4;
            return <Box><React.Suspense fallback={<Loader />}><Box className="text-editor"><SlateRichText value={input.value || EMPTY_BODY} onChange={input.onChange} variables={clientVariables} /></Box></React.Suspense>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</Box>;
          }}</Field><Field name="show_on_print">{_temp6}</Field></Grid>}</>;
    $[2] = clientVariables;
    $[3] = isEditing;
    $[4] = menu;
    $[5] = value;
    $[6] = t3;
  } else {
    t3 = $[6];
  }
  return t3;
};
function _temp(state) {
  return state.businesses.data[state.userPreferences.currentBusinessId]?.client_schema || [];
}
function _temp2(v) {
  return !["location_ids", "dont_send_notifications"].includes(v.name);
}
function _temp3(t0) {
  const {
    name,
    label
  } = t0;
  return {
    value: `client_${name}`,
    label
  };
}
function _temp4(value_0) {
  if (!value_0) {
    return "Enter some text";
  }
}
function _temp5(t0) {
  const {
    input: input_0
  } = t0;
  return <Flex alignItems="center" data-sentry-element="Flex" data-sentry-component="_temp5" data-sentry-source-file="index.tsx"><Switch size="small" {...input_0} data-sentry-element="Switch" data-sentry-source-file="index.tsx" data-sentry-element="Switch" /><Box ml={2} data-sentry-element="Box" data-sentry-source-file="index.tsx" data-sentry-element="Box">Break page before</Box></Flex>;
}
function _temp6(t0) {
  const {
    input: pInput
  } = t0;
  return <><Flex alignItems="center" data-sentry-element="Flex" data-sentry-source-file="index.tsx" data-sentry-element="Flex"><Switch size="small" {...pInput} data-sentry-element="Switch" data-sentry-source-file="index.tsx" data-sentry-element="Switch" /><Box ml={2} data-sentry-element="Box" data-sentry-source-file="index.tsx" data-sentry-element="Box">Show when printing</Box></Flex>{pInput.value && <Field name="has_page_break">{_temp5}</Field>}</>;
}