import { c as _c } from "react/compiler-runtime";
import setFieldTouched from 'final-form-set-field-touched';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Tags from 'shared/tags';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import { useIsDatalistValid } from '../hooks';
import { ClientFieldReference, DataSourceField, FieldPrefixContext, HelpTextField, LabelField, NameField, PlaceholderField, PrivateField, RequiredField, useOptionItems } from '../shared';
import arrayMutators from 'final-form-arrays';
import { touchErrors } from '../../utils/form';
import { useUpdateEffect } from '../../hooks/use-update-effect';
const TagsField = t0 => {
  const $ = _c(20);
  const {
    schema,
    disabled,
    menu,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const prefix = React.useContext(FieldPrefixContext);
  const [items, valueToDescription] = useOptionItems(schema);
  const t2 = isCustom && "custom_fields";
  let t3;
  if ($[0] !== prefix || $[1] !== schema.name || $[2] !== t2) {
    t3 = [prefix, t2, schema.name].filter(_temp);
    $[0] = prefix;
    $[1] = schema.name;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const name = t3.join(".");
  let t4;
  if ($[4] !== schema.required) {
    t4 = value => {
      if (schema.required && !value) {
        return "Select an option";
      }
    };
    $[4] = schema.required;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] !== className || $[7] !== disabled || $[8] !== items || $[9] !== menu || $[10] !== schema.helptext || $[11] !== schema.label || $[12] !== schema.placeholder || $[13] !== style || $[14] !== valueToDescription) {
    t5 = t6 => {
      const {
        input,
        meta
      } = t6;
      return <Box style={style} className={className}><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><Tags {...input} tabIndex={disabled ? -1 : undefined} placeholder={schema.placeholder} sx={{
          display: "block",
          width: "100%"
        }} invalid={meta.touched && meta.error} itemToString={_temp2} itemToTooltip={v_1 => valueToDescription[v_1]} items={items} />{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[6] = className;
    $[7] = disabled;
    $[8] = items;
    $[9] = menu;
    $[10] = schema.helptext;
    $[11] = schema.label;
    $[12] = schema.placeholder;
    $[13] = style;
    $[14] = valueToDescription;
    $[15] = t5;
  } else {
    t5 = $[15];
  }
  let t6;
  if ($[16] !== name || $[17] !== t4 || $[18] !== t5) {
    t6 = <Field name={name} validate={t4}>{t5}</Field>;
    $[16] = name;
    $[17] = t4;
    $[18] = t5;
    $[19] = t6;
  } else {
    t6 = $[19];
  }
  return t6;
};
export default TagsField;
export const toString = value => {
  let v = value || [];
  if (!Array.isArray(value)) {
    v = [value];
  }
  return v.join(', ') || '';
};
export const View = t0 => {
  const $ = _c(8);
  const {
    value
  } = t0;
  let t1;
  if ($[0] !== value) {
    t1 = value || [];
    $[0] = value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  let v = t1;
  if (!Array.isArray(value)) {
    let t2;
    if ($[2] !== value) {
      t2 = [value];
      $[2] = value;
      $[3] = t2;
    } else {
      t2 = $[3];
    }
    v = t2;
  }
  let t2;
  if ($[4] !== v) {
    t2 = v.join(", ") || "";
    $[4] = v;
    $[5] = t2;
  } else {
    t2 = $[5];
  }
  let t3;
  if ($[6] !== t2) {
    t3 = <Box>{t2}</Box>;
    $[6] = t2;
    $[7] = t3;
  } else {
    t3 = $[7];
  }
  return t3;
};
export const Edit = t0 => {
  const $ = _c(16);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp3);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  const wasTouchedRef = React.useRef(false);
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = () => {
      wasTouchedRef.current = false;
    };
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  let t3;
  if ($[2] !== value) {
    t3 = [value];
    $[2] = value;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  useUpdateEffect(t2, t3);
  const isDataListMissing = useIsDatalistValid(value.data_list_id || "");
  let t4;
  if ($[4] !== className || $[5] !== hideRequired || $[6] !== isDataListMissing || $[7] !== isEditing || $[8] !== isUniqueFieldName || $[9] !== menu || $[10] !== removable || $[11] !== showClientFieldReference || $[12] !== showMore || $[13] !== style || $[14] !== value) {
    t4 = <>{!isEditing ? <Form onSubmit={_temp4} mutators={{
        ...arrayMutators,
        setFieldTouched
      }} validate={() => {
        const errors = {};
        if (value.data_source === "data_list_id" && isDataListMissing) {
          errors.onlyField = `Missing data list`;
        }
        return errors;
      }}>{t5 => {
          const {
            form
          } = t5;
          if (wasTouchedRef.current === false) {
            touchErrors(form);
            wasTouchedRef.current = true;
          }
          return <TagsField schema={{
            ...value,
            name: "onlyField"
          }} disabled={true} menu={menu} style={style} className={className} />;
        }}</Form> : <Grid display={!isEditing ? "none" : null}><LabelField prefix="" /><DataSourceField prefix="" />{!hideRequired && <RequiredField prefix="" />}<PlaceholderField prefix="" /><HelpTextField prefix="" />{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} width="100%" variant="outlined" size="small">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[4] = className;
    $[5] = hideRequired;
    $[6] = isDataListMissing;
    $[7] = isEditing;
    $[8] = isUniqueFieldName;
    $[9] = menu;
    $[10] = removable;
    $[11] = showClientFieldReference;
    $[12] = showMore;
    $[13] = style;
    $[14] = value;
    $[15] = t4;
  } else {
    t4 = $[15];
  }
  return t4;
};
function _temp(v) {
  return v;
}
function _temp2(v_0) {
  return v_0;
}
function _temp3(v) {
  return !v;
}
function _temp4(value_0) {
  return null;
}