import React from 'react';
const stub = {
  isValidNumber: (number: string) => true,
  AsYouType: function AsYouType(country: string) {
    this.input = (number: string) => number;
  },
  format: (number: Record<string, any>, format: string) => '',
  getPhoneCode: (number: string) => '',
  parse: (number: string) => ({
    country: null,
    phone: null
  }),
  isSupportedCountry: (number: string) => true
};
export const useLibPhoneNumber = () => {
  const [lib, setLib] = React.useState(null);
  React.useEffect(() => {
    import('libphonenumber-js').then(lib_0 => {
      setLib(lib_0);
    });
  }, []);
  return lib || stub;
};