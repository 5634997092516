import { c as _c } from "react/compiler-runtime";
import React from 'react';
// import { RemoveScroll } from 'shared/react-remove-scroll';
import { FloatingFocusManager, FloatingOverlay, useClick, useFloating, useId, useInteractions, useRole } from '@floating-ui/react';
import { RemoveScrollBar, zeroRightClassName } from 'react-remove-scroll-bar';
import { Transition } from 'react-transition-group';
import { Box, BoxProps } from 'shared/grid';
import { usePreviousIfFalsy } from 'shared/hooks';
import Portal from '../portal';
import Toaster from '../toaster';
import { ModalToastContext } from './context';
const modalZIndex = 6002;
const duration = 100;
const defaultStyle = {
  opacity: 0,
  // transform: 'translate3d(0, 0, 0)',
  // transition: `opacity ${duration}ms ease-out, transform ${duration}ms ease-out`,
  transition: `opacity ${duration}ms ease-out`
};
const transitionStyles = {
  entering: {
    opacity: 1
  },
  entered: {
    opacity: 1
  },
  exiting: {
    opacity: 0
  },
  exited: {
    opacity: 0
  }
};
export interface ModalWindowProps extends BoxProps {
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge' | 'full';
  className?: string;
  close: any;
  style?: React.CSSProperties;
  children: any;
}
function ModalWindow(t0) {
  const $ = _c(29);
  let children;
  let close;
  let ref;
  let rest;
  let size;
  let style;
  let sx;
  let t1;
  if ($[0] !== t0) {
    ({
      ref,
      size,
      className: t1,
      close,
      style,
      children,
      sx,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = children;
    $[2] = close;
    $[3] = ref;
    $[4] = rest;
    $[5] = size;
    $[6] = style;
    $[7] = sx;
    $[8] = t1;
  } else {
    children = $[1];
    close = $[2];
    ref = $[3];
    rest = $[4];
    size = $[5];
    style = $[6];
    sx = $[7];
    t1 = $[8];
  }
  const className = t1 === undefined ? "" : t1;
  let t2;
  if ($[9] !== close) {
    t2 = e => {
      if (e.keyCode === 27) {
        close();
        e.stopPropagation();
      }
    };
    $[9] = close;
    $[10] = t2;
  } else {
    t2 = $[10];
  }
  const handleKeyDown = t2;
  let modalWidth;
  bb0: switch (size) {
    case "tiny":
      {
        modalWidth = 324;
        break bb0;
      }
    case "small":
      {
        modalWidth = 540;
        break bb0;
      }
    case "medium":
      {
        modalWidth = 756;
        break bb0;
      }
    case "large":
      {
        modalWidth = 972;
        break bb0;
      }
    case "xlarge":
      {
        modalWidth = 1260;
        break bb0;
      }
    case "full":
      {
        modalWidth = "100%";
        break bb0;
      }
    default:
      {
        modalWidth = 600;
      }
  }
  const t3 = `modal ${className || ""}`;
  const t4 = typeof modalWidth == "number" ? `${modalWidth}px` : modalWidth;
  const t5 = typeof modalWidth == "number" ? `${modalWidth}px` : modalWidth;
  let t6;
  if ($[11] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = [0, 3];
    $[11] = t6;
  } else {
    t6 = $[11];
  }
  let t7;
  if ($[12] !== size) {
    t7 = size === "full" ? {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      width: "100%",
      maxWidth: "none",
      height: "100%",
      minHeight: "100%",
      marginLeft: 0,
      border: 0,
      borderRadius: 0,
      marginY: 0
    } : {};
    $[12] = size;
    $[13] = t7;
  } else {
    t7 = $[13];
  }
  let t8;
  if ($[14] !== sx) {
    t8 = sx || {};
    $[14] = sx;
    $[15] = t8;
  } else {
    t8 = $[15];
  }
  let t9;
  if ($[16] !== t4 || $[17] !== t5 || $[18] !== t7 || $[19] !== t8) {
    t9 = {
      zIndex: modalZIndex + 1,
      backfaceVisibility: "hidden",
      padding: 0,
      border: 0,
      borderRadius: 2,
      outline: "none",
      boxShadow: "extraLarge",
      overflowY: "visible",
      height: "auto",
      position: "relative",
      marginRight: "auto",
      marginLeft: "auto",
      minHeight: 0,
      maxWidth: t4,
      flexBasis: t5,
      marginY: t6,
      ...t7,
      ...t8
    };
    $[16] = t4;
    $[17] = t5;
    $[18] = t7;
    $[19] = t8;
    $[20] = t9;
  } else {
    t9 = $[20];
  }
  let t10;
  if ($[21] !== children || $[22] !== handleKeyDown || $[23] !== ref || $[24] !== rest || $[25] !== style || $[26] !== t3 || $[27] !== t9) {
    t10 = <Box {...rest} role="dialog" aria-modal="true" ref={ref} onKeyDown={handleKeyDown} tabIndex={0} className={t3} style={style} backgroundColor="background" sx={t9}>{children}</Box>;
    $[21] = children;
    $[22] = handleKeyDown;
    $[23] = ref;
    $[24] = rest;
    $[25] = style;
    $[26] = t3;
    $[27] = t9;
    $[28] = t10;
  } else {
    t10 = $[28];
  }
  return t10;
}
export interface ModalProps<T = any> extends Omit<BoxProps, 'children'> {
  isOpen: boolean;
  close?: () => any;
  children: (props?: T & {
    close: () => void;
    backdropRef: any;
  }) => React.JSX.Element;
  autoFocus?: boolean;
  className?: string;
  noShrink?: boolean;
  size?: ModalWindowProps['size'];
  props?: any;
  zIndexDelta?: number;
  backdropRef?: React.RefObject<HTMLDivElement>;
}
const Modal_ = t0 => {
  const $ = _c(61);
  let backdropRef;
  let children;
  let close;
  let isOpen;
  let props;
  let rest;
  let t1;
  let t2;
  let t3;
  let t4;
  let t5;
  if ($[0] !== t0) {
    ({
      isOpen,
      close,
      children,
      autoFocus: t1,
      className: t2,
      noShrink: t3,
      size: t4,
      props,
      zIndexDelta: t5,
      backdropRef,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = backdropRef;
    $[2] = children;
    $[3] = close;
    $[4] = isOpen;
    $[5] = props;
    $[6] = rest;
    $[7] = t1;
    $[8] = t2;
    $[9] = t3;
    $[10] = t4;
    $[11] = t5;
  } else {
    backdropRef = $[1];
    children = $[2];
    close = $[3];
    isOpen = $[4];
    props = $[5];
    rest = $[6];
    t1 = $[7];
    t2 = $[8];
    t3 = $[9];
    t4 = $[10];
    t5 = $[11];
  }
  const autoFocus = t1 === undefined ? true : t1;
  const className = t2 === undefined ? "" : t2;
  t3 === undefined ? false : t3;
  const size = t4 === undefined ? null : t4;
  const modalZIndexDelta = t5 === undefined ? 0 : t5;
  const defaultBackdropRef = React.useRef(null);
  const modalBackdropRef = backdropRef || defaultBackdropRef;
  const clickStartedOnOverlayRef = React.useRef(false);
  const ctx = React.useContext(ModalToastContext);
  const incrementModalCountRef = React.useRef(ctx.incrementModalCount);
  let t6;
  let t7;
  if ($[12] !== ctx.incrementModalCount) {
    t6 = () => {
      incrementModalCountRef.current = ctx.incrementModalCount;
    };
    t7 = [ctx.incrementModalCount];
    $[12] = ctx.incrementModalCount;
    $[13] = t6;
    $[14] = t7;
  } else {
    t6 = $[13];
    t7 = $[14];
  }
  React.useEffect(t6, t7);
  const decrementModalCountRef = React.useRef(ctx.decrementModalCount);
  let t8;
  let t9;
  if ($[15] !== ctx.decrementModalCount) {
    t8 = () => {
      decrementModalCountRef.current = ctx.decrementModalCount;
    };
    t9 = [ctx.decrementModalCount];
    $[15] = ctx.decrementModalCount;
    $[16] = t8;
    $[17] = t9;
  } else {
    t8 = $[16];
    t9 = $[17];
  }
  React.useEffect(t8, t9);
  const [viewPortOverlaySize, setViewPortOverlaySize] = React.useState(0);
  const pendingUpdateRef = React.useRef(false);
  let t10;
  let t11;
  if ($[18] === Symbol.for("react.memo_cache_sentinel")) {
    t10 = () => {
      const viewportHandler = function viewportHandler(event) {
        if (pendingUpdateRef.current) {
          return;
        }
        pendingUpdateRef.current = true;
        requestAnimationFrame(() => {
          pendingUpdateRef.current = false;
          const viewport = event.target;
          setViewPortOverlaySize(window.innerHeight - viewport.height);
        });
      };
      window.visualViewport?.addEventListener?.("resize", viewportHandler);
      return () => {
        window.visualViewport?.removeEventListener?.("resize", viewportHandler);
      };
    };
    t11 = [];
    $[18] = t10;
    $[19] = t11;
  } else {
    t10 = $[18];
    t11 = $[19];
  }
  React.useEffect(t10, t11);
  let t12;
  if ($[20] !== close) {
    t12 = () => {
      if (close) {
        close();
        clickStartedOnOverlayRef.current = false;
      }
    };
    $[20] = close;
    $[21] = t12;
  } else {
    t12 = $[21];
  }
  const closeFn = t12;
  let t13;
  if ($[22] !== closeFn || $[23] !== isOpen) {
    t13 = {
      open: isOpen,
      onOpenChange: closeFn
    };
    $[22] = closeFn;
    $[23] = isOpen;
    $[24] = t13;
  } else {
    t13 = $[24];
  }
  const {
    context
  } = useFloating(t13);
  const modalRef = React.useRef(null);
  const id = useId();
  const labelId = `${id}-label`;
  const descriptionId = `${id}-description`;
  const t14 = useClick(context);
  const t15 = useRole(context);
  let t16;
  if ($[25] !== t14 || $[26] !== t15) {
    t16 = [t14, t15];
    $[25] = t14;
    $[26] = t15;
    $[27] = t16;
  } else {
    t16 = $[27];
  }
  const {
    getFloatingProps
  } = useInteractions(t16);
  let t17;
  if ($[28] !== autoFocus) {
    t17 = () => {
      incrementModalCountRef.current();
      const focus = () => {
        if (autoFocus && modalRef.current) {
          const focusableEls = modalRef.current.querySelectorAll("input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex=\"-1\"])");
          let focusableEl;
          for (const el of focusableEls) {
            if (window.getComputedStyle(el).display !== "none") {
              focusableEl = el;
              break;
            }
          }
          if (!focusableEl) {
            focusableEl = modalRef.current.querySelector("a[href], area[href], button:not(.close):not(.popover)") as HTMLElement;
          }
          focusableEl?.focus?.();
        }
      };
      setTimeout(focus);
    };
    $[28] = autoFocus;
    $[29] = t17;
  } else {
    t17 = $[29];
  }
  const opened = t17;
  let t18;
  if ($[30] === Symbol.for("react.memo_cache_sentinel")) {
    t18 = () => {
      decrementModalCountRef.current();
    };
    $[30] = t18;
  } else {
    t18 = $[30];
  }
  const closed = t18;
  const isOpenBool = !!isOpen;
  const lastOpenValueRef = React.useRef(isOpenBool);
  let t19;
  let t20;
  if ($[31] !== isOpenBool || $[32] !== opened) {
    t19 = () => {
      if (lastOpenValueRef.current == isOpenBool) {
        return;
      }
      if (isOpenBool) {
        opened();
      } else {
        closed();
      }
      lastOpenValueRef.current = isOpenBool;
    };
    t20 = [isOpenBool, closed, opened];
    $[31] = isOpenBool;
    $[32] = opened;
    $[33] = t19;
    $[34] = t20;
  } else {
    t19 = $[33];
    t20 = $[34];
  }
  React.useEffect(t19, t20);
  const oldProps = usePreviousIfFalsy(props, !isOpen, duration);
  const t21 = !!isOpen;
  let t22;
  if ($[35] !== children || $[36] !== className || $[37] !== close || $[38] !== closeFn || $[39] !== context || $[40] !== descriptionId || $[41] !== getFloatingProps || $[42] !== isOpen || $[43] !== labelId || $[44] !== modalBackdropRef || $[45] !== modalZIndexDelta || $[46] !== oldProps || $[47] !== props || $[48] !== rest || $[49] !== size || $[50] !== viewPortOverlaySize) {
    t22 = state => state !== "exited" && state !== "unmounted" && <><RemoveScrollBar /><Box as={FloatingOverlay} style={{
        ...defaultStyle,
        ...transitionStyles[state]
      }} ref={modalBackdropRef} className="modal-overlay" role="dialog" onClick={event_0 => {
        if (clickStartedOnOverlayRef.current && modalBackdropRef.current === event_0.target) {
          closeFn();
        }
        clickStartedOnOverlayRef.current = false;
      }} onMouseDown={event_1 => {
        if (modalBackdropRef.current === event_1.target) {
          clickStartedOnOverlayRef.current = true;
        }
      }} sx={{
        contain: "content",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: modalZIndex + modalZIndexDelta,
        backgroundColor: "rgba(0, 0, 0, 0.45)",
        WebkitOverflowScrolling: "touch",
        overflowY: "auto",
        overflowX: "hidden",
        overscrollBehavior: "contain",
        "--top-bar-height": `0px`,
        pb: viewPortOverlaySize
      }}><Box sx={{
          pointerEvents: "none",
          transition: "transform 0.2s ease-out",
          minHeight: "100%",
          display: "flex",
          alignItems: "center"
        }} className={zeroRightClassName}><FloatingFocusManager context={context} initialFocus={-1}><ModalWindow {...rest} ref={modalRef} aria-labelledby={labelId} aria-describedby={descriptionId} {...getFloatingProps()} className={`${className} ${zeroRightClassName}`} close={closeFn} size={size} sx={{
              pointerEvents: "all"
            }}>{children({
                ...((!isOpen ? oldProps : props) || {}),
                close,
                backdropRef: modalBackdropRef
              })}</ModalWindow></FloatingFocusManager></Box></Box></>;
    $[35] = children;
    $[36] = className;
    $[37] = close;
    $[38] = closeFn;
    $[39] = context;
    $[40] = descriptionId;
    $[41] = getFloatingProps;
    $[42] = isOpen;
    $[43] = labelId;
    $[44] = modalBackdropRef;
    $[45] = modalZIndexDelta;
    $[46] = oldProps;
    $[47] = props;
    $[48] = rest;
    $[49] = size;
    $[50] = viewPortOverlaySize;
    $[51] = t22;
  } else {
    t22 = $[51];
  }
  let t23;
  if ($[52] !== modalBackdropRef || $[53] !== t21 || $[54] !== t22) {
    t23 = <Transition nodeRef={modalBackdropRef} in={t21} timeout={duration}>{t22}</Transition>;
    $[52] = modalBackdropRef;
    $[53] = t21;
    $[54] = t22;
    $[55] = t23;
  } else {
    t23 = $[55];
  }
  let t24;
  if ($[56] !== isOpen) {
    t24 = isOpen ? <Toaster inModal={true} /> : null;
    $[56] = isOpen;
    $[57] = t24;
  } else {
    t24 = $[57];
  }
  let t25;
  if ($[58] !== t23 || $[59] !== t24) {
    t25 = <Portal>{t23}{t24}</Portal>;
    $[58] = t23;
    $[59] = t24;
    $[60] = t25;
  } else {
    t25 = $[60];
  }
  return t25;
};
const Modal = React.memo(Modal_);
export default Modal;