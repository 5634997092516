import { c as _c } from "react/compiler-runtime";
import { ThemeProvider } from '@emotion/react';
import get from 'lodash.get';
import merge from 'lodash.merge';
import React from 'react';
const getTheme = (baseTheme, mode) => {
  // const modeTheme = get(baseTheme, `modes.${mode}`, {});
  // const newTheme = merge({}, baseTheme, modeTheme);

  const newTheme = merge({}, baseTheme, get(baseTheme.modes, mode, baseTheme));
  return newTheme;
};
const adjustedTheme = mode => baseTheme => getTheme(baseTheme, mode);
const ColorMode = t0 => {
  const $ = _c(5);
  const {
    children,
    mode: t1
  } = t0;
  const mode = t1 === undefined ? null : t1;
  let t2;
  let t3;
  if ($[0] !== mode) {
    t3 = adjustedTheme(mode);
    $[0] = mode;
    $[1] = t3;
  } else {
    t3 = $[1];
  }
  t2 = t3;
  const theme = t2;
  if (!mode) {
    return children;
  }
  let t4;
  if ($[2] !== children || $[3] !== theme) {
    t4 = <ThemeProvider theme={theme}>{children}</ThemeProvider>;
    $[2] = children;
    $[3] = theme;
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  return t4;
};
export default ColorMode;