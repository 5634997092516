import { c as _c } from "react/compiler-runtime";
import React, { useState, useCallback } from 'react';
import useIsomorphicLayoutEffect from './use-isomorphic-layout-effect';
export const getSize = el => {
  if (!el) {
    return {
      width: 0,
      height: 0
    };
  }
  return {
    width: el.offsetWidth,
    height: el.offsetHeight
  };
};
function useComponentSize(ref, minDelta = 1) {
  const [componentSize, setComponentSize] = useState(getSize(ref ? ref.current : {}));
  const handleResize = useCallback(() => {
    if (ref.current) {
      React.startTransition(() => {
        const size = getSize(ref.current);
        // setComponentSize(size);
        // Prevent resize loop?
        setComponentSize(oldSize => {
          const xD = Math.abs(size.width - oldSize.width);
          const yD = Math.abs(size.height - oldSize.height);
          if (xD < minDelta) {
            size.width = oldSize.width;
          }
          if (yD < minDelta) {
            size.height = oldSize.height;
          }
          return size;
        });
      });
    }
  }, [minDelta, ref]);
  useIsomorphicLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    handleResize();
    if (typeof ResizeObserver === 'function') {
      const observedEl = ref.current;
      const resizeObserver = new ResizeObserver(handleResize);
      resizeObserver.observe(observedEl);
      return () => {
        resizeObserver.disconnect();
      };
    } else {
      window.addEventListener('resize', handleResize);
      return function () {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [ref.current]);
  return componentSize;
}
export default useComponentSize;
export const useMaxHeight = ref => {
  const $ = _c(6);
  const {
    height
  } = useComponentSize(ref);
  const [maxHeight, setMaxHeight] = React.useState(height);
  const isResizingRef = React.useRef(false);
  let t0;
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = () => {
      const onResize = () => {
        isResizingRef.current = true;
        setMaxHeight(0);
        setTimeout(() => {
          isResizingRef.current = false;
        }, 1000);
      };
      window.addEventListener("resize", onResize);
      return () => window.removeEventListener("resize", onResize);
    };
    t1 = [];
    $[0] = t0;
    $[1] = t1;
  } else {
    t0 = $[0];
    t1 = $[1];
  }
  React.useEffect(t0, t1);
  let t2;
  let t3;
  if ($[2] !== height || $[3] !== maxHeight) {
    t2 = () => {
      if (!isResizingRef.current && height > maxHeight) {
        setMaxHeight(height);
      }
    };
    t3 = [height, maxHeight];
    $[2] = height;
    $[3] = maxHeight;
    $[4] = t2;
    $[5] = t3;
  } else {
    t2 = $[4];
    t3 = $[5];
  }
  React.useEffect(t2, t3);
  return maxHeight;
};