import { autoUpdate, flip, FloatingFocusManager, Placement, shift, size, Strategy, useDismiss, useFloating, useId, useInteractions, useListNavigation, useRole } from '@floating-ui/react';
import Tippy from 'shared/tooltip';
import React from 'react';
import { Box, BoxProps, Flex, SxStyleProp } from 'shared/grid';
import { StyledInput } from 'shared/input';
import { Text } from 'shared/typography';
import { useDebounce } from '../hooks';
import useIsomorphicLayoutEffect from '../hooks/use-isomorphic-layout-effect';
import { useUpdateEffect } from '../hooks/use-update-effect';
import Portal from '../portal';
import { highlight, score } from '../search';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AutosizeInput from 'shared/input-autosize';
import { dropdownSx } from 'shared/dropdown-style';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'shared/utils/constants';
interface ItemType {
  value: any;
  label: string; // | React.JSX.Element;
  iconElement?: React.JSX.Element;
  searchText?: string;
  extra?: any;
  groupId?: string;
  score?: number;
  disabled?: boolean;
  sx?: SxStyleProp;
}
interface Group {
  label: string;
  iconElement?: React.JSX.Element;
}
const identityFn = v => v;
const toNull = v => null;
interface TagProps {
  onChange: (value: any) => void | string;
  value: any[];
  onInputValueChange?: (value: any) => any;
  itemToString?: (value: any) => string;
  itemToTooltip?: (value: any) => any;
  items?: readonly ItemType[];
  staticItems?: readonly ItemType[];
  staticItemsEnd?: readonly ItemType[];
  groups?: Record<string, Group>;
  onFocus?: (value: any) => void;
  onBlur?: (value: any) => void;
  className?: string;
  invalid?: boolean;
  deleteRemoves?: boolean;
  usePortal?: boolean;
  placement?: Placement;
  strategy?: Strategy;
  placeholder?: string;
  truncate?: number;
  borderWidth?: number;
  createValue?: boolean | ((seatch: string) => any);
  searchExtra?: boolean;
}
export function Tags({
  ref,
  onChange,
  value: value_,
  onInputValueChange = identityFn,
  itemToString: itemToString_,
  itemToTooltip = toNull,
  items = EMPTY_ARRAY,
  staticItems = EMPTY_ARRAY,
  staticItemsEnd = EMPTY_ARRAY,
  groups = EMPTY_OBJECT,
  onFocus: onFocus_,
  onBlur: onBlur_,
  className = '',
  invalid = false,
  deleteRemoves = false,
  usePortal = false,
  placement: placement_ = 'bottom-start',
  strategy: strategy_ = 'absolute',
  placeholder = 'Search...',
  truncate = 100,
  borderWidth = 1,
  createValue = false,
  searchExtra = false,
  ...rest
}: TagProps & BoxProps) {
  // Hack to display string values
  const value = React.useMemo(() => Array.isArray(value_) ? value_ : value_ ? [value_] : [], [value_]);
  staticItems = staticItems || EMPTY_ARRAY;
  staticItemsEnd = staticItemsEnd || EMPTY_ARRAY;
  const [open, setOpen] = React.useState(false);
  const [searchString, setSearchString] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState<number | null>(null);

  // const inputRef = React.useRef<HTMLInputElement>(null);
  const listRef = React.useRef<Array<HTMLElement | null>>([]);
  const noResultsId = useId();
  const buttonId = useId();
  const listboxId = useId();
  const debouncedSearchString = useDebounce(searchString, items.length > 1000 ? 500 : 0);
  const filteredItems_0 = React.useMemo(() => {
    let filteredItems = items.filter(item => !value.includes(item.value));
    if (debouncedSearchString) {
      filteredItems = items.map((item_0, idx) => {
        const itemSearchText = (item_0.searchText || item_0.label) + (searchExtra ? item_0.extra ? ` ${item_0.extra}` : '' : '');
        return {
          ...item_0,
          score: score(itemSearchText, debouncedSearchString)
        };
      }).filter(item_1 => debouncedSearchString ? item_1.score > 0 : true);
      filteredItems = filteredItems.sort((a, b) => a.score > b.score ? -1 : 1);
    }
    filteredItems = filteredItems.filter((_, idx_0) => !truncate || idx_0 < truncate);
    if (createValue && searchString) {
      let option = null;
      if (typeof createValue === 'function') {
        const value_0 = createValue(debouncedSearchString);
        if (value_0) {
          option = {
            label: itemToString_(value_0),
            value: value_0
          };
        }
      } else {
        option = {
          label: debouncedSearchString,
          value: debouncedSearchString
        };
      }
      if (option) {
        filteredItems = filteredItems.concat({
          ...option,
          score: 99999999
        });
      }
    }
    return filteredItems;
  }, [createValue, debouncedSearchString, itemToString_, items, searchExtra, searchString, truncate, value]);
  const [idxToGroupHeading_0, groupedItems_0] = React.useMemo(() => {
    const groupMap = filteredItems_0.reduce((groups_0, item_2) => {
      const key = item_2.groupId || 'default';
      groups_0[key] = groups_0[key] || [];
      groups_0[key].push(item_2);
      return groups_0;
    }, {});
    const groupList = Object.values<any>(groupMap).sort((a_0, b_0) => a_0[0].score < b_0[0].scored ? -1 : 1);
    if (groupList.length < 2) {
      return [{}, staticItems.concat(filteredItems_0).concat(staticItemsEnd)];
    }
    let itemIdx = 0;
    const idxToGroupHeading = {};
    const groupedItems = [];
    for (const group of groupList) {
      for (let i = 0; i < group.length; i++) {
        const item_3 = group[i];
        if (i === 0) {
          idxToGroupHeading[itemIdx + staticItems.length] = groups[item_3.groupId];
        }
        groupedItems.push(item_3);
        itemIdx++;
      }
    }
    return [idxToGroupHeading, staticItems.concat(groupedItems).concat(staticItemsEnd)];
  }, [filteredItems_0, groups, staticItems, staticItemsEnd]);
  const MaybePortal = usePortal ? Portal : React.Fragment;
  const {
    floatingStyles,
    refs,
    context
  } = useFloating({
    whileElementsMounted: autoUpdate,
    open,
    onOpenChange: setOpen,
    middleware: [flip({
      padding: 10
    }), size({
      apply({
        rects,
        availableHeight,
        elements
      }) {
        Object.assign(elements.floating.style, {
          width: `${rects.reference.width}px`,
          maxHeight: `${availableHeight}px`
        });
      },
      padding: 10
    }), shift()],
    placement: placement_,
    strategy: strategy_
  });
  useIsomorphicLayoutEffect(() => {
    // IMPORTANT: When the floating element first opens, this effect runs when
    // the styles have **not yet** been applied to the element. A rAF ensures
    // we wait until the position is ready, and also runs before paint.
    // https://floating-ui.com/docs/react-dom#effects
    requestAnimationFrame(() => {
      if (activeIndex != null) {
        listRef.current[activeIndex]?.scrollIntoView({
          block: 'nearest'
        });
      }
    });
  }, [activeIndex]);
  const role = useRole(context, {
    role: 'listbox'
  });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    onNavigate: open ? setActiveIndex : undefined,
    activeIndex,
    focusItemOnOpen: false,
    virtual: true,
    loop: true,
    allowEscape: true
  });

  // Handles the list navigation where the reference is the inner input, not
  // the button that opens the floating element.
  const {
    getReferenceProps,
    getFloatingProps,
    getItemProps
  } = useInteractions([role, dismiss, listNav]);
  useUpdateEffect(() => {
    if (open) {
      // nope
    } else {
      setSearchString('');
      setActiveIndex(null);
      return;
    }
  }, [open]);

  // Prevent input losing focus on Firefox VoiceOver
  // const { 'aria-activedescendant': ignoreAria, ...floatingProps } =
  //   getFloatingProps({
  //     onKeyDown(event) {
  //       if (event.key === 'Tab') {
  //         setOpen(false);
  //       }
  //     },
  //   });

  const addItem = React.useCallback(val => {
    if (val) {
      return onChange([...(value || EMPTY_ARRAY), val]);
    }
  }, [onChange, value]);
  const removeItem = React.useCallback(idx_1 => {
    if (value) {
      return onChange(value.filter((__0, idx_) => idx_ !== idx_1));
    }
  }, [onChange, value]);
  const popItem = React.useCallback(() => {
    removeItem(value.length - 1);
  }, [removeItem, value.length]);
  const handleItemClick = React.useCallback((value_1 = null) => {
    if (value_1 || activeIndex !== null) {
      const close = addItem(value_1 || filteredItems_0[activeIndex].value) !== 'noclose';
      if (close) {
        setOpen(false);
      }
    }
  }, [addItem, activeIndex, filteredItems_0, setOpen]);
  const itemToString = React.useCallback(v => itemToString_ ? itemToString_(v) : items.find(item_4 => item_4.value === v)?.label || v || '', [itemToString_, items]);
  const handleKeyDown = React.useCallback(event => {
    const currentValue = event.target.value;
    if (event.shiftKey) {
      return;
    }
    setOpen(true);
    switch (event.keyCode) {
      case 27:
        event.stopPropagation();
        event.preventDefault();
        setSearchString('');
        return;
      case 8:
        // backspace
        if (!currentValue) {
          event.preventDefault();
          popItem();
        }
        return;
      case 46:
        // delete
        if (!searchString && deleteRemoves) {
          event.preventDefault();
          popItem();
        }
        return;
      case 188: // comma
      case 13:
        // enter
        if (activeIndex !== null) {
          handleItemClick();
        } else if (createValue) {
          if (typeof createValue === 'function') {
            const value_2 = createValue(searchString);
            if (value_2) {
              addItem(value_2);
            }
          } else {
            handleItemClick(searchString);
          }
          setSearchString('');
        }
        break;
      // case 188: // comma
      //   if (search && items.find((v) => v.value === search)) {
      //     addItem(search);
      //     setSearch('');
      //   } else if (search && createValue) {

      //     addItem(createValue(search));
      //     setSearch('');
      //   }
      //   if (event.keyCode === 188) {
      //     event.preventDefault();
      //   }
      //   event.stopPropagation();
      //   break;
      default:
        return;
    }
    event.preventDefault();
  }, [activeIndex, addItem, createValue, deleteRemoves, handleItemClick, popItem, searchString]);
  const handleInputChange = (event_0: React.ChangeEvent<HTMLInputElement>) => {
    setActiveIndex(null);
    setSearchString(event_0.target.value);
  };

  // const wrapperRef = React.useRef<HTMLDivElement>(null);
  const wrapperRef = refs.reference;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const onWrapperClick = React.useCallback(e => {
    if (wrapperRef.current === e.target || inputRef.current === e.target) {
      inputRef.current['focus']();
    }
    if (!open) {
      setOpen(true);
    }
  }, [open, wrapperRef]);
  return <>
      <StyledInput {...rest} as="div" invalid={invalid} sx={{
      cursor: 'text',
      display: 'flex',
      alignItems: 'baseline',
      flexWrap: 'wrap',
      minHeight: '42px',
      pl: 2,
      pr: 0,
      pb: 2,
      pt: 0,
      mr: -2,
      input: {
        pr: 2,
        lineHeight: 1.5,
        outline: 'none',
        padding: 0,
        border: 0,
        boxShadow: 'none',
        backgroundColor: 'transparent',
        fontSize: 2
      }
    }} {...getReferenceProps({
      ref: refs.setReference
    })} onClick={onWrapperClick} data-sentry-element="StyledInput" data-sentry-source-file="index.tsx">
        {value.map((item_5, idx_2) => <Tippy key={idx_2} content={itemToTooltip(item_5)} disabled={!itemToTooltip(item_5)}>
            <Flex mr={2} mt={2} fontSize={2} bg="gray.1" color="text" pt={0} pr={2} pb={0} pl={2} borderRadius={2} flexWrap="nowrap" alignItems="baseline" lineHeight="24px" sx={{
          textDecoration: 'none',
          cursor: 'default',
          '&:hover, &:focus, &:focus-within': {
            backgroundColor: 'gray.2'
          },
          '&:nth-last-child(2)': {
            maxWidth: 'calc(100% - 16px)'
          }
        }}>
              <Box sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
                {itemToString(item_5)}
              </Box>
              <Box flexShrink={0} as="button" type="button" ml={2} sx={{
            cursor: 'pointer',
            borderRadius: '10px',
            px: 1,
            '&:hover, &:focus': {
              backgroundColor: 'gray.1'
            }
          }} onClick={e_0 => {
            e_0.stopPropagation();
            removeItem(idx_2);
          }}>
                <FontAwesomeIcon icon={faTimes} />
              </Box>
            </Flex>
          </Tippy>)}

        <AutosizeInput inputStyle={{
        paddingTop: '8px'
      }} ref={inputRef} value={searchString} aria-autocomplete="list" onKeyDown={handleKeyDown} onChange={handleInputChange} onFocus={e_1 => {
        onFocus_?.(value);
      }} onBlur={e_2 => {
        onBlur_?.(value);
      }} placeholder={filteredItems_0.length > 0 && (value || []).length === 0 ? placeholder : ''} className="autosize-input" data-sentry-element="AutosizeInput" data-sentry-source-file="index.tsx" />
      </StyledInput>

      <MaybePortal data-sentry-element="MaybePortal" data-sentry-source-file="index.tsx">
        {open && <FloatingFocusManager context={context} initialFocus={-1}>
            <Box sx={dropdownSx} {...getFloatingProps({
          ref: refs.setFloating,
          style: {
            ...floatingStyles
            // background: '#eee',
            // color: 'black',
            // overflowY: 'auto',
          },
          onKeyDown(event_1) {
            if (event_1.key === 'Tab') {
              setOpen(false);
            }
          }
        })}>
              {/* {items.length !== 0 && filteredItems.length === 0 && (
                <Text
                  m={3}
                  key={searchString}
                  id={noResultsId}
                  role="region"
                  aria-atomic="true"
                  aria-live="assertive"
                  fontWeight="bold"
                  textAlign="center"
                  // width="100%"
                >
                  No results
                </Text>
               )} */}

              <Box overflow="auto" role="listbox" width="100%" id={listboxId}>
                {groupedItems_0.map((item_6, index) => <React.Fragment key={`${item_6}${index}`}>
                    {idxToGroupHeading_0[index]?.label && <Flex px={3} py={2} fontWeight="heading" color="gray.4" alignItems="center">
                        {idxToGroupHeading_0[index]?.iconElement && <Box mr={2}>
                            {idxToGroupHeading_0[index]?.iconElement}{' '}
                          </Box>}
                        {idxToGroupHeading_0[index].label}
                      </Flex>}
                    <Box as="button" type="button" display="block" width="100%" key={item_6.value} ref={node => {
                listRef.current[index] = node;
              }} {...getItemProps({
                onClick: e_3 => handleItemClick(item_6.value)
                // onKeyDown: (e) => handleKeyDown(e, item.value),
              })}
              // color="text"
              // px={3}
              // py={'12px'}
              sx={{
                padding: '0.5rem',
                backgroundColor: activeIndex === index ? 'primary_.5' : 'transparent',
                fontWeight: value === item_6.value ? 'heading' : 'normal',
                fontFamily: 'body',
                cursor: 'pointer',
                color: item_6.disabled ? 'gray.4' : activeIndex === index ? 'white' : 'inherit'
              }}>
                      <Flex alignItems="center">
                        {item_6.iconElement}

                        <Text sx={{
                    wordBreak: 'normal'
                  }}
                  // flex="1 1 200px"
                  ml={item_6.iconElement ? 3 : 0} dangerouslySetInnerHTML={{
                    __html: searchString ? highlight(item_6.label, searchString) : item_6.label
                  }} />
                      </Flex>
                      {item_6.extra && <Text dangerouslySetInnerHTML={{
                  __html: searchString && searchExtra && typeof item_6.extra === 'string' ? highlight(item_6.extra, searchString) : item_6.extra
                }} />}
                    </Box>
                  </React.Fragment>)}
              </Box>
            </Box>
          </FloatingFocusManager>}
      </MaybePortal>
    </>;
}

// DropdownSelect['whyDidYouRender'] = true;

export default Tags;