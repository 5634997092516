import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Box } from 'shared/grid';

// https://codepen.io/jensimmons/pen/KKPzxJa?editors=1100

const size = 16;
const RadioButton = props => {
  const $ = _c(7);
  let t0;
  let t1;
  let t2;
  let t3;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = {
      boxShadow: "inset 0 0 0 ${size * 0.15}px rgba(0, 0, 0, 0.12)"
    };
    t1 = {
      backgroundColor: "primary"
    };
    t2 = {
      boxShadow: `0 0 0 ${size * 0.15}px rgba(0, 0, 0, 0.12)`
    };
    t3 = {
      position: "relative",
      backgroundColor: "primary"
    };
    $[0] = t0;
    $[1] = t1;
    $[2] = t2;
    $[3] = t3;
  } else {
    t0 = $[0];
    t1 = $[1];
    t2 = $[2];
    t3 = $[3];
  }
  let t4;
  if ($[4] === Symbol.for("react.memo_cache_sentinel")) {
    t4 = {
      WebkitAppearance: "none",
      width: `${size}px`,
      height: `${size}px`,
      cursor: "pointer",
      borderRadius: "50%",
      padding: 0,
      outline: "none",
      display: "inline-block",
      verticalAlign: "text-top",
      backgroundColor: "gray.2",
      "&:not(:hover):not(:focus):not(:checked)": t0,
      "&:hover": t1,
      "&:focus, &:hover": t2,
      "&:checked": t3,
      "&:checked::after": {
        position: "absolute",
        left: `${Math.round(size * 0.25)}px`,
        top: `${Math.round(size * 0.25)}px`,
        content: "\" \"",
        width: `${Math.round(size * 0.5)}px`,
        height: `${Math.round(size * 0.5)}px`,
        backgroundColor: "white",
        borderRadius: "50%"
      }
    };
    $[4] = t4;
  } else {
    t4 = $[4];
  }
  let t5;
  if ($[5] !== props) {
    t5 = <Box as="input" type="radio" sx={t4} {...props} />;
    $[5] = props;
    $[6] = t5;
  } else {
    t5 = $[6];
  }
  return t5;
};
export default RadioButton;