import { c as _c } from "react/compiler-runtime";
import { faEllipsisV, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import get from 'lodash.get';
import React from 'react';
import { Field, Form, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import { Link, Text } from 'shared/typography';
import { UPLOAD_PATH_PREFIX } from 'shared/utils/constants';
import { getCdnUploadUrlFromPath, getFileBlobDespiteGoogleDrive } from 'shared/utils/uploads';
import anaxios from '../../anaxios';
import Button from '../../button';
import Callout from '../../callout';
import DropdownSelect from '../../dropdown-select';
import { FileIcon } from '../../file-icons';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import { getRecaptchaToken, useIsRecaptchaReady } from '../../hooks/use-recaptcha';
import Label from '../../label';
import useModal from '../../modal/use-modal';
import settings from '../../settings';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from '../shared';
const ONE_MEGABYTE = Math.pow(2, 20);
const ONE_KILOBYTE = Math.pow(2, 10);
const UPLOAD_SIZE_LIMIT_BYTES = 10 * Math.pow(2, 20);
const FormUploadPill = t0 => {
  const $ = _c(23);
  const {
    file: t1,
    remove: t2
  } = t0;
  const {
    name,
    size,
    upload_id
  } = t1;
  const remove = t2 === undefined ? null : t2;
  const t3 = `${UPLOAD_PATH_PREFIX}${upload_id}/${name}`;
  let t4;
  if ($[0] !== t3) {
    t4 = getCdnUploadUrlFromPath(t3);
    $[0] = t3;
    $[1] = t4;
  } else {
    t4 = $[1];
  }
  let t5;
  if ($[2] !== name || $[3] !== upload_id) {
    t5 = upload_id == "uploading" ? <Box position="relative" height={20} width={20}><Box sx={{
        content: "\"\"",
        position: "absolute",
        top: "50%",
        left: "50%",
        width: "20px",
        height: "20px",
        marginTop: "-10px",
        marginLeft: "-10px",
        borderRadius: "50%",
        border: "3px solid transparent",
        borderTopColor: "#666",
        borderRightColor: "#666",
        borderBottomColor: "#666",
        animation: "spinner 0.6s linear infinite"
      }} /></Box> : <FileIcon height={20} name={name} />;
    $[2] = name;
    $[3] = upload_id;
    $[4] = t5;
  } else {
    t5 = $[4];
  }
  let t6;
  if ($[5] === Symbol.for("react.memo_cache_sentinel")) {
    t6 = {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    };
    $[5] = t6;
  } else {
    t6 = $[5];
  }
  let t7;
  if ($[6] !== name) {
    t7 = <Box px={2} sx={t6} flexGrow={1} fontWeight="heading">{name}</Box>;
    $[6] = name;
    $[7] = t7;
  } else {
    t7 = $[7];
  }
  let t8;
  if ($[8] !== t4 || $[9] !== t5 || $[10] !== t7) {
    t8 = <Flex as="a" href={t4} target="_blank" alignItems="center" onClick={_temp}>{t5}{t7}</Flex>;
    $[8] = t4;
    $[9] = t5;
    $[10] = t7;
    $[11] = t8;
  } else {
    t8 = $[11];
  }
  let t9;
  if ($[12] === Symbol.for("react.memo_cache_sentinel")) {
    t9 = {
      whiteSpace: "nowrap"
    };
    $[12] = t9;
  } else {
    t9 = $[12];
  }
  let t10;
  if ($[13] !== size) {
    t10 = <Box px={2} sx={t9}>{size >= ONE_MEGABYTE ? <>{(size / ONE_MEGABYTE).toFixed(2)} M</> : <>{(size / ONE_KILOBYTE).toFixed(2)} K</>}</Box>;
    $[13] = size;
    $[14] = t10;
  } else {
    t10 = $[14];
  }
  let t11;
  if ($[15] !== remove) {
    t11 = remove && <DropdownSelect usePortal={true} value={null} items={[{
      label: "Remove",
      value: "remove",
      iconElement: <FontAwesomeIcon icon={faTimes} />
    }]} onChange={v => {
      if (v === "remove") {
        remove();
      }
    }} itemToString={_temp2} renderToggle={_temp4} />;
    $[15] = remove;
    $[16] = t11;
  } else {
    t11 = $[16];
  }
  let t12;
  if ($[17] !== t10 || $[18] !== t11) {
    t12 = <Flex flexShrink={0}>{t10}{t11}</Flex>;
    $[17] = t10;
    $[18] = t11;
    $[19] = t12;
  } else {
    t12 = $[19];
  }
  let t13;
  if ($[20] !== t12 || $[21] !== t8) {
    t13 = <Flex alignItems="center" justifyContent="space-between" borderColor="borderColor" borderWidth={1} borderStyle="solid" borderRadius={2} py={1} px={2} flexBasis="300px" flexShrink={0} mr={2} mb={2}>{t8}{t12}</Flex>;
    $[20] = t12;
    $[21] = t8;
    $[22] = t13;
  } else {
    t13 = $[22];
  }
  return t13;
};
const Uploads = t0 => {
  const $ = _c(36);
  const {
    schema,
    menu,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const form = useForm();
  const fileInputRef = React.useRef(null);
  const isPublic = useSelector(_temp5);
  const token = useSelector(_temp6);
  const businessId = useSelector(_temp7);
  const isRecaptchaReady = useIsRecaptchaReady();
  const fieldPrefix = React.useContext(FieldPrefixContext);
  const t2 = isCustom && "custom_fields";
  let t3;
  if ($[0] !== fieldPrefix || $[1] !== schema.name || $[2] !== t2) {
    t3 = [fieldPrefix, t2, schema.name].filter(_temp8);
    $[0] = fieldPrefix;
    $[1] = schema.name;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const fieldName = t3.join(".");
  const [uploadsErrorModal, showUploadsErrorModal] = useModal();
  let t4;
  if ($[4] !== schema.max_number_uploads || $[5] !== showUploadsErrorModal) {
    t4 = (title, filesNames) => {
      let innerContent;
      innerContent = null;
      if (filesNames.length == 1) {
        innerContent = <>The file {filesNames[0]} exceeds the 10M size limit</>;
      } else {
        if (filesNames.length > 1) {
          innerContent = <Grid><Text>The following files exceed the 10M size limit</Text><Text ml={3}>{filesNames.map(_temp9)}</Text></Grid>;
        } else {
          innerContent = <>You are allowed to upload a maximum of {schema.max_number_uploads}{" "}files. If you need to share more, you may need to compress them.</>;
        }
      }
      showUploadsErrorModal(title, () => <Callout color="warning">{innerContent}</Callout>);
    };
    $[4] = schema.max_number_uploads;
    $[5] = showUploadsErrorModal;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  const openUploadsErrorModal = t4;
  let t5;
  if ($[7] !== businessId || $[8] !== fieldName || $[9] !== form || $[10] !== isPublic || $[11] !== isRecaptchaReady || $[12] !== openUploadsErrorModal || $[13] !== schema.max_number_uploads || $[14] !== token) {
    t5 = (newFiles, currentFiles) => {
      const tooLargeFilesNames = (currentFiles || []).concat(newFiles).filter(_temp10).map(_temp11);
      if ((tooLargeFilesNames || []).length > 0) {
        openUploadsErrorModal(tooLargeFilesNames.length > 1 ? "The files you are trying to add are too large " : "The file you are trying to add is too large", tooLargeFilesNames);
        return;
      }
      if ((currentFiles?.length || 0) + newFiles.length > schema.max_number_uploads) {
        openUploadsErrorModal("Too many files", []);
        return;
      }
      const newFilesWithId = newFiles.map(file_1 => {
        const id = Math.random().toString(36).substring(7);
        form.mutators.push(fieldName, {
          name: file_1.name,
          upload_id: "uploading",
          size: file_1.size,
          type: file_1.type,
          id
        });
        return {
          file: file_1 as File,
          id
        };
      });
      newFilesWithId.forEach(async t6 => {
        const {
          file: file_2,
          id: id_0
        } = t6;
        if (isPublic && !isRecaptchaReady) {
          return;
        }
        let captchaToken;
        captchaToken = null;
        if (isPublic) {
          captchaToken = await getRecaptchaToken("PUBLIC_UPLOAD");
        }
        getFileBlobDespiteGoogleDrive(file_2).then(blob => {
          const formData = new FormData();
          formData.append("file", blob, file_2.name);
          if (captchaToken) {
            formData.append("captcha_token", captchaToken);
          }
          if (businessId) {
            formData.append("business_id", businessId);
          }
          const headers = {
            Accept: "application/json"
          };
          if (!isPublic) {
            headers.Authorization = `Bearer ${token}`;
          }
          anaxios.post(`${settings.api2Root}/${isPublic ? "form-submission-uploads" : "uploads"}`, formData, {
            headers
          }).then(t7 => {
            const {
              data: t8
            } = t7;
            const {
              id: upload_id
            } = t8;
            const uploads = get(form.getState().values, fieldName);
            const index = (uploads || []).findIndex(upload => upload.id == id_0);
            form.mutators.update(fieldName, index, {
              name: file_2.name,
              upload_id,
              size: file_2.size,
              type: file_2.type
            });
          });
        });
      });
    };
    $[7] = businessId;
    $[8] = fieldName;
    $[9] = form;
    $[10] = isPublic;
    $[11] = isRecaptchaReady;
    $[12] = openUploadsErrorModal;
    $[13] = schema.max_number_uploads;
    $[14] = token;
    $[15] = t5;
  } else {
    t5 = $[15];
  }
  const addFiles = t5;
  let t6;
  if ($[16] !== schema.required) {
    t6 = value => {
      if (schema.required && !value) {
        return "Add a file";
      }
    };
    $[16] = schema.required;
    $[17] = t6;
  } else {
    t6 = $[17];
  }
  let t7;
  if ($[18] !== addFiles || $[19] !== className || $[20] !== fieldName || $[21] !== form || $[22] !== isRecaptchaReady || $[23] !== menu || $[24] !== schema.helptext || $[25] !== schema.label || $[26] !== schema.max_number_uploads || $[27] !== style) {
    t7 = t8 => {
      const {
        input,
        meta
      } = t8;
      return <Box style={style} className={className}><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><Box display="grid" style={{
          gap: "8px"
        }}><Text>Upload{" "}{schema.max_number_uploads == 1 ? "one file. Max 10MB" : `up to ${schema.max_number_uploads} files. Max 10MB per file.`}</Text>{(input.value || []).length > 0 ? <Flex mt={2} flexBasis="50%" sx={{
            overflowX: "hidden",
            "div:hover > &": {
              overflowX: "overlay"
            }
          }}>{input.value.map((v_0, idx_0) => <FormUploadPill key={idx_0} file={v_0} remove={() => form.mutators.remove(fieldName, idx_0)} />)}</Flex> : null}{(input.value || []).length < schema.max_number_uploads && <Button width="fit-content" color="secondary" variant="outlined" iconLeft={faUpload} onClick={() => {
            fileInputRef.current.click();
          }}>Add file<Box disabled={!isRecaptchaReady} as="input" ref={fileInputRef} sx={{
              display: "none !important"
            }} type="file" name="file" multiple={true} value="" onChange={e => addFiles(Array.from(e.target.files), input.values)} /></Button>}</Box>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[18] = addFiles;
    $[19] = className;
    $[20] = fieldName;
    $[21] = form;
    $[22] = isRecaptchaReady;
    $[23] = menu;
    $[24] = schema.helptext;
    $[25] = schema.label;
    $[26] = schema.max_number_uploads;
    $[27] = style;
    $[28] = t7;
  } else {
    t7 = $[28];
  }
  let t8;
  if ($[29] !== fieldName || $[30] !== t6 || $[31] !== t7) {
    t8 = <Field name={fieldName} validate={t6}>{t7}</Field>;
    $[29] = fieldName;
    $[30] = t6;
    $[31] = t7;
    $[32] = t8;
  } else {
    t8 = $[32];
  }
  let t9;
  if ($[33] !== t8 || $[34] !== uploadsErrorModal) {
    t9 = <>{t8}{uploadsErrorModal}</>;
    $[33] = t8;
    $[34] = uploadsErrorModal;
    $[35] = t9;
  } else {
    t9 = $[35];
  }
  return t9;
};
export default Uploads;
export const toString = value => {
  return (Array.isArray(value) ? [value.map(v => v.name)] : []).join(', ');
};
export const View = t0 => {
  const $ = _c(7);
  const {
    value
  } = t0;
  let t1;
  if ($[0] !== value) {
    t1 = value || [];
    $[0] = value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  const v = t1;
  let t2;
  if ($[2] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = {
      overflowX: "hidden",
      "div:hover > &": {
        overflowX: "overlay"
      }
    };
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  let t3;
  if ($[3] !== v) {
    t3 = v.map(_temp12);
    $[3] = v;
    $[4] = t3;
  } else {
    t3 = $[4];
  }
  let t4;
  if ($[5] !== t3) {
    t4 = <Flex mt={2} flexBasis="50%" sx={t2}>{t3}</Flex>;
    $[5] = t3;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  return t4;
};
export const Edit = t0 => {
  const $ = _c(13);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp13);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] === Symbol.for("react.memo_cache_sentinel")) {
    t2 = [{
      label: "1",
      value: 1
    }, {
      label: "5",
      value: 5
    }, {
      label: "10",
      value: 10
    }];
    $[1] = t2;
  } else {
    t2 = $[1];
  }
  const maxUploadsItems = t2;
  let t3;
  if ($[2] !== className || $[3] !== hideRequired || $[4] !== isEditing || $[5] !== isUniqueFieldName || $[6] !== menu || $[7] !== removable || $[8] !== showClientFieldReference || $[9] !== showMore || $[10] !== style || $[11] !== value) {
    t3 = <>{!isEditing ? <Form onSubmit={_temp14} mutators={arrayMutators as any}>{t4 => <Uploads schema={value} menu={menu} style={style} className={className} />}</Form> : <Grid display={!isEditing ? "none" : undefined}><LabelField prefix="" /><Field name={"" ? `${""}.max_number_uploads` : "max_number_uploads"}>{t5 => {
            const {
              input
            } = t5;
            return <Flex alignItems="center" maxHeight="25px"><DropdownSelect {...input} renderToggle={_temp15} items={maxUploadsItems} itemToString={v_0 => maxUploadsItems.find(i => i.value === v_0)?.label} /><Box ml={2}>Maximum number of files</Box></Flex>;
          }}</Field>{!hideRequired && <RequiredField prefix="" />}<HelpTextField prefix="" />{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} size="small" width="100%" variant="outlined">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[2] = className;
    $[3] = hideRequired;
    $[4] = isEditing;
    $[5] = isUniqueFieldName;
    $[6] = menu;
    $[7] = removable;
    $[8] = showClientFieldReference;
    $[9] = showMore;
    $[10] = style;
    $[11] = value;
    $[12] = t3;
  } else {
    t3 = $[12];
  }
  return t3;
};
function _temp(e) {
  return e.stopPropagation();
}
function _temp2(item) {
  return item;
}
function _temp3(e_0) {
  return e_0.stopPropagation();
}
function _temp4(props) {
  return <Box onClick={_temp3} data-sentry-element="Box" data-sentry-component="_temp4" data-sentry-source-file="index.tsx"><Link as="button" type="button" fontWeight="heading" sx={{
      textTransform: "uppercase"
    }} backgroundColor="gray.1" width="24px" height="24px" display="flex" alignItems="center" justifyContent="center" borderRadius={1000} {...props} data-sentry-element="Link" data-sentry-source-file="index.tsx" data-sentry-element="Link"><FontAwesomeIcon icon={faEllipsisV} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="index.tsx" data-sentry-element="FontAwesomeIcon" /></Link></Box>;
}
function _temp5(state) {
  return !!state.public;
}
function _temp6(state_0) {
  return state_0.session.token;
}
function _temp7(state_1) {
  return state_1.public ? state_1.public.business_id : null;
}
function _temp8(v) {
  return v;
}
function _temp9(error, idx) {
  return <li key={idx} data-sentry-component="_temp9" data-sentry-source-file="index.tsx">{error}</li>;
}
function _temp10(file) {
  return file.size > UPLOAD_SIZE_LIMIT_BYTES;
}
function _temp11(file_0) {
  if (file_0.size >= ONE_MEGABYTE) {
    return `${file_0.name} [${(file_0.size / ONE_MEGABYTE).toFixed(2)} M]`;
  } else {
    return `${file_0.name} [${(file_0.size / ONE_KILOBYTE).toFixed(2)} K]`;
  }
}
function _temp12(v_0, idx) {
  return <FormUploadPill key={idx} file={v_0} data-sentry-element="FormUploadPill" data-sentry-component="_temp12" data-sentry-source-file="index.tsx" />;
}
function _temp13(v) {
  return !v;
}
function _temp14(value_0) {
  return null;
}
function _temp15(props, t0) {
  const {
    displayString
  } = t0;
  return <Button {...props} color="secondary" data-sentry-element="Button" data-sentry-component="_temp15" data-sentry-source-file="index.tsx"><Text data-sentry-element="Text" data-sentry-source-file="index.tsx" data-sentry-element="Text">{displayString}</Text></Button>;
}