import { c as _c } from "react/compiler-runtime";
import { faPlusCircle, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tippy from 'shared/tooltip';
import setFieldTouched from 'final-form-set-field-touched';
import React from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch, useSelector } from 'react-redux';
import { LabeledCheckbox } from 'shared/checkbox';
import Autocomplete from '../autocomplete';
import Button from '../button';
import FormError from '../form-error';
import { Box, Flex, Grid } from '../grid';
import InfoIcon from '../info-icon';
import Input from '../input';
import InputGroup from '../input-group';
import Label from '../label';
import { fetchDataList, fetchDataLists } from '../redux/data-lists/actions';
import Select from '../select';
import Switch from '../switch';
import { Link } from '../typography';
import { useIsDatalistValid } from './hooks';
export const FieldPrefixContext = React.createContext('');
export const LabelField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.label` : 'label'} validate={value => {
  if (!value) {
    return 'Enter a label';
  }
}} data-sentry-element="Field" data-sentry-component="LabelField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label="Label" type="text" error={meta.touched && meta.error} />
      </Box>}
  </Field>;
export const PlaceholderField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.placeholder` : 'placeholder'} data-sentry-element="Field" data-sentry-component="PlaceholderField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label={<>
              Placeholder text{' '}
              <InfoIcon value="Placeholder text that appears when the field is empty" />
            </>} error={meta.touched && meta.error} type="text" />
      </Box>}
  </Field>;
export const HelpTextField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.helptext` : 'helptext'} data-sentry-element="Field" data-sentry-component="HelpTextField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label={<>
              Help text{' '}
              <InfoIcon value="Provides guidance as to how to fill in this field" />
            </>} type="text" error={meta.touched && meta.error} />
      </Box>}
  </Field>;
export const PrivateField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.private` : 'private'} type="checkbox" data-sentry-element="Field" data-sentry-component="PrivateField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Label>
          <LabeledCheckbox {...input} mr={2} label="For office use only (not visible to clients)" />
          {meta.touched && meta.error && <FormError>{meta.error}</FormError>}
        </Label>
      </Box>}
  </Field>;
export const NameField = ({
  prefix,
  isUniqueFieldName,
  removable
}) => <Field parse={value => value?.toLowerCase?.()} name={prefix ? `${prefix}.name` : 'name'} validate={(value, values: any) => {
  if (!value) {
    return 'Enter a field name';
  }
  if (value.match(/[^0-9a-zA-Z_$]/)) {
    return `A field name may only contain letters, numbers, and '_'`;
  }
  if (['business_id', 'client_id', 'form_id', 'id'].includes(value)) {
    return `${value} is reserved and cannot be used`;
  }
  if (removable && !isUniqueFieldName(value, values.id)) {
    return `Another field named "${value}" id is already used`;
  }
}} data-sentry-element="Field" data-sentry-component="NameField" data-sentry-source-file="shared.tsx">
    {({
    input,
    meta
  }) => <Box>
        <Input {...input} label={<>
              Field name{' '}
              <InfoIcon value="A unique identifier that can be used to reference this field" />
            </>} disabled={!removable} type="text" error={meta.touched && meta.error} />
      </Box>}
  </Field>;
export const RequiredField = ({
  prefix
}) => <Field name={prefix ? `${prefix}.required` : 'required'} data-sentry-element="Field" data-sentry-component="RequiredField" data-sentry-source-file="shared.tsx">
    {({
    input
  }) => <Flex alignItems="center">
        <Switch size="small" {...input} />
        <Box ml={2}>Required</Box>
      </Flex>}
  </Field>;
export const ClientFieldReference = t0 => {
  const $ = _c(13);
  const {
    prefix,
    schema
  } = t0;
  const clientSchema = useSelector(_temp);
  let t1;
  let t2;
  if ($[0] !== clientSchema || $[1] !== schema.type) {
    let t3;
    if ($[3] !== schema.type) {
      t3 = s => s.type === schema.type;
      $[3] = schema.type;
      $[4] = t3;
    } else {
      t3 = $[4];
    }
    t2 = clientSchema.filter(t3).map(_temp2);
    $[0] = clientSchema;
    $[1] = schema.type;
    $[2] = t2;
  } else {
    t2 = $[2];
  }
  t1 = t2;
  const options = t1;
  let t3;
  let t4;
  if ($[5] !== clientSchema) {
    t4 = clientSchema.reduce(_temp3, {});
    $[5] = clientSchema;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  t3 = t4;
  const valueToLabel = t3;
  const t5 = prefix ? `${prefix}.associated_client_field_name` : "associated_client_field_name";
  let t6;
  if ($[7] !== options || $[8] !== valueToLabel) {
    t6 = t7 => {
      const {
        input,
        meta
      } = t7;
      return <Box><Label mb={2} invalid={meta.touched && meta.error}>Link to client field{" "}<InfoIcon value="When the form is submitted, this client field will be updated" /></Label><InputGroup><Autocomplete {...input} sx={{
            display: "block",
            width: "100%"
          }} invalid={meta.touched && meta.error} itemToString={v => valueToLabel[v] || ""} items={options} />{input.value && <Button onClick={e => input.onChange("")}>Unlink</Button>}</InputGroup>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}</Box>;
    };
    $[7] = options;
    $[8] = valueToLabel;
    $[9] = t6;
  } else {
    t6 = $[9];
  }
  let t7;
  if ($[10] !== t5 || $[11] !== t6) {
    t7 = <Field name={t5}>{t6}</Field>;
    $[10] = t5;
    $[11] = t6;
    $[12] = t7;
  } else {
    t7 = $[12];
  }
  return t7;
};
export const DataSourceField = t0 => {
  const $ = _c(19);
  const {
    prefix
  } = t0;
  const values = useFormState().values;
  const dispatch = useDispatch();
  const businessId = useSelector(_temp4);
  let t1;
  let t2;
  if ($[0] !== businessId || $[1] !== dispatch) {
    t1 = () => {
      dispatch(fetchDataLists({
        businessId
      }));
    };
    t2 = [businessId, dispatch];
    $[0] = businessId;
    $[1] = dispatch;
    $[2] = t1;
    $[3] = t2;
  } else {
    t1 = $[2];
    t2 = $[3];
  }
  React.useEffect(t1, t2);
  const dataListData = useSelector(_temp5);
  let t3;
  let t4;
  if ($[4] !== businessId || $[5] !== dataListData) {
    let t5;
    if ($[7] !== businessId) {
      t5 = l => l.business_id === businessId;
      $[7] = businessId;
      $[8] = t5;
    } else {
      t5 = $[8];
    }
    t4 = Object.values(dataListData).filter(t5).map(_temp6);
    $[4] = businessId;
    $[5] = dataListData;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  t3 = t4;
  const dataListsItems = t3;
  const dataSource = prefix ? values[prefix].data_source : values.data_source;
  const t5 = prefix ? `${prefix}.data_source` : "data_source";
  let t6;
  if ($[9] !== t5) {
    t6 = <Box><Field name={t5}>{_temp7}</Field></Box>;
    $[9] = t5;
    $[10] = t6;
  } else {
    t6 = $[10];
  }
  let t7;
  if ($[11] !== dataListData || $[12] !== dataListsItems || $[13] !== dataSource || $[14] !== prefix) {
    t7 = dataSource === "data_list_id" ? <Box><Label mb={2}>Data list</Label><Field name={prefix ? `${prefix}.data_list_id` : "data_list_id"}>{t8 => {
          const {
            input: input_0,
            meta: meta_0
          } = t8;
          return <><Box><Autocomplete {...input_0} truncate={100} items={dataListsItems} itemToString={v => dataListData[v]?.label || ""} /></Box>{meta_0.touched && meta_0.error && <FormError>{meta_0.error}</FormError>}</>;
        }}</Field></Box> : <Box><Label mb={2}>Options</Label><FieldArray name={prefix ? `${prefix}.options` : "options"}>{_temp9}</FieldArray></Box>;
    $[11] = dataListData;
    $[12] = dataListsItems;
    $[13] = dataSource;
    $[14] = prefix;
    $[15] = t7;
  } else {
    t7 = $[15];
  }
  let t8;
  if ($[16] !== t6 || $[17] !== t7) {
    t8 = <>{t6}{t7}</>;
    $[16] = t6;
    $[17] = t7;
    $[18] = t8;
  } else {
    t8 = $[18];
  }
  return t8;
};
export const useOptionItems = schema => {
  const $ = _c(17);
  const dispatch = useDispatch();
  const dataListData = useSelector(_temp10);
  let t0;
  let t1;
  if ($[0] !== dataListData || $[1] !== schema.data_list_id || $[2] !== schema.data_source) {
    t1 = schema.data_source === "data_list_id" ? (dataListData[schema.data_list_id]?.items || []).reduce(_temp11, {}) : {};
    $[0] = dataListData;
    $[1] = schema.data_list_id;
    $[2] = schema.data_source;
    $[3] = t1;
  } else {
    t1 = $[3];
  }
  t0 = t1;
  const valueToDescription = t0;
  let t2;
  let t3;
  if ($[4] !== dataListData || $[5] !== schema.data_list_id || $[6] !== schema.data_source || $[7] !== schema.options) {
    t3 = schema.data_source === "data_list_id" ? (dataListData[schema.data_list_id]?.items || []).map(_temp12) : (schema.options || []).map(_temp13);
    $[4] = dataListData;
    $[5] = schema.data_list_id;
    $[6] = schema.data_source;
    $[7] = schema.options;
    $[8] = t3;
  } else {
    t3 = $[8];
  }
  t2 = t3;
  const items = t2;
  let t4;
  let t5;
  if ($[9] !== dispatch || $[10] !== items.length || $[11] !== schema.data_list_id) {
    t4 = () => {
      if (schema.data_list_id && items.length === 0) {
        dispatch(fetchDataList(schema.data_list_id));
      }
    };
    t5 = [dispatch, schema.data_list_id, items.length];
    $[9] = dispatch;
    $[10] = items.length;
    $[11] = schema.data_list_id;
    $[12] = t4;
    $[13] = t5;
  } else {
    t4 = $[12];
    t5 = $[13];
  }
  React.useEffect(t4, t5);
  let t6;
  if ($[14] !== items || $[15] !== valueToDescription) {
    t6 = [items, valueToDescription];
    $[14] = items;
    $[15] = valueToDescription;
    $[16] = t6;
  } else {
    t6 = $[16];
  }
  return t6;
};
function _temp(state) {
  return state.businesses.data[state.userPreferences.currentBusinessId].client_schema;
}
function _temp2(s_0) {
  return {
    value: s_0.name,
    label: s_0.label
  };
}
function _temp3(agg, cur) {
  agg[cur.name] = cur.label;
  return agg;
}
function _temp4(state) {
  return state.userPreferences.currentBusinessId;
}
function _temp5(state_0) {
  return state_0.dataLists.data;
}
function _temp6(t0) {
  const {
    id,
    label
  } = t0;
  return {
    value: id,
    label
  };
}
function _temp7(t0) {
  const {
    input
  } = t0;
  return <Select {...input} label="List source" data-sentry-element="Select" data-sentry-component="_temp7" data-sentry-source-file="shared.tsx"><option value="options">Options list</option><option value="data_list_id">Data list</option></Select>;
}
function _temp8(value) {
  if (!value) {
    return "Enter ";
  }
}
function _temp9(t0) {
  const {
    fields
  } = t0;
  return <Grid data-sentry-element="Grid" data-sentry-component="_temp9" data-sentry-source-file="shared.tsx">{fields.map((name, index) => <Field key={index} name={name} validate={_temp8}>{t1 => {
        const {
          input: input_1,
          meta: meta_1
        } = t1;
        return <Box><Input {...input_1} type="text" invalid={meta_1.touched && meta_1.error} suffix={<Tippy content="Remove"><Link color="alert" as="button" type="button" onClick={() => fields.remove(index)}><FontAwesomeIcon icon={faTrash} /></Link></Tippy>} />{meta_1.error && meta_1.touched && <FormError>{meta_1.error}</FormError>}</Box>;
      }}</Field>)}<Link as="button" type="button" onClick={() => fields.push("")} data-sentry-element="Link" data-sentry-source-file="shared.tsx" data-sentry-element="Link"><FontAwesomeIcon icon={faPlusCircle} data-sentry-element="FontAwesomeIcon" data-sentry-source-file="shared.tsx" data-sentry-element="FontAwesomeIcon" /> Add option</Link></Grid>;
}
function _temp10(state) {
  return state.dataLists.data;
}
function _temp11(agg, v) {
  agg[v.value] = v.description;
  return agg;
}
function _temp12(v_0) {
  return {
    label: `${v_0.description} - ${v_0.value}`,
    value: v_0.value
  };
}
function _temp13(v_1) {
  return {
    label: v_1,
    value: v_1
  };
}