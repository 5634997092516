import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { DateTime } from 'luxon';
import Input from '../input';
import { BoxProps } from '../grid';

// Only hours and minutes, no timezone or UTC
const validateRegex = /^\d{4}-[01]\d-[0-3]\d$/;
interface DateOnlyPickerProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'prefix'> {
  value: string;
  onChange: (value: string) => any;
  className?: string;
  style?: Record<string, any>;
  earliest?: string;
  latest?: string;
  disabled?: boolean;
  required?: boolean;
  tabIndex?: number;
  invalid?: boolean;
  label?: any;
  error?: any;
  forceText?: boolean;
  sx?: BoxProps['sx'];
}
const isValidDate = v => (v || '').match(validateRegex) && DateTime.fromISO(v).isValid;

// const toValue = (valueText, displayTimezone) =>

const DateOnlyPicker = t0 => {
  const $ = _c(29);
  let earliest;
  let forceText;
  let latest;
  let onBlur;
  let onChange;
  let ref;
  let rest;
  let value;
  if ($[0] !== t0) {
    ({
      ref,
      value,
      onChange,
      earliest,
      latest,
      onBlur,
      forceText,
      ...rest
    } = t0);
    $[0] = t0;
    $[1] = earliest;
    $[2] = forceText;
    $[3] = latest;
    $[4] = onBlur;
    $[5] = onChange;
    $[6] = ref;
    $[7] = rest;
    $[8] = value;
  } else {
    earliest = $[1];
    forceText = $[2];
    latest = $[3];
    onBlur = $[4];
    onChange = $[5];
    ref = $[6];
    rest = $[7];
    value = $[8];
  }
  const dateInputType = forceText ? "text" : "date";
  const [displayText, setDisplayText_] = React.useState("");
  let t1;
  if ($[9] !== onChange) {
    t1 = displayText_0 => {
      setDisplayText_(displayText_0);
      if (!displayText_0) {
        onChange("");
      }
      if (displayText_0 && !isValidDate(displayText_0)) {
        return;
      }
      onChange(displayText_0.substring(0, 10));
    };
    $[9] = onChange;
    $[10] = t1;
  } else {
    t1 = $[10];
  }
  const setDisplayText = t1;
  let t2;
  let t3;
  if ($[11] !== earliest || $[12] !== latest || $[13] !== onChange || $[14] !== value) {
    t2 = () => {
      if (earliest && earliest.substring(0, 10) > value) {
        onChange(earliest);
      } else {
        if (latest && latest.substring(0, 10) < value) {
          onChange(latest);
        } else {
          if (isValidDate(value)) {
            setDisplayText_(value);
          }
        }
      }
    };
    t3 = [value, earliest, latest, onChange];
    $[11] = earliest;
    $[12] = latest;
    $[13] = onChange;
    $[14] = value;
    $[15] = t2;
    $[16] = t3;
  } else {
    t2 = $[15];
    t3 = $[16];
  }
  React.useEffect(t2, t3);
  let t4;
  if ($[17] !== onBlur || $[18] !== value) {
    t4 = e => {
      setDisplayText_(value);
      if (onBlur) {
        onBlur(e);
      }
    };
    $[17] = onBlur;
    $[18] = value;
    $[19] = t4;
  } else {
    t4 = $[19];
  }
  let t5;
  if ($[20] !== setDisplayText) {
    t5 = v => setDisplayText(v.target.value);
    $[20] = setDisplayText;
    $[21] = t5;
  } else {
    t5 = $[21];
  }
  let t6;
  if ($[22] !== dateInputType || $[23] !== displayText || $[24] !== ref || $[25] !== rest || $[26] !== t4 || $[27] !== t5) {
    t6 = <Input {...rest} max="2999-12-31" ref={ref} placeholder="YYYY-MM-DD" type={dateInputType} value={displayText} onBlur={t4} onChange={t5} />;
    $[22] = dateInputType;
    $[23] = displayText;
    $[24] = ref;
    $[25] = rest;
    $[26] = t4;
    $[27] = t5;
    $[28] = t6;
  } else {
    t6 = $[28];
  }
  return t6;
};
export default React.memo(DateOnlyPicker);