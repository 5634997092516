import { c as _c } from "react/compiler-runtime";
import useComponentSize from '../../hooks/component-size';
import React from 'react';
import { Field, Form } from 'react-final-form';
import Button from '../../button';
import FormError from '../../form-error';
import HelpText from '../../form-help-text';
import { Box, Flex, Grid } from '../../grid';
import Label from '../../label';
import useDraw, { toSVG } from '../../use-draw';
import { ClientFieldReference, FieldPrefixContext, HelpTextField, LabelField, NameField, PrivateField, RequiredField } from './../shared';
const ASPECT_RATIO = 0.33; // w/h
const STROKE_COLOR = '#333';
const SigDraw = React.memo(function Sigdraw({
  value,
  onChange,
  disabled,
  name
}: any) {
  const initialRef = React.useRef(value);
  const containerRef = React.useRef<HTMLImageElement>(null);
  const {
    width,
    height
  } = useComponentSize(containerRef);
  const {
    setCanvas,
    fromData
  } = useDraw(containerRef, initialRef.current, onChange, '#000000');
  return <Box position="relative" ref={containerRef} height={width * ASPECT_RATIO} width="100%" bg="gray.0" name={name} sx={{
    userSelect: 'none',
    WebkitUserSelect: 'none',
    WebkitTouch: 'none',
    canvas: {
      pointerEvents: disabled ? 'none' : 'all',
      position: 'absolute'
    }
  }}>
      <canvas height={height} width={width} ref={setCanvas}></canvas>
      <Box p={3} as="button" type="button"
    // color="secondary"
    sx={{
      position: 'absolute',
      bottom: 0,
      right: 0
    }} onClick={e => {
      initialRef.current = [];
      onChange(initialRef.current);
      fromData(initialRef.current);
      e.stopPropagation();
    }}>
        Clear
      </Box>
    </Box>;
});
const EMPTY_ARRAY = [];
const Signature = t0 => {
  const $ = _c(17);
  const {
    schema,
    menu,
    disabled,
    style,
    className,
    isCustom: t1
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const prefix = React.useContext(FieldPrefixContext);
  const t2 = isCustom && "custom_fields";
  let t3;
  if ($[0] !== prefix || $[1] !== schema.name || $[2] !== t2) {
    t3 = [prefix, t2, schema.name].filter(_temp);
    $[0] = prefix;
    $[1] = schema.name;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const name = t3.join(".");
  let t4;
  if ($[4] !== schema.required) {
    t4 = value => {
      if (schema.required && (!value || value.length === 0)) {
        return "Signature required";
      }
    };
    $[4] = schema.required;
    $[5] = t4;
  } else {
    t4 = $[5];
  }
  let t5;
  if ($[6] !== className || $[7] !== disabled || $[8] !== menu || $[9] !== schema.helptext || $[10] !== schema.label || $[11] !== style) {
    t5 = t6 => {
      const {
        input,
        meta
      } = t6;
      return <Box style={style} className={className}><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><SigDraw disabled={disabled} {...input} />{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[6] = className;
    $[7] = disabled;
    $[8] = menu;
    $[9] = schema.helptext;
    $[10] = schema.label;
    $[11] = style;
    $[12] = t5;
  } else {
    t5 = $[12];
  }
  let t6;
  if ($[13] !== name || $[14] !== t4 || $[15] !== t5) {
    t6 = <Field defaultValue={EMPTY_ARRAY} name={name} validate={t4}>{t5}</Field>;
    $[13] = name;
    $[14] = t4;
    $[15] = t5;
    $[16] = t6;
  } else {
    t6 = $[16];
  }
  return t6;
};
export default Signature;
export const toString = value => {
  return value || '';
};
export const View = t0 => {
  const $ = _c(3);
  const {
    value
  } = t0;
  const ref = React.useRef(null);
  const {
    width,
    height
  } = useComponentSize(ref);
  let t1;
  let strokes = value || [];
  if (!strokes[0]?.color) {
    strokes = (value || []).filter(_temp2).map(stroke => ({
      color: STROKE_COLOR,
      maxWidth: 2,
      minWidth: 0.5,
      sourceCanvas: {
        width,
        height
      },
      points: stroke.map(_temp3)
    }));
  }
  t1 = toSVG(strokes, width, height).replace("\n", " ");
  const svgDataUri = t1;
  const t2 = width * ASPECT_RATIO;
  const t3 = `${svgDataUri}`;
  let t4;
  if ($[0] !== t2 || $[1] !== t3) {
    t4 = <Box as="img" height={t2} width="100%" ref={ref} alt="drawing" src={t3} />;
    $[0] = t2;
    $[1] = t3;
    $[2] = t4;
  } else {
    t4 = $[2];
  }
  return t4;
};
export const Edit = t0 => {
  const $ = _c(13);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style,
    disabled
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp4);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== disabled || $[3] !== hideRequired || $[4] !== isEditing || $[5] !== isUniqueFieldName || $[6] !== menu || $[7] !== removable || $[8] !== showClientFieldReference || $[9] !== showMore || $[10] !== style || $[11] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp5}>{t3 => <Signature schema={value} disabled={disabled} menu={menu} style={style} className={className} />}</Form> : <Grid display={!isEditing ? "none" : undefined}><LabelField prefix="" />{!hideRequired && <RequiredField prefix="" />}<HelpTextField prefix="" />{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} size="small" width="100%" variant="outlined">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = disabled;
    $[3] = hideRequired;
    $[4] = isEditing;
    $[5] = isUniqueFieldName;
    $[6] = menu;
    $[7] = removable;
    $[8] = showClientFieldReference;
    $[9] = showMore;
    $[10] = style;
    $[11] = value;
    $[12] = t2;
  } else {
    t2 = $[12];
  }
  return t2;
};
function _temp(v) {
  return v;
}
function _temp2(v) {
  return v.length;
}
function _temp3(t0, idx) {
  const {
    x,
    y
  } = t0;
  return {
    x,
    y,
    time: idx * 10
  };
}
function _temp4(v) {
  return !v;
}
function _temp5(value_0) {
  return null;
}