import { c as _c } from "react/compiler-runtime";
import React from 'react';
import { Field, Form } from 'react-final-form';
import isEmail from 'shared/utils/is-email';
import { HelpTextField, LabelField, NameField, PlaceholderField, RequiredField, PrivateField, ClientFieldReference, FieldPrefixContext } from '../shared';
import Button from '../../button';
import Input from '../../input';
import HelpText from '../../form-help-text';
import FormError from '../../form-error';
import Label from '../../label';
import { Grid, Box, Flex } from '../../grid';
import { disableAutoCompleteInput } from 'shared/utils/form';
const Email = t0 => {
  const $ = _c(24);
  const {
    schema,
    disabled,
    menu,
    style,
    className,
    disableAutoComplete,
    isCustom: t1,
    getWrapper
  } = t0;
  const isCustom = t1 === undefined ? false : t1;
  const prefix = React.useContext(FieldPrefixContext);
  const t2 = isCustom && "custom_fields";
  let t3;
  if ($[0] !== prefix || $[1] !== schema.name || $[2] !== t2) {
    t3 = [prefix, t2, schema.name].filter(_temp);
    $[0] = prefix;
    $[1] = schema.name;
    $[2] = t2;
    $[3] = t3;
  } else {
    t3 = $[3];
  }
  const name = t3.join(".");
  let t4;
  if ($[4] !== getWrapper || $[5] !== name) {
    t4 = getWrapper?.(name) || [React.Fragment, {}];
    $[4] = getWrapper;
    $[5] = name;
    $[6] = t4;
  } else {
    t4 = $[6];
  }
  const [Wrapper, wrapperProps] = t4;
  let t5;
  if ($[7] !== schema.required) {
    t5 = value => {
      if (schema.required && !value) {
        return "Enter an email";
      }
      if (value && !isEmail(value)) {
        return "Enter a valid email";
      }
    };
    $[7] = schema.required;
    $[8] = t5;
  } else {
    t5 = $[8];
  }
  let t6;
  if ($[9] !== Wrapper || $[10] !== className || $[11] !== disableAutoComplete || $[12] !== disabled || $[13] !== menu || $[14] !== schema.helptext || $[15] !== schema.label || $[16] !== schema.placeholder || $[17] !== style || $[18] !== wrapperProps) {
    t6 = t7 => {
      const {
        input,
        meta
      } = t7;
      return <Box style={style} className={className}><Flex mb={2} justifyContent="space-between"><Label sx={{
            flexGrow: 1
          }}>{schema.label}</Label>{menu}</Flex><Wrapper {...wrapperProps}><Input {...disableAutoCompleteInput(input, disableAutoComplete)} tabIndex={disabled ? -1 : undefined} type="email" invalid={meta.touched && meta.error} placeholder={schema.placeholder} /></Wrapper>{meta.error && meta.touched && <FormError>{meta.error}</FormError>}{schema.helptext && <HelpText>{schema.helptext}</HelpText>}</Box>;
    };
    $[9] = Wrapper;
    $[10] = className;
    $[11] = disableAutoComplete;
    $[12] = disabled;
    $[13] = menu;
    $[14] = schema.helptext;
    $[15] = schema.label;
    $[16] = schema.placeholder;
    $[17] = style;
    $[18] = wrapperProps;
    $[19] = t6;
  } else {
    t6 = $[19];
  }
  let t7;
  if ($[20] !== name || $[21] !== t5 || $[22] !== t6) {
    t7 = <Field name={name} validate={t5}>{t6}</Field>;
    $[20] = name;
    $[21] = t5;
    $[22] = t6;
    $[23] = t7;
  } else {
    t7 = $[23];
  }
  return t7;
};
export default Email;
export const toString = value => {
  return value || '';
};
export const View = t0 => {
  const $ = _c(2);
  const {
    value
  } = t0;
  let t1;
  if ($[0] !== value) {
    t1 = <Box>{value}</Box>;
    $[0] = value;
    $[1] = t1;
  } else {
    t1 = $[1];
  }
  return t1;
};
export const Edit = t0 => {
  const $ = _c(14);
  const {
    isEditing,
    className,
    isUniqueFieldName,
    hideRequired,
    showClientFieldReference,
    value,
    menu,
    style,
    disableAutoComplete,
    getWrapper
  } = t0;
  const [showMore, setShowMore] = React.useState(false);
  let t1;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t1 = () => setShowMore(_temp2);
    $[0] = t1;
  } else {
    t1 = $[0];
  }
  const toggleMore = t1;
  const removable = value.removable;
  let t2;
  if ($[1] !== className || $[2] !== disableAutoComplete || $[3] !== getWrapper || $[4] !== hideRequired || $[5] !== isEditing || $[6] !== isUniqueFieldName || $[7] !== menu || $[8] !== removable || $[9] !== showClientFieldReference || $[10] !== showMore || $[11] !== style || $[12] !== value) {
    t2 = <>{!isEditing ? <Form onSubmit={_temp3}>{t3 => <Email getWrapper={getWrapper} schema={value} disabled={true} menu={menu} style={style} className={className} disableAutoComplete={disableAutoComplete} />}</Form> : <Grid sx={{
        display: !isEditing ? "none" : null
      }}><LabelField prefix="" />{!hideRequired && <RequiredField prefix="" />}<PlaceholderField prefix="" /><HelpTextField prefix="" />{showMore && <>{showClientFieldReference && removable && <PrivateField prefix="" />}<NameField prefix="" isUniqueFieldName={isUniqueFieldName} removable={removable} />{showClientFieldReference && removable && <ClientFieldReference prefix="" schema={value} />}</>}<Button onClick={toggleMore} size="small" width="100%" variant="outlined">{showMore ? "Show less ..." : "Show more ..."}</Button></Grid>}</>;
    $[1] = className;
    $[2] = disableAutoComplete;
    $[3] = getWrapper;
    $[4] = hideRequired;
    $[5] = isEditing;
    $[6] = isUniqueFieldName;
    $[7] = menu;
    $[8] = removable;
    $[9] = showClientFieldReference;
    $[10] = showMore;
    $[11] = style;
    $[12] = value;
    $[13] = t2;
  } else {
    t2 = $[13];
  }
  return t2;
};
function _temp(v) {
  return v;
}
function _temp2(v) {
  return !v;
}
function _temp3(value_0) {
  return null;
}